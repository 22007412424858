import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Radio,
  withStyles,
} from "@material-ui/core";
import StickyBox from "react-sticky-box";

import { OrderSelectors } from "../../Redux/OrderRedux";
import { MenuSelectors } from "../../Redux/MenuRedux";

import createUrlToScreen from "../../Services/Utils";
import styles from "./style.module.scss";

const classes = (theme) => {
  return {
    quantityIcon: {
      backgroundColor: theme.palette.secondary.main,
      cursor: "pointer !important",
      color: "white !important",
      padding: "15px !important",
      marginLeft: "15px !important",
      marginRight: "15px !important",
      paddingTop: "20px !important",
      opacity: 0.8,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
};

class BookingVariation extends Component {
  constructor(props) {
    super(props);
    console.log("BookingVariation props ", props);
    let variationList = this.itemVariationList();
    let selectedVariation = variationList.length ? variationList[1] : {};
    this.state = {
      selectedVariation: selectedVariation,
      variationList: variationList,
    };
  }
  itemVariationList = () => {
    let variationListWithNames = [];
    let itemVariationList = this.props.historyJsonItem
      ? this.props.historyJsonItem.item.item_variation_list
      : this.props.item.item_variation_list;

    let allVariationList = this.props.historyJsonItem
      ? this.props.historyJsonItem.variationList
      : this.props.variationList;
    console.log("itemVariationList ", itemVariationList);
    console.log("allVariationList ", allVariationList);

    itemVariationList.forEach((variation) => {
      let name = allVariationList.find(
        (variationWithName) =>
          variationWithName.variation_id == variation.variation_id
      );
      let nameWithCost = Object.assign(
        { variation_cost: variation.variation_cost },
        name
      );
      variationListWithNames.push(nameWithCost);
      console.log("nameWithCost ", nameWithCost);
    });

    return variationListWithNames;
  };

  isSelectedVariation = (variation) => {
    if (this.state.selectedVariation) {
      return (
        this.state.selectedVariation.variation_name == variation.variation_name
      );
    } else {
      return (
        variation.variation_name == this.state.variationList[0].variation_name
      );
    }
  };

  setSelectedVariation = (variation) => {
    this.setState((prevState) => ({
      selectedVariation: variation,
    }));
  };

  render() {
    console.log("variationList", this.state.variationList);
    console.log("selectedVariation", this.state.selectedVariation);
    return (
      <div
        style={{
          justifyContent: "center",
          backgroundColor: "#fff",
          height: "100%",
        }}
      >
        <StickyBox className={styles.titleContainer}>
          <div className={styles.titleBox}>
            <div className="font20Bold">{this.props?.item?.item_name}</div>
            <div className="subhead">{this.props?.item?.description}</div>
          </div>
        </StickyBox>
        <Divider />
        {/* <div style={{ display: "block" }}>
          <div className={`font16Bold ${styles.modifierTitle}`}>
            {"Estimated Service Time :" +
              this.props?.item?.service_duration_minutes}
          </div>
          <div className={`font16Bold ${styles.constTitle}`}>
            {"Estimated Service Cost :  $" + this.props?.item?.item_cost}
          </div>
        </div> */}
        {this.state.variationList.length > 0 && (
          <div style={{ display: "block" }}>
            <div className={`font16Bold ${styles.modifierTitle}`}>
              Choose Your Service
            </div>
            <List className={styles.listContainerStyle}>
              {this.state.variationList.map((variation, i) => (
                <ListItem
                  key={"variation" + i}
                  button
                  onClick={() => this.setSelectedVariation(variation)}
                  className={styles.listStyle}
                  disabled={
                    variation.available_qty === 0 ||
                    variation.available_qty <= 0
                  }
                >
                  <ListItemText
                    primary={
                      variation.variation_name +
                      " ($" +
                      (+variation.variation_cost).toFixed(2) +
                      ")"
                    }
                  />
                  <Radio
                    checked={this.isSelectedVariation(variation)}
                    className="font16"
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}

        <Button
          style={{ width: "100%" }}
          variant="contained"
          // className={styles.floatingButton}
          color="secondary"
          onClick={(e) => {
            e.stopPropagation(); // Prevent click on card when button is clicked
            this.props.bookAppointment();
          }}
        >
          Book Now
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu_items: MenuSelectors.getMenuItems(state)
      ? MenuSelectors.getMenuItems(state)
      : [],
    selectedProductsQty: OrderSelectors.getTotalAvailableQty(state),
    variationList: MenuSelectors.getVariationList(state) || [],
    groupList: MenuSelectors.getGroupList(state) || [],
    modifierList: MenuSelectors.getModifierList(state) || [],
    taxList: MenuSelectors.getTaxList(state) || [],
    selectedProducts: OrderSelectors.getProducts(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(classes)(BookingVariation))
);
