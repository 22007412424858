import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  withTheme,
  withStyles,
  withWidth,
} from "@material-ui/core";

const styles = (theme) => ({
  container: {
    height: "100%",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  orderButton: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});
class CancelationPolicy extends Component {
  constructor(props) {
    super(props);
    console.log("CancelationPolicy props ", props);
    this.state = {};
  }

  // goToShowAppointment = () => {
  //   this.props.history.push(
  //     `/web/${this.props?.selectedOrder.business_name_url}/${this.props?.selectedOrder.location_name_url}/showAppointment/${this.props?.selectedOrder?.appointment_details?.id}`
  //   );
  // };
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box className={classes.container}>
          {/* <NavBar /> */}
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "80vh",
              position: "relative",
            }}
          >
            <h2>Cancelation Policy</h2>
            <p style={{ wordSpacing: 10, letterSpacing: 1, padding: "10px" }}>
              {this.props.restaurantInfoData?.cancellation_policy}
            </p>
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
              }}
            >
              {/* <Button
                color="secondary"
                variant="outlined"
                onClick={this.goToShowAppointment}
              >
                Go To ShowAppointment
              </Button> */}
              <Button
                color="secondary"
                variant="outlined"
                onClick={this.props.handleCloseCancelationPolicyModal}
              >
                Close
              </Button>
            </div>
          </Container>
        </Box>
      </>
    );
  }
}
// Map state to props
const mapStateToProps = (state) => ({
  selectedOrder: state.order.selectedOrder,
  businessId: state.menu.business_id,
});

export default withRouter(
  connect(mapStateToProps)(
    withTheme(withStyles(styles)(withWidth()(CancelationPolicy)))
  )
);
