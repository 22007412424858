import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Radio,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Grid,
  Chip,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./style.module.scss";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

const classes = (theme) => {
  return {
    quantityIcon: {
      backgroundColor: theme.palette.secondary.main,
      cursor: "pointer !important",
      color: "white !important",
      padding: "15px !important",
      marginLeft: "15px !important",
      marginRight: "15px !important",
      paddingTop: "20px !important",
      opacity: 0.8,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.down(600)]: {
        padding: "0px !important",
        marginLeft: "7px !important",
        marginRight: "0px !important",
        paddingTop: "2px !important",
      },
    },
    quantityNumber: {
      paddingTop: 3,
      marginLeft: "0px !important",
      [theme.breakpoints.down(600)]: {
        marginLeft: "7px !important",
      },
    },
  };
};

class Modifiers extends Component {
  constructor(props) {
    super(props);
    let minimumCountReq = 0;
    let maximumCountReq = 0;
    let isReq = false;
    if (this.props.modifierObj.required == 1) {
      minimumCountReq = 1;
      maximumCountReq = 1;
      isReq = true;
    } else if (this.props.modifierObj.required == 2) {
      minimumCountReq = 0;
      maximumCountReq = 0;
      isReq = true;
    } else if (this.props.modifierObj.required == 3) {
      minimumCountReq = 1;
      maximumCountReq = 1;
      isReq = false;
    } else if (this.props.modifierObj.required == 5) {
      minimumCountReq = this.props.modifierObj.minimumCount;
      maximumCountReq = this.props.modifierObj.maximumCount;
      isReq = true;
    }

    let selectedModifiers = [];
    let selectionCount = 0;
    if (this.props.previouslySelectedModifiers.length) {
      this.props.previouslySelectedModifiers.forEach((modifier) => {
        if (modifier.modifier_group_id == this.props.modifierObj.groupId) {
          selectedModifiers.push(modifier);
          if (modifier.is_double == "1") {
            selectionCount += 2;
          } else {
            selectionCount++;
          }
        }
      });
    }

    this.state = {
      selectionCount: selectionCount,
      minimumCountReq: minimumCountReq,
      maximumCountReq: maximumCountReq,
      selectedModifier: selectedModifiers,
      isReq: isReq,
    };
  }

  addModifier = (modifier) => {
    this.handleSelection(modifier);
    this.props.addModifier(modifier);
  };

  removeModifier = (modifier, isFromAddModifier) => {
    this.handleDeletion(modifier, isFromAddModifier);
    this.props.removeModifier(modifier);
  };

  /**
    Handles the count and selction state on addition
    */
  handleSelection = (modifier) => {
    if (this.state.maximumCountReq == 1) {
      if (this.state.selectionCount != 0) {
        this.removeModifier(this.state.selectedModifier[0], true);
      }
      if (this.state.isReq) {
        this.setSelectionState(true);
      }
      this.setState({ selectionCount: 1, selectedModifier: [modifier] });
    } else if (this.state.maximumCountReq == 0) {
      if (this.state.isReq) {
        this.setSelectionState(true);
      }
      this.setState({
        selectionCount: this.state.selectionCount + 1,
        selectedModifier: [...this.state.selectedModifier, modifier],
      });
    } else if (this.state.maximumCountReq > 1) {
      if (
        this.state.minimumCountReq <=
          this.state.selectionCount + (modifier.is_double == "1" ? 2 : 1) &&
        this.state.selectionCount + (modifier.is_double == "1" ? 2 : 1) <=
          this.state.maximumCountReq
      ) {
        this.setSelectionState(true);
      }
      this.setState({
        selectionCount:
          this.state.selectionCount + (modifier.is_double == "1" ? 2 : 1),
        selectedModifier: [...this.state.selectedModifier, modifier],
      });
    }
  };

  handleDeletion = (modifier, isFromAddModifier) => {
    if (this.state.maximumCountReq == 1 && !isFromAddModifier) {
      this.setSelectionState(false);
      this.setState({ selectionCount: 0, selectedModifier: [] });
    } else if (this.state.maximumCountReq == 0) {
      if (
        this.state.isReq &&
        this.state.selectionCount - (modifier.is_double == "1" ? 2 : 1) == 0
      ) {
        this.setSelectionState(false);
      }
      this.setState({
        selectionCount:
          this.state.selectionCount - (modifier.is_double == "1" ? 2 : 1),
        selectedModifier: this.state.selectedModifier.filter(
          (selectedModifier) =>
            selectedModifier.modifier_id != modifier.modifier_id
        ),
      });
    } else if (this.state.maximumCountReq > 1) {
      if (this.state.minimumCountReq >= this.state.selectionCount) {
        this.setSelectionState(false);
      }
      let prevModifiers = this.state.selectedModifier;
      prevModifiers.splice(
        this.state.selectedModifier.findIndex(
          (mod) => mod.modifier_id == modifier.modifier_id
        ),
        1
      );
      this.setState({
        selectionCount:
          this.state.selectionCount - (modifier.is_double == "1" ? 2 : 1),
        selectedModifier: prevModifiers,
      });
    }
  };

  isModifierSelected = (modifier) => {
    return (
      this.state.selectedModifier.findIndex(
        (mod) => mod.modifier_id == modifier.modifier_id
      ) >= 0
    );
  };

  setSelectionState = (state) => {
    this.props.setModifierSelectionState(this.props.modifierObj.groupId, state);
  };

  getSpiceLevel = (spiceLevel) => {
    var response = "";
    switch (spiceLevel) {
      case "None":
        response = "";
        break;
      case "Sweet":
        response = (
          <Chip
            variant="outlined"
            color="secondary"
            size="small"
            label={spiceLevel}
            classes={{ sizeSmall: styles.smallChip }}
          />
        );
        break;
      case "Mild":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={spiceLevel}
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimaryMild,
            }}
          />
        );
        break;
      case "Medium":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={spiceLevel}
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimaryMedium,
            }}
          />
        );
        break;
      case "Hot":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={spiceLevel}
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimaryHot,
            }}
          />
        );
        break;
      case "Homicide":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label="Homocide"
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimaryHomocide,
            }}
          />
        );
      case "Suicide":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={spiceLevel}
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimarySuicide,
            }}
          />
        );
        break;
      case "Extra Suicide":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={spiceLevel}
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimaryExtraSuicide,
            }}
          />
        );
        break;
      case "Knockout":
        response = (
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={spiceLevel}
            classes={{
              sizeSmall: styles.smallChip,
              colorPrimary: styles.colorPrimaryKnockout,
            }}
          />
        );
        break;
    }
    return response;
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          className={styles.modifierTitle}
          id={this.props.modifierObj.groupId}
        >
          <div className="font16Bold">{this.props.modifierObj.groupName}</div>
          <Grid container>
            <Grid item xs={12} sm={8}>
              {(this.props.modifierObj.required == 4 ||
                this.props.modifierObj.required == 2) && (
                <span className="subhead">
                  Choose as many as you would like
                </span>
              )}
              {(this.props.modifierObj.required == 1 ||
                this.props.modifierObj.required == 3) && (
                <span className="subhead">Choose any one of the following</span>
              )}
              {this.props.modifierObj.required == 5 &&
                this.props.modifierObj.minimumCount ===
                  this.props.modifierObj.maximumCount && (
                  <span className="subhead">
                    {"Choose exactly " + this.props.modifierObj.minimumCount}
                  </span>
                )}
              {this.props.modifierObj.required == 5 &&
                this.props.modifierObj.minimumCount !==
                  this.props.modifierObj.maximumCount && (
                  <span className="subhead">
                    {"Choose between " +
                      this.props.modifierObj.minimumCount +
                      " and " +
                      this.props.modifierObj.maximumCount}
                  </span>
                )}
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
              {(this.props.modifierObj.required == 1 ||
                this.props.modifierObj.required == 2 ||
                this.props.modifierObj.required == 5) && (
                <span className={`subhead ${styles.requiredText}`}>
                  Required
                </span>
              )}
            </Grid>
          </Grid>
        </div>
        {this.props.modifierObj.required != 5 && (
          <div>
            <List className={styles.listContainerStyle}>
              {this.props.modifierObj.modifiers.map((modifier, i) => {
                return (
                  <ListItem
                    key={"variation" + i}
                    button
                    onClick={() =>
                      this.isModifierSelected(modifier)
                        ? this.removeModifier(modifier, false)
                        : this.addModifier(modifier)
                    }
                    className={styles.listStyle}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span style={{ paddingRight: 15 }}>
                            {modifier.modifier_name +
                              "($" +
                              (+modifier.modifier_cost).toFixed(2) +
                              ")"}
                          </span>
                          <span className={styles.doubleToppings}>
                            {modifier.is_double == "1" ? (
                              <Chip
                                variant="outlined"
                                color="secondary"
                                size="small"
                                label="Double Topping"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className={styles.doubleToppings}>
                            {this.getSpiceLevel(modifier.spice_level)}
                          </span>
                        </>
                      }
                    />
                    {this.props.modifierObj.required == 4 ||
                    this.props.modifierObj.required == 2 ? (
                      <Checkbox checked={this.isModifierSelected(modifier)} />
                    ) : (
                      <Radio checked={this.isModifierSelected(modifier)} />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
        {this.props.modifierObj.required == 5 && (
          <div>
            <List className={styles.listContainerStyle}>
              {this.props.modifierObj.modifiers.map((modifierIn) => (
                <ListItem
                  key={modifierIn.modifier_id}
                  button
                  className={styles.listStyle}
                >
                  <ListItemText
                    style={{ width: "100%" }}
                    primary={
                      <>
                        <span style={{ paddingRight: 15 }}>
                          {modifierIn.modifier_name +
                            "($" +
                            (+modifierIn.modifier_cost).toFixed(2) +
                            ")"}
                        </span>
                        <span className={styles.doubleToppings}>
                          {modifierIn.is_double == "1" ? (
                            <Chip
                              variant="outlined"
                              color="secondary"
                              size="small"
                              label="Double Topping"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                        <span className={styles.doubleToppings}>
                          {this.getSpiceLevel(modifierIn.spice_level)}
                        </span>
                      </>
                    }
                  />
                  <Grid container justify="flex-end">
                    <Chip
                      label={<Remove />}
                      className={classes.quantityIcon}
                      onClick={() =>
                        this.props.getModifierSelectedCount(modifierIn) == 0
                          ? null
                          : this.removeModifier(modifierIn)
                      }
                    />
                    <span className={classes.quantityNumber}>
                      {this.props.getModifierSelectedCount(modifierIn)}
                    </span>
                    <Chip
                      label={<Add />}
                      className={classes.quantityIcon}
                      onClick={() =>
                        +this.state.selectionCount +
                          (modifierIn.is_double == "1" ? 2 : 1) <=
                        this.state.maximumCountReq
                          ? this.addModifier(modifierIn)
                          : null
                      }
                    />
                  </Grid>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </>
    );
  }
}

export default connect()(withStyles(classes)(Modifiers));
