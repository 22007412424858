import apisauce from "apisauce";
import { api_key } from "./apiKey";
import baseUrl from "./baseUrl";

const create = (baseURL = baseUrl()) => {
  const customerApi = apisauce.create({
    baseURL,
  });

  const findCustomers = (phoneNumber, businessId, locationId) => {
    let userData = {
      phone: phoneNumber,
      business_id: businessId,
      location_id: locationId,
      api_key: api_key,
    };
    return customerApi.post("searchcustomers.php", JSON.stringify(userData));
  };

  const createCustomer = (name, phoneNumber, businessId, locationId) => {
    let userData = {
      customer_phone: phoneNumber,
      customer_name: name,
      business_id: businessId,
      location_id: locationId,
      api_key: api_key,
    };
    return customerApi.post("savecustomer.php", JSON.stringify(userData));
  };

  return {
    findCustomers,
    createCustomer,
  };
};

export default {
  create,
};
