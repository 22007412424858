import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator

const { Types, Creators } = createActions({
  findCustomers: ["phoneNumber", "businessId", "locationId", "token"],
  findCustomerSuccess: ["customerData"],
  findCustomerError: {},
  setActiveCustomer: ["customer"],
  clearCustomers: {},
});

export const CustomerTypes = Types;
export default Creators;

// Initial State
export const INITIAL_CUSTOMER_STATE = Immutable({
  activeCustomer: null,
  customerData: [],
  customerError: false,
});

// Selectors to derive data from state
export const CustomerSelectors = {
  getActiveCustomer: (state) => {
    return state.customer?.activeCustomer?.customer;
  },
  getCustomerData: (state) => {
    return state.customer?.customerData;
  },
};

// Reducers

export const findCustomers = (
  state,
  { phoneNumber, businessId, locationId, token }
) => {
  return {
    activeCustomer: null,
    customerData: [],
    customerError: false,
  };
};

export const findCustomerError = (state, error) => {
  return {
    customerError: true,
  };
};

export const findCustomerSuccess = (state, customerData) => {
  return {
    customerData: customerData.customerData,
    customerError: false,
    activeCustomer: state.activeCustomer,
  };
};

export const setActiveCustomer = (state, customer) => {
  return {
    activeCustomer: customer,
    customerData: [],
    customerError: false,
  };
};

export const clearCustomers = (state) => {
  return {
    activeCustomer: null,
    customerData: [],
    customerError: false,
  };
};
// Setting reducers to the types

export const reducer = createReducer(INITIAL_CUSTOMER_STATE, {
  [Types.FIND_CUSTOMERS]: findCustomers,
  [Types.SET_ACTIVE_CUSTOMER]: setActiveCustomer,
  [Types.FIND_CUSTOMER_ERROR]: findCustomerError,
  [Types.FIND_CUSTOMER_SUCCESS]: findCustomerSuccess,
  [Types.CLEAR_CUSTOMERS]: clearCustomers,
});
