export default function createUrlToScreen(screenName, url) {
    const urlArray = url.split('/')
    urlArray.pop()
    if (screenName != '') {
        const newUrlArray = [...urlArray, screenName]
        const newUrl = newUrlArray.join('/')
        return newUrl
    } else {
        return urlArray.join('/')
    }
}