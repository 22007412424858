const tipValueLookup = [10, 15, 20];
const tipValueLookupDineIn = [15, 18, 20];

const calculateTip = (tipIndex, subTotal, isDineIn) => {
  if (isDineIn) {
    let tip = 0;
    if (tipIndex < 3) {
      let tipPercentValue = tipValueLookupDineIn[tipIndex];
      tip = subTotal * (tipPercentValue / 100);
    }
    return (+tip).toFixed(2);
  } else {
    let tip = 0;
    if (tipIndex < 3) {
      let tipPercentValue = tipValueLookup[tipIndex];
      tip = subTotal * (tipPercentValue / 100);
    }
    return (+tip).toFixed(2);
  }
};

export default calculateTip;
