import React, { Component } from "react";
import styles from "./styles.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Api from "../../Services/Api";
import { StoreLoginSelectors } from "../../Redux/StoreLoginRedux";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemText,
  ListItem,
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  StepConnector,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { OrderSelectors } from "../../Redux/OrderRedux";
import moment from "moment";
import Loading from "../Loading/Loading";

class PhoneOrderHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderHistory: [],
      loading: true,
      error: false,
      pickupStatus: [
        "New Order",
        "Order Confirmed",
        "Order Ready For Pickup",
        "Order Ready For Pickup",
        "Completed",
        "Cancelled",
      ],
      deliveryStatus: [
        "New Order",
        "Order Confirmed",
        "Order Ready",
        "On Delivery",
        "Completed",
        "Cancelled",
      ],
      filteredHistory: [],
      searchString: "",
    };
  }

  componentDidMount() {
    const apiService = Api.create();
    var requestObj = {
      business_id: this.props.restaurantInfo?.info?.business_id,
      location_id: this.props.restaurantInfo?.info?.location_id,
    };
    apiService.getOrderHistory(true, requestObj).then((response) => {
      if (response.ok && response.status == 200) {
        this.setState({
          orderHistory: response.data.orders_list.reverse(),
          loading: false,
          error: false,
          filteredHistory: response.data.orders_list.reverse(),
        });
      } else if (this.state.orderHistory == {}) {
        this.setState({ loading: false, error: false });
      }
    });
  }

  removeInterval = () => {
    clearInterval(this.state.intervalId);
    this.setState({
      intervalId: null,
    });
  };

  getOrderHistoryInterval = () => {
    if (!this.state.intervalId) {
      this.getOrderStatus();
      const intervalId = setInterval(this.getOrderStatus, 45000);
      this.setState({
        intervalId: intervalId,
      });
    }
  };

  getOrderStatus = () => {
    if (this.state.orderHistory?.length) {
      const apiService = Api.create();
      let allOrders = this.state.orderHistory;
      allOrders.forEach((order, index) => {
        if (order.order_status != 5) {
          apiService
            .getOrderStatus(
              order.business_id,
              order.location_id,
              order.order_number
            )
            .then((response) => {
              allOrders[index].order_message = response?.data?.order_message;
              if (
                allOrders[index].order_status != response?.data?.order_status
              ) {
                allOrders[index].order_status = response?.data?.order_status;
              }
            });
        }
        if (index == allOrders.length - 1) {
          this.setState({
            orderHistoryJson: allOrders,
          });
        }
      });
    }
  };

  renderTime = (timeStamp) => {
    var date = moment.utc(timeStamp);
    return date.local().format("DD MMM LT");
  };

  clearFilter = () => {
    const orderHistory = this.state.orderHistory;
    this.setState({
      searchString: "",
      filteredHistory: orderHistory,
    });
  };

  filterHistory = (event) => {
    var value = event.target.value;
    var length = value.length;
    this.setState({ searchString: value });
    if (length > 2) {
      var filteredHistory = this.state.orderHistory.filter(function (order) {
        if (
          ("" + order.customer_phone).replace(/\D/g, "").indexOf(value) !==
            -1 ||
          order.order_number.indexOf(value) !== -1 ||
          order.customer_name.indexOf(value) !== -1
        ) {
          return true;
        }
      });
      this.setState({
        filteredHistory: filteredHistory,
      });
    } else {
      const orderHistory = this.state.orderHistory;
      this.setState({
        filteredHistory: orderHistory,
      });
    }
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  render() {
    return (
      <>
        <Card>
          <CardHeader title="Order History" />
          <CardContent>
            {this.state.loading && <Loading />}
            {this.state.orderHistory.length == 0 && !this.state.loading && (
              <Grid container>
                <Grid item xs={12}>
                  There are no new orders.
                </Grid>
              </Grid>
            )}
            {this.state.orderHistory.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    variant="standard"
                    label="Search Orders"
                    fullWidth={true}
                    style={{ paddingBottom: 40 }}
                    value={this.state.searchString}
                    onChange={(event) => this.filterHistory(event)}
                  ></TextField>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    style={{ marginTop: 16 }}
                    color="secondary"
                    onClick={() => this.clearFilter()}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            )}
            {this.state.filteredHistory?.reverse().map((order) => (
              <Card>
                <CardContent>
                  <CardHeader
                    title={
                      <Grid container>
                        <Grid item xs={8}>
                          {order?.customer_name}
                        </Grid>
                        <Grid item xs={4}>
                          <div className={styles.orderStatus}>
                            {order?.order_type == 1 &&
                              (order.order_status
                                ? this.state.deliveryStatus[
                                    +order.order_status - 1
                                  ]
                                : "")}
                            {order?.order_type == 2 &&
                              (order.order_status
                                ? this.state.pickupStatus[
                                    +order.order_status - 1
                                  ]
                                : "")}
                          </div>
                        </Grid>
                      </Grid>
                    }
                    subheader={
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <div class="subhead">{order?.customer_phone}</div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {order.order_type == 1 && (
                            <div
                              class="font16"
                              style={{
                                width: "100%",
                              }}
                            >
                              Delivery
                            </div>
                          )}
                          {order.order_type == 2 && (
                            <div
                              class="font16"
                              style={{
                                width: "100%",
                              }}
                            >
                              Pickup
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <div
                            class="font16"
                            style={{
                              width: "100%",
                            }}
                          >
                            Requested Time:
                            {order?.receipt?.order_requested_time !== "ASAP"
                              ? this.renderTime(
                                  order?.receipt?.order_requested_time
                                )
                              : "ASAP"}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <div class="subhead">
                            Order: {order?.order_number}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <div class="subhead">
                            {" "}
                            Placed at:
                            {order?.receipt?.order_placed
                              ? this.renderTime(order?.receipt?.order_placed)
                              : ""}
                          </div>
                        </Grid>
                      </Grid>
                    }
                  />
                  {order.order_message && (
                    <Grid item xs={12}>
                      <div className={styles.centerMessage}>
                        {order.order_message}
                      </div>
                    </Grid>
                  )}
                  {/* {order?.order_status != 5 && (
                                <Stepper
                                    activeStep={this.getActiveStep(order)}
                                    alternativeLabel
                                    connector={<ColorlibConnector />}
                                >
                                    {this.getSteps(order).map((step, index) => {
                                        if (order?.order_type == 1) {
                                            return (
                                                <Step key={step}>
                                                    <StepLabel
                                                        StepIconProps={{
                                                            classes: {
                                                                completed: styles.completedIcon,
                                                            },
                                                        }}
                                                        StepIconComponent={DeliveryColorlibStepIcon}
                                                    >
                                                        {step}
                                                    </StepLabel>
                                                </Step>
                                            );
                                        } else {
                                            return (
                                                <Step key={step}>
                                                    <StepLabel
                                                        StepIconProps={{
                                                            classes: {
                                                                completed: styles.completedIcon,
                                                            },
                                                        }}
                                                        StepIconComponent={
                                                            PickupColorlibStepIcon
                                                        }
                                                    >
                                                        {step}
                                                    </StepLabel>
                                                </Step>
                                            );
                                        }
                                    })}
                                </Stepper>
                            )} */}
                  <Divider />
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div>Order Receipt</div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <List style={{ width: "100%" }}>
                        {order.receipt.item_list.map((product) => (
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={product.description}
                              secondary={
                                product.modifiers.length ? (
                                  <Grid item xs={12} sm={12}>
                                    <List>
                                      {product.modifiers.map((modifier, index) => (
                                        <ListItem
                                          classes={{
                                            root: styles.modifierItemStyle,
                                          }}
                                          key={"modi" + modifier.modifier_id + modifier + index}
                                        >
                                          {modifier}
                                        </ListItem>
                                      ))}
                                    </List>
                                  </Grid>
                                ) : null
                              }
                            />
                            <ListItemText
                              classes={{ root: styles.amountStyle }}
                              edge="end"
                              primary={product.cost}
                            />
                          </ListItem>
                        ))}
                        <Divider />
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary="Food & Beverages Subtotal" />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.food_beverage_subtotal}
                          />
                        </ListItem>
                        {order.order_type == 1 && (
                          <ListItem
                            alignItems="flex-start"
                            classes={{ root: styles.receiptLineItem }}
                          >
                            <ListItemText primary="Delivery Fees" />
                            <ListItemText
                              classes={{ root: styles.amountStyle }}
                              edge="end"
                              primary={order.receipt.delivery_fee}
                            />
                          </ListItem>
                        )}
                        {order.coupon_amount && (
                          <ListItem
                            alignItems="flex-start"
                            classes={{ root: styles.receiptLineItem }}
                          >
                            <ListItemText primary="Discount" />
                            <ListItemText
                              classes={{ root: styles.amountStyle }}
                              edge="end"
                              primary={`- ${order.receipt.coupon_amount}`}
                            />
                          </ListItem>
                        )}
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary="Tip" />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.tip_cost}
                          />
                        </ListItem>
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary="Tax" />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.tax}
                          />
                        </ListItem>
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary="Total" />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.total}
                          />
                        </ListItem>
                        <Divider />
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary={order.receipt.paid_text} />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.paid_value}
                          />
                        </ListItem>
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary="Date" />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.date}
                          />
                        </ListItem>
                        <ListItem
                          alignItems="flex-start"
                          classes={{ root: styles.receiptLineItem }}
                        >
                          <ListItemText primary="Message" />
                          <ListItemText
                            classes={{ root: styles.amountStyle }}
                            edge="end"
                            primary={order.receipt.message}
                          />
                        </ListItem>
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    storeUserInfo: StoreLoginSelectors.getUserInfo(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
  };
};

export default connect(mapStateToProps)(PhoneOrderHistoryModal);
