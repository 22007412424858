import { call, put } from "redux-saga/effects";
import CustomerActions from "../Redux/CustomerRedux";

export function* findCustomers(api, action) {
  const { phoneNumber, businessId, locationId, token } = action;
  const response = yield call(
    api.findCustomers,
    phoneNumber,
    businessId,
    locationId,
    token
  );

  if (response.ok) {
    const customerData = response.data.customer_list;
    yield put(CustomerActions.findCustomerSuccess(customerData));
  } else yield put(CustomerActions.findCustomerError());
}
