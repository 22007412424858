import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import CustomerActions, { CustomerSelectors } from "../../Redux/CustomerRedux";
import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../../Redux/StoreLoginRedux";
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  CardHeader,
  IconButton,
  ListItemText,
  Card,
  ListItemSecondaryAction,
  Divider,
  CardContent,
  CardActions,
  Modal,
  TextField,
  Box,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  TableHead,
  TableBody,
  Table,
} from "@material-ui/core";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import styles from "./style.module.scss";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomerApi from "../../Services/CustomerApi";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckoutComponent from "../CheckoutComponent/CheckoutComponent";
import { toast } from "react-toastify";
import MenuApi from "../../Services/MenuApi";
import Api from "../../Services/Api";
const google = window.google;

class PhoneOrderSidbBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      isScheduled: this.props.isScheduled,
      datePicked: "",
      timePicked: "",

      orderType: this.props.orderType,
      customerInfo: null,
      orderInstructions: null,
      inputAddress: "",
      searchResults: [],
      selectedAddress: "",
      customerList: null,
      createCustomerNameBox: false,
      phoneNumber: null,
      deliveryAddressError: false,
      editCustomer: false,
      editCustomerInfo: null,
    };
    this.checkoutComponentRef = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
    window.onbeforeunload = function () {
      return "Are you sure to leave this page?";
    };
  }

  removeCoupon = () => {
    this.checkoutComponentRef.removeCoupon();
  };

  setOrderType = (orderType) => {
    this.setState({ datePicked: "", timePicked: "", orderType: orderType });
    this.props.dispatch(LoginActions.setOrderType(orderType));
  };

  isAsapAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_asap_delivery;
    else return this.props.restaurantInfo?.info?.is_asap_pickup;
  };

  isScheduleAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_scheduled_delivery;
    else return this.props.restaurantInfo?.info?.is_scheduled_pickup;
  };

  getAvailableDate = () => {
    const slots =
      this.state.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableDates = Object.keys(slots);
    return availableDates.map((date) => (
      <MenuItem value={date}>{moment(date).format("Do MMM dddd")}</MenuItem>
    ));
  };

  getAvailableTime = () => {
    const slots =
      this.state.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableSlots = slots[this.state.datePicked] ?? [];
    return availableSlots.map((time) => (
      <MenuItem value={time}>{time}</MenuItem>
    ));
  };

  removeProduct = (index) => {
    if (this.props.couponInfo?.applied) {
      this.removeCoupon();
      this.props.dispatch(OrderActions.removeProduct(index));
    } else {
      this.props.dispatch(OrderActions.removeProduct(index));
    }
  };

  getRestaurantInfo = () => {
    const apiService = Api.create();

    apiService
      .getRestaurantInfo(
        this.props.match?.params?.restaurant,
        this.props.match?.params?.location == "main"
          ? null
          : this.props.match?.params?.location
      )
      .then((response) => {
        if (response.status == 200 && response.data?.business_id) {
          const responseInfo = response.data;
          const requestObj = {
            business_id: responseInfo.business_id,
            location_id: responseInfo.location_id,
            order_type: "2",
          };
          const apiService = MenuApi.create();
          let moreRestaurantDetails = {};
          apiService
            .getRestaurantDetailsPhoneOrder(requestObj)
            .then((response) => {
              if (!response.data.available) {
                toast.error("Error loading new time slots");
              } else {
                const responseObj = response.data;

                var info = { ...responseInfo, ...responseObj };
                this.props.dispatch(
                  OrderActions.setNewRestaurant(
                    responseInfo.business_id,
                    responseInfo.location_id,
                    responseInfo.intersection_plaza,
                    false,
                    info,
                    false
                  )
                );
                if (!info?.is_pick_up_available) {
                  this.props.dispatch(LoginActions.setOrderType(1));
                }
                if (!this.isAsapAvailable()) {
                  this.props.dispatch(LoginActions.setIsScheduled(true));
                  this.setState({ isScheduled: true });
                }
              }
            });
        } else {
          this.setState({
            restaurantClosed: true,
            restaurantErrorMessage: response?.data?.status_message,
          });
        }
      });
  };

  getModifierName = (id) => {
    let modifier = "";

    modifier = this.props.modifiers.find(
      (modifier) => modifier.modifier_id == id
    );
    // }
    return modifier.modifier_name;
  };

  getProductName = (id) => {
    let menuItem = "";

    menuItem = this.props.menuItems?.filter((item) => item.item_id == id);
    // }

    return menuItem?.length ? menuItem[0]?.item_name : null;
  };

  getVariationInfo = (product) => {
    if (product.variation_id) {
      return (
        " (" +
        this.props.variationList.find(
          (variation) => variation.variation_id == product.variation_id
        ).variation_name +
        ")"
      );
      // }
    }
    return "";
  };

  saveChanges = () => {
    var dateTime = null;
    if (this.state.isScheduled) {
      dateTime = this.state.datePicked + " " + this.state.timePicked;
      this.props.dispatch(LoginActions.setScheduleDateTime(dateTime));
    }

    if (this.state.editCustomer) {
      var flag = false;

      if (this.state.orderType !== this.state.editCustomerInfo.orderType) {
        flag = true;
      }

      if (this.state.datePicked !== this.state.editCustomerInfo.datePicked) {
        flag = true;
      }

      if (this.state.timePicked !== this.state.editCustomerInfo.timePicked) {
        flag = true;
      }

      if (flag) {
        this.props.dispatch(OrderActions.phoneOrderSuccessful());
        this.props.dispatch(
          MenuActions.menuRequest(
            this.props.restaurantInfo?.info?.business_id,
            this.props.restaurantInfo?.info?.location_id,
            this.props.orderType,
            dateTime
          )
        );
      }
    } else {
      this.props.dispatch(OrderActions.phoneOrderSuccessful());
      this.props.dispatch(
        MenuActions.menuRequest(
          this.props.restaurantInfo?.info?.business_id,
          this.props.restaurantInfo?.info?.location_id,
          this.props.orderType,
          dateTime
        )
      );
    }

    this.setState({ showEditModal: false });
  };

  isCheckoutDisabled = () => {
    if (this.props.selectedProducts.length < 1) return true;
    else
      return this.props.orderType == 1
        ? !this.props.selectedAddress?.latitude
        : false;
  };

  isSaveDisabled = () => {
    if (this.state.isScheduled) {
      return this.state.datePicked == "" || this.state.timePicked == "";
    } else {
      return false;
    }
  };

  getResults = (query) => {
    if (query.length > 3) {
      this.setState({ inputAddress: query });
      this.searchPlaces(query).then((response) => {});
    }
  };

  removeCountryText = (description) => {
    description = description.replace(", USA", "");
    return description.replace(", Canada", "");
  };

  searchOldPlace = async (query) => {
    var options = {
      types: ["address"],
      componentRestrictions: { country: "ca" },
    };
    var response = new google.maps.places.AutocompleteService();
    var self = this;
    var result = response.getPlacePredictions(
      {
        input: query,
        types: ["address"],
        componentRestrictions: { country: "ca" },
      },
      async function (predictions, status) {
        if (predictions?.length > 0) {
          self.getLocationInfo(predictions[0]);
        }
      }
    );
    return await result;
  };

  searchPlaces = async (query) => {
    var options = {
      types: ["address"],
      componentRestrictions: { country: ["ca", "us"] },
    };
    var response = new google.maps.places.AutocompleteService();
    var self = this;
    var result = response.getPlacePredictions(
      {
        input: query,
        types: ["address"],
        componentRestrictions: { country: ["ca", "us"] },
      },
      function (predictions, status) {
        if (status == "OK") {
          self.setState((state) => {
            return {
              searchResults: predictions,
              selectedAddress: state.selectedAddress,
              latitude: state.latitude,
              longitude: state.longitude,
            };
          });
          return predictions;
        }
      }
    );
    return await result;
  };

  changeOrderType = () => {};

  getLocationInfo = async (place) => {
    var response = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    var self = this;
    response.getDetails(
      { placeId: place.place_id },
      function (exactLocation, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          self.setState((state) => {
            return {
              searchResults: [],
              selectedAddress:
                self.removeCountryText(place.description) +
                self.findPostalCode(exactLocation),
              latitude: exactLocation.geometry.location.lat(),
              longitude: exactLocation.geometry.location.lng(),
              inputAddress:
                place.description + self.findPostalCode(exactLocation),
            };
          });
          if (self.props.orderType == 1) {
            self.props.dispatch(
              LoginActions.setAddress({
                address:
                  self.removeCountryText(place.description) +
                  self.findPostalCode(exactLocation),
                latitude: exactLocation.geometry.location.lat(),
                longitude: exactLocation.geometry.location.lng(),
              })
            );

            var requestObj = {};

            const apiService = MenuApi.create();
            apiService
              .getDeliveryFee(
                self.props.restaurantInfo?.info?.business_id,
                self.props.restaurantInfo?.info?.location_id,
                exactLocation.geometry.location.lat(),
                exactLocation.geometry.location.lng()
              )
              .then((response) => {
                if (
                  response.ok &&
                  response.data.status != "Failure" &&
                  response.data.available == true
                ) {
                  self.props.dispatch(
                    OrderActions.successPhoneDeliveryFee(response.data)
                  );
                  self.setState({ deliveryAddressError: false });
                } else {
                  self.setState({ deliveryAddressError: true });
                }
              });
          }
        }
      }
    );
  };

  isSubTotalDeliveryMinimumCompareError = () => {
    if (this.props.orderType == 1) {
      if (this.props.restaurantInfo.business_id != "") {
        if (
          this.props.subTotal <
          +this.props.restaurantInfo.info.delivery_minimum_order_amount
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getMinimumDeliveryAmount = () => {
    if (this.props.restaurantInfo.business_id != "")
      return this.props.restaurantInfo.info.delivery_minimum_order_amount;
    else return 0;
  };
  findPostalCode = (address) => {
    var postalCode = "";
    address.address_components.forEach((component) => {
      component.types.forEach((type) => {
        if (type == "postal_code") {
          postalCode = " , " + component.long_name;
        }
      });
    });
    return postalCode;
  };

  getSelectedAddressInfo = (place) => {
    if (typeof place === "string" || place instanceof String) {
      var self = this;
      this.searchOldPlace(place);
    } else {
      if (place) {
        this.getLocationInfo(place);
      }
    }
  };

  searchPhoneNumber = (event, direct = false) => {
    if (direct) {
      var phoneNumber = event;
    } else {
      var phoneNumber = event.target.value;
    }
    if (phoneNumber.length == 10) {
      const apiService = CustomerApi.create();
      apiService
        .findCustomers(
          phoneNumber,
          this.props.restaurantInfo?.info?.business_id,
          this.props.restaurantInfo?.info?.location_id
        )
        .then((response) => {
          if (response.ok && response.data.status != "Failure") {
            const customerData = response.data.customer_list;
            if (customerData?.length > 0) {
              this.setState({
                customerList: customerData,
                phoneNumber: phoneNumber,
              });
            } else {
              this.setState({
                createCustomerNameBox: true,
                customerList: [],
                phoneNumber: phoneNumber,
              });
            }
          }
        });
    } else if (phoneNumber.length < 10) {
      if (this.state.createCustomerNameBox) {
        this.setState({
          createCustomerNameBox: false,
          phoneNumber: phoneNumber,
        });
      } else {
        this.setState({
          phoneNumber: phoneNumber,
        });
      }
    }
  };

  createCustomer = () => {
    if (this.state.phoneNumber?.length == 10 && this.state.name) {
      const apiService = CustomerApi.create();
      apiService
        .createCustomer(
          this.state.name,
          this.state.phoneNumber,
          this.props.restaurantInfo?.info?.business_id,
          this.props.restaurantInfo?.info?.location_id
        )
        .then((response) => {
          if (response.ok) {
            const customerData = response.data;
            if (customerData.customer_id) {
              this.props.dispatch(
                CustomerActions.setActiveCustomer(customerData)
              );
              toast.success("Customer Created Successfully");
              this.setState({ createCustomerNameBox: false });
              this.searchPhoneNumber(this.state.phoneNumber, true);
            }
          }
        });
    } else {
      toast.error(
        "Please enter both number and name for creating a new customer"
      );
    }
  };

  setNameForCreate = (event) => {
    const name = event.target.value;
    if (name.length) {
      this.setState({
        name: name,
      });
    }
  };

  clearCustomer = () => {
    this.props.dispatch(CustomerActions.clearCustomers());
  };

  selectCustomer = (row) => {
    this.props.dispatch(CustomerActions.setActiveCustomer(row));
  };

  setCustomer = () => {
    if (!this.isContinueDisabled()) {
      this.setState({ showEditModal: false });
      this.saveChanges();
    }
  };

  isContinueDisabled = () => {
    if (
      this.state.isScheduled &&
      !(this.state.datePicked && this.state.timePicked)
    ) {
      return true;
    }

    if (!(this.props.activeCustomer && this.props.activeCustomer.customer_id)) {
      return true;
    }

    // Delivery
    if (
      this.props.orderType == 1 &&
      !(this.props.selectedAddress && this.props.selectedAddress.address)
    ) {
      return true;
    } else {
      return false;
    }
  };

  placeOrder = () => {
    this.setState({
      isScheduled: this.props.isScheduled,
      datePicked: "",
      timePicked: "",
      orderType: this.props.orderType,
      customerInfo: null,
      orderInstructions: null,
      inputAddress: "",
      searchResults: [],
      selectedAddress: "",
      customerList: null,
      createCustomerNameBox: false,
      phoneNumber: null,
      deliveryAddressError: false,
    });
  };

  orderSentLoading = (loading) => {
    this.props.orderSentLoading(loading);
  };

  trimming = (phoneNumber) => {
    if (phoneNumber) {
      var result = phoneNumber.replace("(", "");
      var result = result.replace(")", "");
      var result = result.replace(" ", "");
      var result = result.replace("-", "");
    }
    return result;
  };

  changeUser = () => {
    this.props.dispatch(CustomerActions.clearCustomers());
    this.props.dispatch(StoreLoginActions.clearUserData());
    this.setState({ customerList: [] });
  };

  cancelCustomer = () => {
    this.props.dispatch(CustomerActions.clearCustomers());
    this.props.dispatch(StoreLoginActions.clearUserData());
    this.setState({
      customerList: [],
      showEditModal: false,
      editCustomer: false,
      editCustomerInfo: null,
    });
    this.props.dispatch(OrderActions.phoneOrderSuccessful());
  };

  updateCustomer = () => {
    this.getRestaurantInfo();
    const info = {
      orderType: this.props.orderType,
      datePicked: this.state.datePicked,
      timePicked: this.state.timePicked,
      selectedAddress: this.state.selectedAddress,
    };
    this.setState({
      editCustomer: true,
      showEditModal: true,
      phoneNumber: this.trimming(this.props.activeCustomer?.customer_phone),
      editCustomerInfo: info,
    });
  };

  addCustomer = () => {
    this.getRestaurantInfo();
    this.setState({
      showEditModal: true,
      phoneNumber: this.trimming(this.props.activeCustomer?.customer_phone),
    });
  };

  render() {
    return (
      <>
        {this.props.isStoreUserloggedIn && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Card classes={{ root: styles.orderCard }}>
              <CardContent
                classes={{ root: styles.cardStyle }}
                style={{ width: "100%" }}
              >
                <CardHeader
                  classes={{ root: styles.checkoutHeader }}
                  title={
                    <Grid container>
                      <Grid item xs={6}>
                        Order Summary
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {this.props.selectedProducts.length} Items
                      </Grid>
                    </Grid>
                  }
                  alignItems="center"
                />
                <Divider />

                {!(
                  this.props.activeCustomer &&
                  this.props.activeCustomer.customer_name
                ) && (
                  <Grid container classes={{ root: styles.cardPadding }}>
                    <Grid item xs={12}>
                      <div className={styles.commonPadding}>
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() => this.addCustomer()}
                          color="secondary"
                          style={{ marginBottom: 15 }}
                        >
                          Add Customer
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {this.props.activeCustomer &&
                  this.props.activeCustomer.customer_name && (
                    <Grid container classes={{ root: styles.cardPadding }}>
                      <Grid item xs={12}>
                        <div className={styles.commonPadding}>
                          <Grid container onClick={() => this.updateCustomer()}>
                            <Grid item xs={12} sm={4}>
                              <strong>
                                {this.props.activeCustomer?.customer_name}
                              </strong>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ textAlign: "right" }}
                            >
                              <div className="font16Bold">
                                {this.props.orderTypeText} (
                                {this.state.isScheduled
                                  ? "at " +
                                    moment(
                                      this.props.scheduleDateTime,
                                      "YYYY-MM-DD HH:mm A"
                                    ).format("DD MMM LT")
                                  : "ASAP"}
                                )
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                             
                              {this.props.selectedAddress &&
                                this.props.orderTypeText !== "Pickup" && (
                                  <div className="font16">
                                    {this.props.selectedAddress.address}
                                  </div>
                                )}
                              {this.props.orderType == 1 &&
                                !this.props.selectedAddress?.latitude && (
                                  <div
                                    className="font16"
                                    style={{ color: "red" }}
                                  >
                                    Please select an address for delivery
                                  </div>
                                )}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                <Divider />
                {this.props.selectedProducts.length <= 0 && (
                  <Grid
                    classes={{ root: styles.cardPadding }}
                    container
                    style={{ minHeight: "150px" }}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      alignItems="center"
                      style={{ textAlign: "center" }}
                    >
                      <span className="subhead">
                        {" "}
                        Add Items from menu to build your order
                      </span>
                    </Grid>
                  </Grid>
                )}
                {this.props.selectedProducts.length > 0 && (
                  <Grid container classes={{ root: styles.menuContainer }}>
                    <List className={styles.checkoutMenuUl}>
                      {this.props.selectedProducts.map((product, index) => (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            onClick={() => {
                              this.props.editProduct(product.product_id, index);
                              this.removeCoupon();
                            }}
                            disableGutters={true}
                          >
                            <ListItemAvatar
                              classes={{ root: styles.itemCountStyle }}
                            >
                              <span>{product.quantity}</span>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                this.getProductName(product.product_id) +
                                this.getVariationInfo(product)
                              }
                              secondary={
                                <Grid container>
                                  {product.modifiers.length ? (
                                    <Grid item xs={12} sm={12}>
                                      <List>
                                        {product.modifiers.map(
                                          (modifier, index) => (
                                            <ListItem
                                              classes={{
                                                root: styles.modifierItemStyle,
                                              }}
                                              key={
                                                "modi" +
                                                modifier.modifier_id +
                                                this.getModifierName(
                                                  modifier.modifier_id
                                                ) +
                                                index
                                              }
                                            >
                                              {this.getModifierName(
                                                modifier.modifier_id
                                              )}
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Grid>
                                  ) : null}
                                  {product.special_instructions && (
                                    <span class="subhead">
                                      {product.special_instructions}
                                    </span>
                                  )}
                                </Grid>
                              }
                            />
                            <ListItemText
                              classes={{ root: styles.amountStyle }}
                              edge="end"
                              primary={
                                "$" + (+product.original_total).toFixed(2)
                              }
                            />
                            <ListItemSecondaryAction
                              classes={{ root: styles.cancelButtonStyle }}
                            >
                              <IconButton edge="end">
                                <CancelIcon
                                  onClick={() => this.removeProduct(index)}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider variant="fullWidth" component="li" />
                        </>
                      ))}
                    </List>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      style={{ width: "100%" }}
                    >
                      <h4> Subtotal </h4>
                      <h4> ${this.props.oldSubTotal}</h4>
                    </Grid>
                  </Grid>
                )}
                <CardActions classes={{ root: styles.checkoutButtonStyle }}>
                  <Grid container>
                    {/* <Grid item xs={12} sm={12} style={{ width: "100%" }}>
                      {this.props.selectedProducts.length > 0 &&
                        this.isSubTotalDeliveryMinimumCompareError() && (
                          <ListItem classes={{ root: styles.noInsideBorder }}>
                            <ListItemText
                              className={styles.errorText}
                              primary={
                                "Minimum order of $" +
                                this.getMinimumDeliveryAmount() +
                                " required for delivery"
                              }
                            />
                          </ListItem>
                        )}
                      <Divider />
                    </Grid> */}
                    <CheckoutComponent
                      onRef={(ref) => (this.checkoutComponentRef = ref)}
                      onPlaceOrder={() => this.placeOrder()}
                      editModalUpdated={this.state.showEditModal}
                      isScheduled={this.state.isScheduled}
                      orderSentLoading={(loading) =>
                        this.orderSentLoading(loading)
                      }
                    />
                    {/* <Grid item xs={12} sm={12} style={{ width: "100%" }}>
                      <Button
                        disabled={
                          this.isCheckoutDisabled() ||
                          this.isSubTotalDeliveryMinimumCompareError()
                        }
                        style={{ width: "100%" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.props.checkout()}
                        className={
                          this.isSubTotalDeliveryMinimumCompareError() ||
                          this.isCheckoutDisabled
                            ? styles.orderDisabledButton
                            : styles.checkoutButton
                        }
                      >
                        Place Order
                      </Button>
                    </Grid> */}
                  </Grid>
                </CardActions>
              </CardContent>
            </Card>
            <Modal open={this.state.showEditModal}>
              <Box container className={styles.modalStyle}>
                {/* <Grid
              item
              xs={12}
              sm={12}
              style={{ flexDirection: "row", marginTop: 10 }}
              className={styles.padding30}
            > */}
                {!(
                  this.props.activeCustomer &&
                  this.props.activeCustomer.customer_id
                ) && (
                  <Grid contianer classes={{ root: styles.cardPadding }}>
                    <Grid item xs={12}>
                      <Box className={styles.commonPadding}>
                        <TextField
                          id="phone-basic"
                          label="Phone Number"
                          variant="outlined"
                          type="number"
                          size="small"
                          value={this.state.phoneNumber}
                          onChange={this.searchPhoneNumber}
                        />
                        {this.state.createCustomerNameBox && (
                          <TextField
                            id="name-basic"
                            label="Name"
                            size="small"
                            variant="outlined"
                            onChange={this.setNameForCreate}
                            style={{ marginLeft: 10 }}
                          />
                        )}

                        {this.state.customerList &&
                          this.state.customerList.length == 0 &&
                          this.state.createCustomerNameBox && (
                            <Button
                              size="medium"
                              variant="outlined"
                              onClick={() => this.createCustomer()}
                              color="secondary"
                              style={{ marginBottom: 15, marginLeft: 10 }}
                            >
                              Create
                            </Button>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Grid container>
                  <Grid xs={12}>
                    {this.state.customerList &&
                      this.state.customerList.length > 0 && (
                        <TableContainer component={Paper}>
                          <Table className={styles.table}>
                            <TableBody>
                              {this.state.customerList.map((row) => (
                                <TableRow
                                  key={row.customer_id}
                                  className={
                                    this.props.activeCustomer?.customer_id ==
                                    row.customer_id
                                      ? styles.activeRow
                                      : ""
                                  }
                                >
                                  <TableCell component="th" scope="row">
                                    {row.customer_name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.customer_phone}
                                  </TableCell>
                                  {/* <TableCell align="left">
                                  {row.customer_email}
                                </TableCell> */}
                                  <TableCell align="left">
                                    {this.props.activeCustomer?.customer_id ==
                                      row.customer_id && "Selected"}
                                    {!(
                                      this.props.activeCustomer?.customer_id ==
                                      row.customer_id
                                    ) && (
                                      <Button
                                        onClick={() => this.selectCustomer(row)}
                                        variant="outlined"
                                      >
                                        Select Customer
                                      </Button>
                                    )}
                                    {this.props.activeCustomer?.customer_id ==
                                      row.customer_id && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.changeUser()}
                                        style={{ marginLeft: 15 }}
                                      >
                                        Change
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container classes={{ root: styles.cardPadding }}>
                  <Grid item xs={12} sm={12}>
                    <div className={styles.commonPadding}>
                      {this.props.restaurantInfo?.info
                        ?.is_pick_up_available && (
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() => this.setOrderType(2)}
                          color={this.props.orderType == 2 ? `secondary` : ""}
                        >
                          Pickup
                        </Button>
                      )}
                      {this.props.restaurantInfo?.info?.is_delivery_available !=
                        0 && (
                        <Button
                          size="large"
                          variant="contained"
                          style={{ marginLeft: 5 }}
                          onClick={() => this.setOrderType(1)}
                          color={
                            this.props.orderType == 1 ? `secondary` : "inherit"
                          }
                        >
                          Delivery
                        </Button>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={styles.commonPadding}>
                      {(this.props.restaurantInfo?.info
                        ?.is_delivery_available != 2 ||
                        this.props.orderType == 2) && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ flexDirection: "row" }}
                            className={styles.padding30}
                          >
                            <div
                              className="font16"
                              style={{ paddingBottom: 10 }}
                            >
                              When do you need to order
                            </div>
                            <div>
                              {this.isAsapAvailable() == 1 && (
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    this.setState({ isScheduled: false })
                                  }
                                  style={{ marginRight: 5 }}
                                  color={
                                    this.state.isScheduled
                                      ? "inherit"
                                      : `secondary`
                                  }
                                >
                                  ASAP
                                </Button>
                              )}

                              {this.isScheduleAvailable() == 1 && (
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    this.setState({ isScheduled: true })
                                  }
                                  color={
                                    this.state.isScheduled
                                      ? `secondary`
                                      : "inherit"
                                  }
                                >
                                  Schedule Order
                                </Button>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={styles.padding30}
                          >
                            {this.state.isScheduled && (
                              <Grid
                                container
                                spacing={2}
                                style={{ flexDirection: "row", marginTop: 30 }}
                              >
                                <Grid item xs={6}>
                                  <FormControl fullWidth={true}>
                                    <InputLabel
                                      id="demo-simple-select-label"
                                      style={{ fontSize: 18 }}
                                    >
                                      Choose a date for your order
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={this.state.datePicked}
                                      onChange={(event) =>
                                        this.setState({
                                          datePicked: event.target.value,
                                        })
                                      }
                                    >
                                      {this.getAvailableDate()}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth={true}>
                                    <InputLabel
                                      id="demo-simple-select-label"
                                      style={{ fontSize: 18 }}
                                    >
                                      Choose a time for your order
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={this.state.timePicked}
                                      onChange={(event) =>
                                        this.setState({
                                          timePicked: event.target.value,
                                        })
                                      }
                                    >
                                      {this.getAvailableTime()}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>

                          {this.props.orderType == 1 && (
                            <Grid container className={styles.padding30}>
                              {/* Delivery Flow */}
                              {this.props.restaurantInfo?.info
                                ?.is_delivery_available == 1 && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: 40 }}
                                >
                                  {/* Address Input */}
                                  <Autocomplete
                                    id="combo-box-demo"
                                    noOptionsText="Type in the address"
                                    options={
                                      this.state.searchResults.length
                                        ? this.state.searchResults
                                        : this.props.activeCustomer
                                        ? this.props.activeCustomer.address_list
                                        : this.state.searchResults
                                    }
                                    getOptionLabel={(option) =>
                                      option.description
                                        ? option.description
                                        : option
                                    }
                                    fullWidth
                                    onInputChange={(event, value) =>
                                      this.getResults(value)
                                    }
                                    id="autocomplete"
                                    onChange={(event, value) =>
                                      this.getSelectedAddressInfo(value)
                                    }
                                    value={this.props.selectedAddress?.address}
                                    renderInput={(params) => (
                                      <TextField
                                        // ref={this.autocompleteInput}
                                        {...params}
                                        label="Delivery address"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                              )}
                              {this.state.deliveryAddressError && (
                                <div className="">
                                  Delivery is not available to this address
                                </div>
                              )}

                              {this.props.restaurantInfo?.info
                                ?.is_delivery_available == 1 &&
                                this.state.deliveryError && (
                                  <Grid item xs={12}>
                                    <div
                                      style={{ marginTop: 10, color: "red" }}
                                    >
                                      {this.state.deliveryError}
                                    </div>
                                  </Grid>
                                )}
                            </Grid>
                          )}
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.padding30}></Grid>
                <Grid
                  container
                  justify="flex-end"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    onClick={() => this.cancelCustomer()}
                    color="secondary"
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                  >
                    {this.state.editCustomer && "Clear Customer"}
                    {!this.state.editCustomer && "Cancel"}
                  </Button>
                  <Button
                    onClick={() => this.setCustomer()}
                    color="secondary"
                    style={{ marginRight: "10px" }}
                    variant="contained"
                    disabled={this.isContinueDisabled()}
                  >
                    {this.state.editCustomer && "Update Order Information"}
                    {!this.state.editCustomer && "Add Customer To Order"}
                  </Button>
                </Grid>
              </Box>
            </Modal>
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: OrderSelectors.getProducts(state),
    subTotal: OrderSelectors.getSubTotal(state),
    menuItems: MenuSelectors.getMenuItems(state),
    modifiers: MenuSelectors.getModifierList(state),
    variationList: MenuSelectors.getVariationList(state),
    selectedAddress: StoreLoginSelectors.getSelectedAddress(state),
    scheduleDateTime: StoreLoginSelectors.getScheduledDateTime(state),
    orderTypeText: StoreLoginSelectors.getOrderType(state),
    isScheduled: StoreLoginSelectors.getIsScheduled(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    orderType: StoreLoginSelectors.getOrderTypeId(state),
    totalCost: OrderSelectors.getTotalCost(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
    totalTaxes: OrderSelectors.getTotalTaxes(state),
    oldSubTotal: OrderSelectors.getOldSubTotal(state),
    businessId: state.menu.business_id,
    locationId: state.menu.location_id,
    userInfo: StoreLoginSelectors.getUserInfo(state),
    token: StoreLoginSelectors.getUserToken(state),
    // pastCardList: StoreLoginSelectors.getPastCardList(state),
    // scheduledDateTimeObject: LoginSelectors.getScheduledDateTimeObject(state),
    restaurantTimeZone: MenuSelectors.getTimezone(state),
    restaurantName: MenuSelectors.getRestaurantName(state),
    isUserLoggedIn: StoreLoginSelectors.isUserloggedIn(state),
    coverImage: MenuSelectors.getCoverImage(state),
    tax_list: MenuSelectors.getTaxList(state),
    couponInfo: OrderSelectors.getCouponInfo(state),
    tipValue: OrderSelectors.getTipValue(state),
    customerData: CustomerSelectors.getCustomerData(state),
    isStoreUserloggedIn: StoreLoginSelectors.isUserloggedIn(state),
    activeCustomer: CustomerSelectors.getActiveCustomer(state),
  };
};

export default withRouter(connect(mapStateToProps)(PhoneOrderSidbBar));
