import { call, put, select } from "redux-saga/effects";
import MenuActions from "../Redux/MenuRedux";

export function* getMenu(api, action) {
  console.log("action: ", action);
  const { business_id, location_id, order_type, date_time, web_menu } = action;

  // Wait for the updated tabValue from Redux state
  const storedTabValue = yield select((state) => state.menu.tabValue);
  console.log("getMenu storedTabValue from Redux: ", storedTabValue);

  let response;

  if (storedTabValue === 0) {
    // Call menu API when tabValue is 0
    response = yield call(
      api.getMenu,
      business_id,
      location_id,
      order_type,
      date_time,
      web_menu
    );
  } else {
    // Call staff menu API when tabValue is other than 0
    response = yield call(
      api.getStaffMenu,
      business_id,
      location_id,
      order_type
    );
  }

  if (response.ok) {
    const menuData = response.data;
    console.log("menuData get info: ", menuData);
    yield put(MenuActions.menuSuccess(menuData));
  } else {
    yield put(MenuActions.menuError());
  }
}

export function* menuRequestDineIn(api, action) {
  const { business_id, location_id, room_name, table_number } = action;

  const response = yield call(
    api.getMenuDineIn,
    business_id,
    location_id,
    room_name,
    table_number
  );

  if (response.ok) {
    const menuData = response.data;
    yield put(MenuActions.menuSuccess(menuData));
  } else yield put(MenuActions.menuError());
}
