import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router";
import AddressSelection from "./AddressSelection/AddressSelection";
import OrderScreen from "./OrderScreen/OrderScreen";
import Checkout from "./Checkout/Checkout";
import OrderHistory from "./OrderHistory/OrderHistory";
import PhoneLogin from "./PhoneLogin/PhoneLogin";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../Redux/StoreLoginRedux";

// function PhoneInitialPlaceholderScreen({ match }) {
//   return (
//     <Switch>
//       <Route exact path={`${match.path}`} component={PhoneLogin} />
//       <Route exact path={`${match.path}/order`} component={OrderScreen} />
//     </Switch>
//   );
// }

class ProtectedRoute extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { component: Component, ...props } = this.props;
    var restaurantDomain = this.props.match.params?.restaurant;
    var locationDoamin = this.props.match.params?.location;
    return (
      <Route
        {...props}
        render={(props) =>
          this.props.isStoreUserloggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={`/phone-order/${restaurantDomain}/${locationDoamin}`}
            />
          )
        }
      />
    );
  }
}

class PhoneInitialPlaceholderScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}`} component={PhoneLogin} />
        <ProtectedRoute
          path={`${this.props.match.path}/order`}
          component={OrderScreen}
          match={this.props.match}
          isStoreUserloggedIn={this.props.isStoreUserloggedIn}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isStoreUserloggedIn: StoreLoginSelectors.isUserloggedIn(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(PhoneInitialPlaceholderScreen)
);
