import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import {
  Grid,
  Box,
  withStyles,
  withTheme,
  Button,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  FormControlLabel,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import Api from "../../Services/Api";
import { toast } from "react-toastify";
// import styles from "./style.module.scss";

import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import CustomerActions, { CustomerSelectors } from "../../Redux/CustomerRedux";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../../Redux/StoreLoginRedux";
import MenuApi from "../../Services/MenuApi";
import { LoginSelectors } from "../../Redux/LoginRedux";
import { MenuSelectors } from "../../Redux/MenuRedux";
import createUrlToScreen from "../../Services/Utils";

const styles = (theme) => ({
  container: {
    backgroundColor: "#fff",
    padding: theme.spacing(3),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  orderButton: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  asterisk: {
    color: "red",
    fontSize: "23px",
    padding: "10px",
  },
});
const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "green", // Change this color to your desired checked color
    },
  },
  checked: {},
})(Checkbox);

class CreateAppointment extends Component {
  constructor(props) {
    super(props);
    console.log("CreateAppointment props ", props);
 
    let serviceList = this.selectedService();
    this.state = {
      serviceList: serviceList,
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      customerNotes: "",
      customerNameError: false,
      customerPhoneError: false,
      customerEmailError: false,
      customerNotesError: false,
      bookingResponse: "",
      date: new Date(),
      agreeToTerms: true,
      
    };
  }

  componentDidMount() {
    const { userInfo, isUserLoggedIn } = this.props;
    let phoneNumber = userInfo?.customer_phone;
    let formatedPhone = phoneNumber.replace(/[\s-()]/g, "");
    if (isUserLoggedIn && userInfo) {
      // If user is logged in and userInfo is available, populate the form fields
      this.setState({
        customerName: userInfo?.customer_name,
        customerPhone: formatedPhone,
        customerEmail: userInfo?.customer_email,
        customerNotes: userInfo?.customer_notes,
      });
    }
  }

  selectedService = () => {
    let result = [];
    let selectedProdcut = this.props.historyJsonItem
      ? this.props.historyJsonItem.selectedProducts
      : this.props.selectedProducts;

    let selectedMenuItem = this.props.historyJsonItem
      ? this.props.historyJsonItem.menuItems
      : this.props.menuItems;
    selectedProdcut.forEach((selectedProduct) => {
      let foundItem = selectedMenuItem.find(
        (menuItem) => menuItem.item_id === selectedProduct.product_id
      );
      if (foundItem) {
        result.push(foundItem.item_name);
      }
    });
    return result;
  };

  createAppointmentObject = () => {
    const {
      business_id,
      location_id,
      selectedProductsInfo,
      selectedTime,
      selected_date,
    } = this.props;

    let scheduledDate = "";
    // Combine date and time
    var combinedDateTime = selected_date + " " + selectedTime;

    var momentDate = moment(combinedDateTime, "YYYY-MM-DD HH:mm A");

    scheduledDate = momentDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

    const { customerName, customerPhone, customerEmail, customerNotes } =
      this.state;
    var productIds = "";
    var variationIds = "";
    var categoryIds = "";

    console.log("createAppointmentObject customerPhone ", customerPhone);

    // Loop over selectedProductsInfo array to extract product_id and variation_id
    selectedProductsInfo.forEach((productInfo) => {
      productIds = productInfo.product_id;
      variationIds = productInfo.variation_id;
      categoryIds = productInfo.staff_id
    });
    console.log("categoryIds ", categoryIds);
    
    let paymentInfo = {
      service_id: productIds,
      date: selected_date,
      business_id: business_id,
      location_id: location_id,
      notes: customerNotes ? customerNotes : " ",
      start_time: selectedTime,
      customer_name: customerName,
      customer_email: customerEmail,
      customer_phone: customerPhone,
      customer_address: null,
      staff_id: this.props.tabValue === 0 ? 0 : categoryIds,
      variation_id: variationIds ? variationIds : 0,
    };

    return paymentInfo;
  };

  sendAppointmentOrder = async () => {
    if (!this.validateForm()) {
      return;
    }

    let payment_method = 4;
    let scheduledDate = "";
    const { business_id, location_id, selectedTime, selected_date } =
      this.props;

    // Combine selected_date and selectedTime into one string
    var order_time_format = `${selected_date} ${selectedTime}`;

    // Parse the combined string into a moment object
    var momentDate = moment(order_time_format, "YYYY-MM-DD HH:mm"); // Adjust format if needed

    scheduledDate = momentDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

    // Bill Total
    const billTotal = this.state.finalOrderJson
      ? this.state.finalOrderJson.bill_total
      : this.props.totalCost;
    let paymentInfo = this.createAppointmentObject();
    console.log("appointment object ", paymentInfo);
    // New Order flow
    if (!this.state.finalOrderJson) {
      this.props.dispatch(
        OrderActions.placeBookingOrder(
          payment_method,
          business_id,
          location_id,
          this.props.userInfo,
          paymentInfo,
          scheduledDate,
          billTotal,
          this.props.orderType,
          this.props.selectedAddress,
          this.props.isScheduled,
          this.state.orderInstructions,
          this.props.match.url
        )
      );
    }
  };

  validateForm = () => {
    const { customerName, customerPhone, customerEmail } = this.state;

    let customerNameError = false;
    let customerPhoneError = false;
    let customerEmailError = false;

    if ((customerName || "").trim() === "") {
      customerNameError = true;
    }
    if ((customerPhone || "").trim() === "" || customerPhone.length !== 10) {
      customerPhoneError = true;
    }
    if ((customerEmail || "").trim() === "") {
      customerEmailError = true;
    }

    this.setState({
      customerNameError,
      customerPhoneError,
      customerEmailError,
    });

    return !customerNameError && !customerPhoneError && !customerEmailError;
  };

  handleInputChange = (event) => {
    let { id, value } = event.target;

    if (id === "customerPhone") {
      // Remove non-numeric characters
      value = value.replace(/\D/g, "").slice(0, 10);
    }

    this.setState({
      [id]: value,
      [`${id}Error`]: (value || "").trim() === "",
    });

    if (id === "customerPhone" && value.length === 10) {
      this.setState({ customerPhoneError: false });
    }
  };

  handleCheckboxChange = (event) => {
    this.setState({ agreeToTerms: event.target.checked });
  };

  render() {
    const {
      customerName,
      customerPhone,
      customerEmail,
      customerNotes,
      customerNameError,
      customerPhoneError,
      customerEmailError,
      agreeToTerms,
    } = this.state;
    const { classes } = this.props;

    console.log("this.state.bookingResponse ", this.state.bookingResponse);
    console.log("this.state.customerPhone ", customerPhone);

    const isFormValid =
      !customerNameError &&
      !customerPhoneError &&
      !customerEmailError &&
      agreeToTerms;

    return (
      <Box className={classes.container}>
        <Grid container>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Customer Information
          </div>

          <Divider style={{ color: "black" }} />
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              error={customerNameError}
            >
              <InputLabel htmlFor="customerName">
                Name<span className={classes.asterisk}>*</span>
              </InputLabel>
              <Input
                id="customerName"
                value={customerName}
                onChange={this.handleInputChange}
              />
              {customerNameError && (
                <FormHelperText>Please enter a name</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              error={customerEmailError}
            >
              <InputLabel htmlFor="customerEmail">
                Email<span className={classes.asterisk}>*</span>
              </InputLabel>
              <Input
                id="customerEmail"
                value={customerEmail}
                onChange={this.handleInputChange}
              />
              {customerEmailError && (
                <FormHelperText>Please enter an email</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              error={customerPhoneError}
            >
              <InputLabel htmlFor="customerPhone">
                Phone Number <span className={classes.asterisk}>*</span>
              </InputLabel>
              <Input
                id="customerPhone"
                value={customerPhone}
                onChange={this.handleInputChange}
              />
              {customerPhoneError && (
                <FormHelperText>
                  Please enter a valid phone number (10 digits)
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="customerNotes">Notes</InputLabel>
              <Input
                id="customerNotes"
                value={customerNotes}
                onChange={this.handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={agreeToTerms}
                  onChange={this.handleCheckboxChange}
                  name="agreeToTerms"
                  color="primary"
                  disabled={true} 
                />
              }
              label="Please send me reminder e-mail"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => this.props.handleCloseModal()}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.orderButton}
              onClick={this.sendAppointmentOrder}
              disabled={!isFormValid}
              style={{ marginLeft: "8px" }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: LoginSelectors.getUserInfo(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    selectedProducts: OrderSelectors.getProducts(state),
    totalCost: OrderSelectors.getTotalCost(state),
    menuItems: MenuSelectors.getMenuItems(state),
    tabValue: state.menu.tabValue,
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withStyles(styles)(CreateAppointment)))
);
