import React, { Component } from "react";

import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Api from "../../Services/Api";
import MenuApi from "../../Services/MenuApi";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loading from "../../components/Loading/Loading";
import styles from "./style.module.scss";
import moment from "moment";
import { Helmet } from "react-helmet";
import { withTheme } from "@material-ui/core/styles";

const google = window.google;

class AddressSelection extends Component {
  constructor(props) {
    super(props);
    console.log("AddressSelection props ", props);
    this.state = {
      isScheduled: false,
      datePicked: "",
      timePicked: "",
      selectedAddress: "",
      latitude: "",
      longitude: "",
      inputAddress: "",
      searchResults: [],
      restaurantClosed: false,
      restaurantErrorMessage: "",
      deliveryError: "",
      ageVerification: false,
      doorDashInfo: null,
      loading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getRestaurantInfo();
  }

  getRestaurantInfo = () => {
    const apiService = Api.create();
    apiService
      .getRestaurantInfo(
        this.props.match?.params?.restaurant,
        this.props.match?.params?.location == "main"
          ? null
          : this.props.match?.params?.location
      )
      .then((response) => {
        if (response.status == 200 && response.data?.business_id) {
          const responseInfo = response.data;
          this.props.dispatch(
            OrderActions.setNewRestaurant(
              responseInfo.business_id,
              responseInfo.location_id,
              responseInfo.intersection_plaza,
              false,
              responseInfo
            )
          );
          if (!responseInfo?.is_pick_up_available) {
            this.props.dispatch(LoginActions.setOrderType(1));
          } else {
            this.props.dispatch(LoginActions.setOrderType(2));
          }
          if (!this.isAsapAvailable()) {
            this.props.dispatch(LoginActions.setIsScheduled(true));
            this.setState({ isScheduled: true });
          }
          this.setState({ loading: false });
          // If is_service is true, call getMenu automatically
          if (this.props.restaurantInfo?.info?.is_service) {
            this.getMenu();
            this.setState({ loading: false });
          }
        } else {
          this.setState({
            restaurantClosed: true,
            restaurantErrorMessage: response?.data?.status_message,
            loading: false,
          });
        }
      });
  };

  isContinueDisabled = () => {
    if (
      this.state.isScheduled &&
      (this.state.datePicked == "" || this.state.timePicked == "")
    ) {
      return true;
    }
    if (this.props.orderType == 1 && this.state.latitude == "") {
      return true;
    }

    if (
      this.props.restaurantInfo?.info?.is_age_verificaton &&
      !this.state.ageVerification
    ) {
      return true;
    }
  };

  getMenu = () => {
    console.log(
      "this.props.restaurantInfo?.info?.is_service ",
      this.props.restaurantInfo?.info?.is_service
    );
    if (this.props.restaurantInfo?.info?.is_service) {
      // Call goToMenuScreen for regular service
      this.goToMenuScreen(
        this.props.restaurantInfo?.info?.business_id,
        this.props.restaurantInfo?.info?.location_id
      );

      // Call goToStaffMenuScreen for staff service
      this.goToStaffMenuScreen(
        this.props.restaurantInfo?.info?.business_id,
        this.props.restaurantInfo?.info?.location_id
      );
    } else {
      if (!this.isContinueDisabled()) {
        if (
          this.props.restaurantInfo?.info?.business_id &&
          this.props.restaurantInfo?.info?.location_id
        ) {
          const requestObj = {
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            order_type: this.props.orderType,
            business_id: this.props.restaurantInfo?.info?.business_id,
            location_id: this.props.restaurantInfo?.info?.location_id,
          };
          if (this.state.isScheduled) {
            const dateTime =
              this.state.datePicked + " " + this.state.timePicked;
            requestObj.date_time = dateTime;
          }
          const apiService = MenuApi.create();
          let moreRestaurantDetails = {};
          apiService.getRestaurantDetails(requestObj).then((response) => {
            console.log("getRestaurantDetails response", response);
            if (!response.data.available) {
              this.setState({ deliveryError: response.data.reason });
            } else {
              if (!response.data.status) {
                moreRestaurantDetails = response.data;
              }
              const restaurantInfo = {
                ...this.props.restaurantInfo?.info,
                ...moreRestaurantDetails,
                businessDomain: this.props.match?.params?.restaurant,
                locationDomain: this.props.match?.params?.location,
              };
              if (response.data) {
                this.setState({
                  doorDashInfo: response?.data?.door_dash_delivery_id,
                });
              }

              this.props.dispatch(
                OrderActions.setNewRestaurant(
                  restaurantInfo.business_id,
                  restaurantInfo.location_id,
                  restaurantInfo.intersection_plaza,
                  false,
                  restaurantInfo
                )
              );
              if (this.props.restaurantInfo?.info?.is_service) {
                this.goToMenuScreen(
                  this.props.restaurantInfo?.info?.business_id,
                  this.props.restaurantInfo?.info?.location_id
                );
              } else {
                if (this.props.orderType == 1) {
                  this.props.dispatch(
                    LoginActions.setAddress({
                      address: this.state.selectedAddress,
                      latitude: this.state.latitude,
                      longitude: this.state.longitude,
                    })
                  );
                }

                this.props.dispatch(
                  LoginActions.setIsScheduled(this.state.isScheduled)
                );

                if (this.props.isScheduled) {
                  const dateTime =
                    this.state.datePicked + " " + this.state.timePicked;
                  this.props.dispatch(
                    LoginActions.setScheduleDateTime(dateTime)
                  );
                  this.goToMenuScreen(
                    this.props.restaurantInfo?.info?.business_id,
                    this.props.restaurantInfo?.info?.location_id,
                    dateTime
                  );
                } else {
                  this.goToMenuScreen(
                    this.props.restaurantInfo?.info?.business_id,
                    this.props.restaurantInfo?.info?.location_id
                  );
                }
              }
            }
          });
        }
      }
    }
  };

  doordashInfo = () => {
    return this.state.doorDashInfo;
  };
  goToMenuScreen = (business_id, location_id, dateTime = null) => {
    this.props.dispatch(
      MenuActions.menuRequest(
        business_id,
        location_id,
        this.props.orderType,
        dateTime
      )
    );

    const path = `${this.props.match.url}/order`;
    this.props.history.push(path);
  };

  goToStaffMenuScreen = (business_id, location_id) => {
    this.props.dispatch(
      MenuActions.staffMenuRequest(
        business_id,
        location_id,
        this.props.orderType
      )
    );

    const path = `${this.props.match.url}/order`;
    this.props.history.push(path);
  };

  setOrderType = (orderType) => {
    this.props.dispatch(LoginActions.setOrderType(orderType));
    this.setState({ datePicked: "", timePicked: "", isScheduled: false });
  };

  isAsapAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_asap_delivery ? true : false;
    else return this.props.restaurantInfo?.info?.is_asap_pickup ? true : false;
  };

  isScheduleAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_scheduled_delivery
        ? true
        : false;
    else
      return this.props.restaurantInfo?.info?.is_scheduled_pickup
        ? true
        : false;
  };

  getAvailableDate = () => {
    const slots =
      this.props.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableDates = Object.keys(slots);
    return availableDates.map((date) => (
      <MenuItem value={date} key={date}>
        {moment(date).format("Do MMM, dddd")}
      </MenuItem>
    ));
  };

  getAvailableTime = () => {
    const slots =
      this.props.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableSlots = slots[this.state.datePicked] ?? [];
    return availableSlots.map((time) => (
      <MenuItem value={time}>{time}</MenuItem>
    ));
  };

  getResults = (query) => {
    if (query.length > 3) {
      this.setState({ inputAddress: query });
      this.searchPlaces(query).then((response) => {});
    }
  };

  searchPlaces = async (query) => {
    var options = {
      types: ["address"],
      componentRestrictions: { country: ["ca", "us"] },
    };
    var response = new google.maps.places.AutocompleteService();
    var self = this;
    var result = response.getPlacePredictions(
      {
        input: query,
        types: ["address"],
        componentRestrictions: { country: ["ca", "us"] },
      },
      function (predictions, status) {
        if (status == "OK") {
          self.setState((state) => {
            return {
              searchResults: predictions,
              selectedAddress: state.selectedAddress,
              latitude: state.latitude,
              longitude: state.longitude,
            };
          });
          return predictions;
        }
      }
    );
    return await result;
  };

  removeCountryText = (description) => {
    description = description.replace(", USA", "");
    return description.replace(", Canada", "");
  };

  getLocationInfo = async (place) => {
    var response = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    var self = this;
    response.getDetails(
      { placeId: place.place_id },
      function (exactLocation, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          self.setState((state) => {
            return {
              searchResults: [],
              selectedAddress:
                self.removeCountryText(place.description) +
                self.findPostalCode(exactLocation),
              latitude: exactLocation.geometry.location.lat(),
              longitude: exactLocation.geometry.location.lng(),
              inputAddress:
                place.description + self.findPostalCode(exactLocation),
            };
          });
        }
      }
    );
  };

  findPostalCode = (address) => {
    var postalCode = "";
    address.address_components.forEach((component) => {
      component.types.forEach((type) => {
        if (type == "postal_code") {
          postalCode = " , " + component.long_name;
        }
      });
    });
    return postalCode;
  };

  getSelectedAddressInfo = (place) => {
    if (place) {
      this.getLocationInfo(place);
    } else {
      this.setState({
        latitude: "",
        longitude: "",
        inputAddress: "",
      });
    }
  };

  setThemeColor = () => {
    if (
      this.props.restaurantInfo?.info?.theme_color &&
      this.props.restaurantInfo?.info?.theme_color !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(
        this.props.restaurantInfo?.info?.theme_color
      );
    }
  };

  render() {
    this.setThemeColor();
    return (
      <>
        {this.state.loading && (
          <Box style={{ minHeight: "100vh" }}>
            <Grid
              container
              justify="space-around"
              style={{ height: "100vh" }}
              alignItems="center"
              alignContent="center"
            >
              <Loading title="Loading Menu !!!" />
            </Grid>
          </Box>
        )}
        {this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              {this.props.restaurantInfo?.info?.business_name} | Order On The Go
            </title>
          </Helmet>
        )}
        {!this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              Order On The Go | Takeout and Delivery from your favorite
              restaurants
            </title>
          </Helmet>
        )}

        {this.props.restaurantInfo?.info?.is_service ? (
          <>
            <Box>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                className={styles.backgroudImage}
                style={{
                  minHeight: "calc(100vh - 40px)",
                  backgroundImage:
                    "url(" +
                    this.props.restaurantInfo?.info?.background_image +
                    ")",
                }}
              ></Grid>
            </Box>
          </>
        ) : (
          <Box>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              className={styles.backgroudImage}
              style={{
                minHeight: "calc(100vh - 40px)",
                backgroundImage:
                  "url(" +
                  this.props.restaurantInfo?.info?.background_image +
                  ")",
              }}
            >
              {this.state.restaurantClosed && (
                <Grid container justify="center" className={styles.mainSection}>
                  <Grid
                    container
                    style={{ minHeight: "30vh" }}
                    justify="center"
                    alignContent="center"
                  >
                    <span style={{ fontStyle: "italic" }}>
                      {this.state.restaurantErrorMessage}
                    </span>
                    {/* <div className={styles.restaurantHours}>
                    <div className="font20Bold">Restaurant Hours</div>
                    <div className={styles.restaurantHoursSubHeading}>
                      <div className="font16Bold">Delivery Hours</div>
                      <div>8:00am - 9:00pm</div>
                    </div>
                    <div className={styles.restaurantHoursSubHeading}>
                      <div className="font16Bold">Pickup Hours</div>
                      <div>8:00am - 9:00pm</div>
                    </div>
                  </div> */}
                  </Grid>
                </Grid>
              )}
              {!this.state.restaurantClosed && (
                <Grid container justify="center" className={styles.mainSection}>
                  <Grid item xs={12} className={styles.topSection}>
                    <Grid container justify="center" alignItems="center">
                      {this.props.restaurantInfo?.info?.logo && (
                        <Grid item xs={12}>
                          <div style={{ textAlign: "center" }}>
                            <img
                              className={styles.logo}
                              src={this.props.restaurantInfo?.info?.logo}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {this.props.restaurantInfo?.info
                          ?.logo_has_business_name !== "1" && (
                          <h1 style={{ textAlign: "center" }}>
                            {this.props.restaurantInfo?.info?.business_name}
                          </h1>
                        )}
                        <span style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_location}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.props.restaurantInfo?.info?.is_age_verificaton ===
                    1 && (
                    <Grid
                      item
                      xs={12}
                      className={styles.padding30}
                      style={{ flexDirection: "row", marginTop: 10 }}
                    >
                      <Grid container>
                        <Grid xs={12}>
                          <div className="font16" style={{ marginBottom: 10 }}>
                            Are you 19 years old?
                          </div>
                        </Grid>
                        <Grid xs={12}>
                          <Button
                            size="large"
                            variant="contained"
                            style={{ marginRight: 5 }}
                            onClick={() =>
                              this.setState({ ageVerification: true })
                            }
                            color={
                              this.state.ageVerification ? "secondary" : ""
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            size="large"
                            variant="contained"
                            onClick={() =>
                              this.setState({ ageVerification: false })
                            }
                            color={
                              this.state.ageVerification ? "" : "secondary"
                            }
                          >
                            No
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ flexDirection: "row", marginTop: 15 }}
                    className={styles.padding30}
                  >
                    <div className="font16" style={{ paddingBottom: 10 }}>
                      Choose order type
                    </div>
                    <div>
                      {this.props.restaurantInfo?.info?.is_pick_up_available !=
                        0 && (
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() => this.setOrderType(2)}
                          color={this.props.orderType == 2 ? `secondary` : ""}
                        >
                          Pickup
                        </Button>
                      )}
                      {this.props.restaurantInfo?.info?.is_delivery_available !=
                        0 && (
                        <Button
                          size="large"
                          variant="contained"
                          style={{ marginLeft: 5 }}
                          onClick={() => this.setOrderType(1)}
                          color={
                            this.props.orderType == 1 ? `secondary` : "inherit"
                          }
                        >
                          Delivery
                        </Button>
                      )}
                    </div>
                  </Grid>

                  {(this.props.restaurantInfo?.info?.is_delivery_available !=
                    2 ||
                    this.props.orderType == 2) && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ flexDirection: "row", marginTop: 15 }}
                        className={styles.padding30}
                      >
                        <div className="font16" style={{ paddingBottom: 10 }}>
                          When do you need to order
                        </div>
                        <div>
                          {this.isAsapAvailable() && (
                            <Button
                              variant="contained"
                              onClick={() =>
                                this.setState({ isScheduled: false })
                              }
                              style={{ marginRight: 5 }}
                              color={
                                this.state.isScheduled ? "inherit" : `secondary`
                              }
                            >
                              ASAP
                            </Button>
                          )}
                          {this.isScheduleAvailable() && (
                            <Button
                              variant="contained"
                              onClick={() =>
                                this.setState({ isScheduled: true })
                              }
                              color={
                                this.state.isScheduled ? `secondary` : "inherit"
                              }
                            >
                              Schedule Order
                            </Button>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} className={styles.padding30}>
                        {this.state.isScheduled && (
                          <Grid
                            container
                            style={{ flexDirection: "column", marginTop: 15 }}
                          >
                            <FormControl>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{ fontSize: 18 }}
                              >
                                Choose a date for your order
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.datePicked}
                                onChange={(event) =>
                                  this.setState({
                                    datePicked: event.target.value,
                                  })
                                }
                              >
                                {this.getAvailableDate()}
                              </Select>
                            </FormControl>
                            <FormControl style={{ marginTop: 10 }}>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{ fontSize: 18 }}
                              >
                                Choose a time for your order
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.timePicked}
                                onChange={(event) =>
                                  this.setState({
                                    timePicked: event.target.value,
                                  })
                                }
                              >
                                {this.getAvailableTime()}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>

                      {this.props.orderType == 1 && (
                        <Grid container className={styles.padding30}>
                          {/* Delivery Flow */}
                          {this.props.restaurantInfo?.info
                            ?.is_delivery_available == 1 && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ marginTop: 40 }}
                            >
                              {/* Address Input */}
                              <Autocomplete
                                // id="combo-box-demo"
                                noOptionsText="Type in the address"
                                options={this.state.searchResults}
                                getOptionLabel={(option) => option.description}
                                fullWidth
                                onInputChange={(event, value) =>
                                  this.getResults(value)
                                }
                                id="autocomplete"
                                onChange={(event, value) =>
                                  this.getSelectedAddressInfo(value)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    // ref={this.autocompleteInput}
                                    {...params}
                                    label="Delivery address"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {this.state.deliveryError && (
                            <Grid item xs={12}>
                              <div style={{ marginTop: 10, color: "red" }}>
                                {this.state.deliveryError}
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {/* {this.props.orderType == 2 && <Grid container>
                                  <span>Restaurant Address</span>
                              </Grid>} */}
                      <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                        <Button
                          style={{ width: "100%" }}
                          color="secondary"
                          variant="contained"
                          className={styles.continueButton}
                          onClick={() => this.getMenu()}
                          disabled={this.isContinueDisabled()}
                        >
                          Continue
                        </Button>
                      </Grid>
                    </>
                  )}

                  {this.props.restaurantInfo?.info?.is_delivery_available ==
                    2 &&
                    this.props.orderType == 1 && (
                      <Grid
                        container
                        className={styles.padding30}
                        direction="column"
                        style={{ marginTop: 40, paddingBottom: 30 }}
                      >
                        {/* Delivery Url */}
                        <div className="font16" style={{ paddingBottom: 10 }}>
                          Order from our delivery partners
                        </div>
                        <br />
                        <Grid container direction="row" justify="left">
                          {this.props.restaurantInfo?.info?.delivery_urls.map(
                            (url) => (
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: "5px" }}
                                onClick={() => {
                                  const win = window.open(url.url, "_blank");
                                  win.focus();
                                }}
                              >
                                {url.title}
                              </Button>
                            )
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        {/* <Switch>
          <Route exact path={`${this.props.match.path}/order`} component={OrderScreen} />
          <Route exact path={`${this.props.match.path}/checkout`} component={Checkout} />
          <Route exact path={`${this.props.match.path}/orderHistory`} component={OrderHistory} />
        </Switch> */}
      </>

      // <Button variant="contained" onClick={() => this.goToMenuScreen()}>Get Menu</Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    orderType: LoginSelectors.getOrderTypeId(state),
    isScheduled: LoginSelectors.getIsScheduled(state),
    selectedAddress: LoginSelectors.getSelectedAddress(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
    loadingState: MenuSelectors.getMenuLoadingState(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(AddressSelection))
);
