import React, { Component } from "react";
import { connect } from "react-redux";
import { MenuSelectors } from "../../Redux/MenuRedux";
import { LoginSelectors } from "../../Redux/LoginRedux";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import {
  Grid,
  Card,
  Modal,
  Box,
  TextField,
  CardHeader,
  CardContent,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  InputAdornment,
  Hidden,
  withStyles,
  withTheme,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { DeleteOutlineRounded } from "@material-ui/icons";
import moment from "moment-timezone";
import styles from "./style.module.scss";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { discountTypeLookup } from "../../Lookups/discountTypeLookup";
import MenuApi from "../../Services/MenuApi";
import calculateTip from "../../Services/Calculations";
import calculateDineInTip from "../../Services/CalculateDineInTip";
import Api from "../../Services/Api";
import LoginActions from "../../Redux/LoginRedux";
import Snackbar from "@material-ui/core/Snackbar";
import CreditCardInput from "react-credit-card-input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Remove from "@material-ui/icons/Remove";
import CancelIcon from "@material-ui/icons/Clear";
import Navbar from "../../components/Navbar/Navbar";
import createUrlToScreen from "../../Services/Utils";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import RedeemIcon from "@material-ui/icons/Redeem";
import Loading from "../../components/Loading/Loading";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import images from "react-payment-inputs/images";
import {
  PaymentInputsContainer,
  PaymentInputsWrapper,
} from "react-payment-inputs";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";

import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import loginApi from "../../Services/loginApi";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { encrypt } from "../../Services/crypt.service";

var valid = require("card-validator");

const classes = (theme) => {
  return {
    orderButton: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
};

class Checkout extends Component {
  constructor(props) {
    super(props);
    console.log("Checkout props ", props);
    let couponInput = "";

    this.state = {
      showPayment: false,
      selectedPayment:
        this.props.pastCardList?.length > 0 &&
        this.props.restaurantInfo.info?.is_credit_card_available == 1
          ? this.getLastCardUsedIndex()
          : "Add Card",
      finalOrderJson: null,
      restaurantDetails: null,
      productsInfo: null,
      cardNumber: "",
      expDate: "",
      cardType: "",
      cardCvv: "",
      oldCardUsed: this.props.pastCardList?.length > 0 ? true : false,
      orderSentLoading: false,
      tipIndex: 1,
      tipInputValue: "",
      showCoupon: false,
      couponInput: couponInput,
      couponValid: true,
      couponInfoForReorder: {},
      orderInstructions: "",
      removeCouponHook: true,
      showError: false,
      errorMessage: "",
      showloginModal: false,
      specialInstructionError: false,
      couponProcess: false,
      cardZip: "",
      cardNumberError: false,
      cardExpiryError: false,
      cardCvvError: false,
      cardZipPostalCodeError: false,
      openDialog: false,
      closeDialog: false,
      confirmDialog: false,
      cardSSLValue: null,
    };

    // }
  }

  zipCodeInputRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getLatestUserInfo();
    this.appleAutoCoupon();
    // TODO
    // this._subUserInfo = this.props.navigation.addListener(
    //     'focus',
    //     (payload) => {
    //         self.getLatestUserInfo();
    //     },
    // );
    // this._subCouponRemoval = this.props.navigation.addListener(
    //     'blur',
    //     (payload) => {
    //         self.removeCoupon();
    //     },
    // );
    if (this.props.selectedProducts.length == 0) {
      this.props.history.push(createUrlToScreen("", this.props.match.url));
    }

    if (this.isTipEnabled()) {
      // if (this.props.orderType !== 3) {
      this.props.dispatch(OrderActions.setTipIndex(1));
      var tipValue = calculateTip(
        1,
        this.props.subTotal,
        this.props.orderType == 3
      );
      this.props.dispatch(OrderActions.setTipInputValue(tipValue));
      // } else {
      //   this.props.dispatch(OrderActions.setTipIndex(2));
      //   var tipValue = calculateDineInTip(
      //     2,
      //     this.props.subTotal,
      //     this.props.restaurantInfo?.info?.tip_options
      //   );
      //   this.props.dispatch(OrderActions.setTipInputValue(tipValue));
      // }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.orderStatus === "Fail" && prevProps.orderStatus !== "Fail") {
      this.setState({ orderSentLoading: false });
    }
  }

  componentWillUnmount() {
    // if (this._subUserInfo.remove != undefined) {
    //     this._subUserInfo.remove();<
    // }
    // if (this._subCouponRemoval.remove != undefined) {
    //     this._subCouponRemoval.remove();
    // }
  }

  invalidateTip = () => {
    if (this.isTipEnabled()) {
      // if (this.props.orderType !== 3) {
      var tipValue = calculateTip(
        this.state.tipIndex,
        this.props.subTotal,
        this.props.orderType == 3
      );
      this.props.dispatch(OrderActions.setTipInputValue(tipValue));
      // } else {
      //   this.props.dispatch(OrderActions.setTipIndex(2));
      //   var tipValue = calculateDineInTip(
      //     2,
      //     this.props.subTotal,
      //     this.props.restaurantInfo?.info?.tip_options
      //   );
      //   this.props.dispatch(OrderActions.setTipInputValue(tipValue));
      // }
    }
  };

  appleAutoCoupon = () => {
    if (
      this.props.restaurantInfo.info?.auto_apply_coupon &&
      !this.isCouponApplied()
    ) {
      let apiService = Api.create();
      apiService
        .checkCoupon(
          this.props.restaurantInfo.info?.auto_apply_coupon?.coupon_code,
          this.props.businessId,
          this.props.locationId,
          this.props.token
        )
        .then((response) => {
          if (response.ok && response.data.status != "Failure") {
            this.props.dispatch(
              OrderActions.applyCoupon(
                response.data.coupon_type,
                response.data.coupon_value,
                this.props.tax_list,
                this.props.restaurantInfo.info?.auto_apply_coupon?.coupon_code
              )
            );
            this.setState({
              couponInput:
                this.props.restaurantInfo.info?.auto_apply_coupon?.coupon_code,
            });
          }
          this.invalidateTip();
        });
    }
  };

  getLastCardUsedIndex = () => {
    let lastCardIndex = 0;
    if (this.props.pastCardList.length > 0) {
      this.props.pastCardList.map((card, index) => {
        if (card.is_flag == 1) {
          lastCardIndex = index;
        }
      });
    }
    return lastCardIndex;
  };

  getLatestUserInfo = () => {
    if (this.props.isUserLoggedIn) {
      const apiService = loginApi.create();
      apiService
        .getLatestUserInfo(
          this.props.userInfo.customer_id,
          this.props.userInfo.token
        )
        .then((response) => {
          if (response?.data?.status == "Success") {
            this.props.dispatch(
              LoginActions.setPastAddressAndCard(
                response.data.address_list,
                response.data.card_list
              )
            );
            if (
              response.data.card_list.length > 0 &&
              this.props.restaurantInfo.info?.is_credit_card_available == 1
            ) {
              this.setState({ selectedPayment: this.getLastCardUsedIndex() });
            }
          }
        });
    }
  };

  getVariationInfo = (product) => {
    if (product.variation_id) {
      return (
        " (" +
        this.props.variationList.find(
          (variation) => variation.variation_id == product.variation_id
        ).variation_name +
        ")"
      );
    }
    return "";
  };

  getProductName = (id) => {
    let menuItem = this.props.menuItems.filter((item) => item.item_id == id);
    return menuItem[0].item_name;
  };

  getModifierName = (id) => {
    let modifierItem = this.props.modifiers.filter(
      (item) => item.modifier_id == id
    );
    return modifierItem[0].modifier_name;
  };

  isAsapAvailable = (restaurantInfo) => {
    if (this.props.orderType == 1) return restaurantInfo.is_asap_delivery;
    else return restaurantInfo.is_asap_pickup;
  };

  isScheduleAvailable = (restaurantInfo) => {
    if (this.props.orderType == 1) return restaurantInfo.is_scheduled_delivery;
    else return restaurantInfo.is_scheduled_pickup;
  };

  isRestaurantAvailable = async () => {
    if (this.props.orderType == 2 || !this.props.isScheduled) {
      const apiServiceRestaurant = Api.create();
      return await apiServiceRestaurant
        .getRestaurantInfo(
          this.props.match?.params?.restaurant,
          this.props.match?.params?.location == "main"
            ? null
            : this.props.match?.params?.location
        )
        .then((response) => {
          if (response.status == 200 && response.data?.business_id) {
            if (this.props.orderType == 2) {
              if (!response.data?.is_pick_up_available) {
                return false;
              }
            }
            if (!this.props.isScheduled) {
              if (!this.isAsapAvailable(response.data)) {
                return false;
              }
            } else {
              if (!this.isScheduleAvailable(response.data)) {
                return false;
              }
            }
            return true;
          } else {
            return false;
          }
        });
    } else {
      return true;
    }
  };

  DeleteCards = (value) => {
    if (value !== null) {
      this.setState({ openDialog: true, cardSSLValue: value });
    }
  };

  DeleteCardApiCall = async (OldCard) => {
    const token = this.props.token;
    const sslToken = OldCard;
    const apiKey = "633WKThVmnNv-DeUtk9K5ZDUg-K9HWV5D79htY";
    if (sslToken) {
      let apiService = Api.create();
      apiService.DeleteCardApi(sslToken, apiKey, token).then((response) => {
        if (response.status == 200 && response?.data?.status == "Success") {
          this.setState({ openDialog: false, showPayment: false });
          this.getLatestUserInfo();
        } else {
          this.setState({ openDialog: false, showPayment: false });
          this.getLatestUserInfo();
        }
      });
    }
  };

  sendOrder = async () => {
    if (!this.state.orderSentLoading) {
      this.setState({ orderSentLoading: true, removeCouponHook: false });
      const apiService = MenuApi.create();
      let payment_method = 2;
      let scheduledDate = "";
      if (this.props.isScheduled) {
        var momentDate = moment(
          this.props.scheduleDateTime,
          "YYYY-MM-DD HH:mm A"
        );

        scheduledDate = momentDate.format(
          moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
        );
      }

      const restaurantAvailable = await this.isRestaurantAvailable();

      // Check the restaurant status if order is ASAP or Pickup
      if (restaurantAvailable) {
        // Non - card processing flow
        if (
          typeof this.state.selectedPayment != "number" &&
          this.state.selectedPayment != "Add Card"
        ) {
          let payment_method = 4;
          if (this.state.selectedPayment == "Pay on Delivery - Card") {
            payment_method = 5;
          } else if (this.state.selectedPayment == "Pay on Delivery - Cash") {
            payment_method = 6;
          } else if (this.state.selectedPayment == "Pay Cash") {
            payment_method = 7;
          }

          // Bill Total
          const billTotal = this.state.finalOrderJson
            ? this.state.finalOrderJson.bill_total
            : this.props.totalCost;

          // New Order flow
          if (!this.state.finalOrderJson) {
            this.props.dispatch(
              OrderActions.placeOrder(
                payment_method,
                this.props.businessId,
                this.props.locationId,
                this.props.orderType,
                this.props.userInfo,
                this.props.selectedAddress,
                {},
                this.props.isScheduled,
                scheduledDate,
                billTotal,
                this.getDeliveryFee(),
                this.state.orderInstructions,
                this.isCouponApplied()
                  ? (+this.getCouponValue()).toFixed(2)
                  : "",
                this.props.match.url
              )
            );
          }
        } else {
          const billTotal = this.state.finalOrderJson
            ? this.state.finalOrderJson.bill_total
            : this.props.totalCost;
          let paymentInfo = this.createPaymentObject();
          if (!this.state.finalOrderJson) {
            this.props.dispatch(
              OrderActions.placeOrder(
                payment_method,
                this.props.businessId,
                this.props.locationId,
                this.props.orderType,
                this.props.userInfo,
                this.props.selectedAddress,
                paymentInfo,
                this.props.isScheduled,
                scheduledDate,
                billTotal,
                this.getDeliveryFee(),
                this.state.orderInstructions,
                this.isCouponApplied()
                  ? (+this.getCouponValue()).toFixed(2)
                  : ""
              )
            );
          }
        }
      } else {
        toast.error("Restaurant is not available at the moment");
        this.props.history.push(createUrlToScreen("", this.props.match.url));
      }
    }
  };

  validateStatus = (status) => {
    return status >= 200 && status < 300; // default
  };

  createPaymentObject = () => {
    let paymentInfo = {
      business_id: this.state.finalOrderJson
        ? this.state.finalOrderJson.business_id
        : this.props.businessId,
      location_id: this.state.finalOrderJson
        ? this.state.finalOrderJson.location_id
        : this.props.locationId,
      customer_name: this.props.userInfo.customer_name,
      customer_phone: this.props.userInfo.customer_phone,
      customer_id: this.props.userInfo.customer_id,
      bill_total: this.state.finalOrderJson
        ? this.state.finalOrderJson.bill_total
        : this.props.totalCost,
      token: this.props.userInfo.token,
    };

    if (
      this.state.selectedPayment !== "Add Card" &&
      typeof this.state.selectedPayment == "number"
    ) {
      paymentInfo.ssl_token =
        this.props.pastCardList[this.state.selectedPayment].ssl_token;
      paymentInfo.ssl_card_number =
        this.props.pastCardList[this.state.selectedPayment]?.ssl_card_number;
      paymentInfo.card_name =
        this.props.pastCardList[this.state.selectedPayment].card_name;
    } else {
      paymentInfo.card_number = this.state.cardNumber.replace(/\s+/g, "");
      paymentInfo.expire_month = this.state.expDate.substring(0, 2);
      paymentInfo.expire_year = this.state.expDate.substring(5, 7);
      paymentInfo.card_postal_code = this.state.cardZip;
      paymentInfo.cvv = this.state.cardCvv;
    }
    return paymentInfo;
  };

  displayProducts = () => {
    if (this.props.selectedProducts.length > 0) {
      return this.props.selectedProducts.map((product) => (
        <li key={product.product_id + "v"}>
          {product.quantity +
            " - " +
            this.getProductName(product.product_id) +
            this.getVariationInfo(product) +
            " = " +
            "$" +
            (+product.original_total).toFixed(2)}
        </li>
        // <Fragment key={product.product_id + 'v'}>
        //     <ListItem
        //         containerStyle={{ paddingRight: 0, paddingLeft: 0 }}
        //         leftElement={
        //             <span style={styles.productQuantity}>{product.quantity}</span>
        //         }
        //         title={
        //             this.getProductName(product.product_id) +
        //             this.getVariationInfo(product)
        //         }
        //         rightTitle={'$' + (+product.original_total).toFixed(2)}
        //         subtitle={
        //             product.modifiers.length > 0 ? (
        //                 <div style={styles.modifiersContainer}>
        //                     {product.modifiers.map((modifier) => (
        //                         <span>{this.getModifierName(modifier.modifier_id)}</span>
        //                     ))}
        //                 </div>
        //             ) : null
        //         }
        //     />
        // </Fragment>
      ));
    } else {
      return (
        <div>
          <span>Add Products to order</span>
        </div>
      );
    }
  };

  isTipEnabled = () => {
    if (this.state.finalOrderJson) {
      return this.state.restaurantDetails.is_tip_available == 1;
    } else {
      return (
        this.props.restaurantInfo.business_id != "" &&
        this.props.restaurantInfo.info.is_tip_available == 1
      );
    }
  };

  isPayAtStoreEnabled = () => {
    if (this.props.orderType == 2) {
      if (this.state.finalOrderJson) {
        return this.state.restaurantDetails.is_pay_at_store_available == 1;
      } else {
        return (
          this.props.restaurantInfo.business_id != "" &&
          this.props.restaurantInfo.info.is_pay_at_store_available == 1
        );
      }
    } else {
      return false;
    }
  };

  isPayCashEnable = () => {
    if (this.props.orderType == 3) {
      return (
        this.props.restaurantInfo.business_id &&
        this.props.restaurantInfo.info.is_cash == 1
      );
    }
  };

  isPayOnDeliveryCashEnabled = () => {
    if (this.props.orderType == 1) {
      if (this.state.finalOrderJson) {
        return (
          this.state.restaurantDetails.is_pay_on_delivery_cash_available == 1
        );
      } else {
        return (
          this.props.restaurantInfo.business_id != "" &&
          this.props.restaurantInfo.info.is_pay_on_delivery_cash_available == 1
        );
      }
    } else {
      return false;
    }
  };

  isPayOnDeliveryCardEnabled = () => {
    if (this.props.orderType == 1) {
      if (this.state.finalOrderJson) {
        return (
          this.state.restaurantDetails.is_pay_on_delivery_card_available == 1
        );
      } else {
        return (
          this.props.restaurantInfo.business_id != "" &&
          this.props.restaurantInfo.info.is_pay_on_delivery_card_available == 1
        );
      }
    } else {
      return false;
    }
  };

  onPaymentInfoChange = (form) => {
    if (
      form.status.number == "valid" &&
      form.status.expiry == "valid" &&
      form.status.cvc == "valid"
    ) {
      this.setState({
        cardNumber: form.values.number.replace(/\s/g, ""),
        expDate: form.values.expiry,
        cardCvv: form.values.cvc,
        cardType: form.values.type,
      });
    } else {
      this.setState({ cardNumber: "", cardCvv: "", expDate: "", cardType: "" });
    }
  };

  isSubTotalDeliveryMinimumCompareError = () => {
    if (this.props.orderType == 1) {
      if (this.state.finalOrderJson) {
        if (
          this.state.finalOrderJson.sub_total <
          +this.state.restaurantDetails.delivery_minimum_order_amount
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.props.restaurantInfo.business_id != "") {
        if (
          this.props.subTotal <
          +this.props.restaurantInfo.info.delivery_minimum_order_amount
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  isOrderDisable = () => {
    let isDisabled = false;
    isDisabled = this.isSubTotalDeliveryMinimumCompareError();
    if (!isDisabled) {
      if (this.state.selectedPayment !== null) {
        if (this.state.selectedPayment == "Add Card") {
          var numberValidation = valid.number(this.state.cardNumber);
          var cvvValidation3 = valid.cvv(this.state.cardCvv);
          var cvvValidation4 = valid.cvv(this.state.cardCvv, 4);
          var expValidation = valid.expirationDate(this.state.expDate);
          if (
            !(
              numberValidation.isValid &&
              expValidation.isValid &&
              (cvvValidation3.isValid || cvvValidation4.isValid)
            )
          ) {
            isDisabled = true;
          } else {
          }
        } else {
          isDisabled = false;
        }
      } else isDisabled = true;
    }

    if (this.state.specialInstructionError) {
      isDisabled = true;
    }

    return isDisabled;
  };

  changeTip = (selectedIndex) => {
    const tipValue =
      selectedIndex == 3
        ? ""
        : this.state.finalOrderJson
        ? calculateTip(
            selectedIndex,
            +this.state.finalOrderJson.sub_total,
            this.props.orderType == 3
          )
        : calculateTip(
            selectedIndex,
            this.props.subTotal,
            this.props.orderType == 3
          );
    this.setState({
      tipIndex: selectedIndex,
      tipInputValue:
        selectedIndex == 3
          ? ""
          : this.state.finalOrderJson
          ? calculateTip(
              selectedIndex,
              +this.state.finalOrderJson.sub_total,
              this.props.orderType == 3
            )
          : calculateTip(
              selectedIndex,
              this.props.subTotal,
              this.props.orderType == 3
            ),
    });
    if (!this.state.finalOrderJson) {
      this.props.dispatch(OrderActions.setTipIndex(selectedIndex));
      this.props.dispatch(OrderActions.setTipInputValue(tipValue));
    }
  };

  setDineInTip = (selectedIndex) => {
    this.setState({
      tipIndex: selectedIndex,
      tipInputValue: calculateDineInTip(
        selectedIndex,
        +this.props.subTotal,
        this.props.restaurantInfo.info.tip_options
      ),
    });
  };

  tipinputComponent = () => (
    <TextField
      id="outlined-basic"
      label="Enter amount"
      variant="outlined"
      onChange={(tipInput) => {
        this.setState({ tipInputValue: tipInput.target.value, tipIndex: 3 });
        this.props.dispatch(
          OrderActions.setTipInputValue(tipInput.target.value)
        );
      }}
    />
    // <Input
    //     keyboardType="numeric"
    //     placeholderTextColor="#a6a6a6"
    //     onFocus={() => {
    //         this.setState({ tipIndex: 3, tipInputValue: '' });
    //     }}
    //     inputStyle={styles.tipInputStyle}
    //     value={
    //         this.state.tipIndex == 3
    //             ? this.state.tipInputValue.toString()
    //             : this.state.finalOrderJson
    //                 ? calculateTip(
    //                     +this.state.tipIndex,
    //                     +this.state.finalOrderJson.sub_total,
    //                 )
    //                 : this.props.tipValue
    //     }
    //     leftIcon={
    //         <FontAwesome5Icon name="dollar-sign" style={{ color: '#a6a6a6' }} />
    //     }
    //     leftIconContainerStyle={{ paddingRight: 5 }}
    //     onChangeText={(tipInput) => {
    //         this.setState({ tipInputValue: tipInput, tipIndex: 3 });
    //         this.props.dispatch(OrderActions.setTipInputValue(tipInput));
    //     }}
    // />
  );

  getTipAmountForReorderHistory = () => {
    // Add tip available check here
    if (this.state.restaurantDetails.is_tip_available == 1) {
      if (this.state.tipIndex != 3) {
        return calculateTip(
          this.state.tipIndex,
          +this.state.finalOrderJson.sub_total,
          this.props.orderType == 3
        );
      } else {
        return (+this.state.tipInputValue).toFixed(2);
      }
    } else {
      return 0.0;
    }
  };

  applyCoupon = () => {
    this.setState({
      couponProcess: true,
    });
    if (this.state.couponInput != "") {
      if (this.state.finalOrderJson) {
      } else {
        let apiService = Api.create();
        apiService
          .checkCoupon(
            this.state.couponInput,
            this.props.businessId,
            this.props.locationId,
            this.props.token
          )
          .then((response) => {
            if (response.data.status != "Failure") {
              this.props.dispatch(
                OrderActions.applyCoupon(
                  response.data.coupon_type,
                  response.data.coupon_value,
                  this.props.tax_list,
                  this.state.couponInput
                )
              );
              this.setState({
                showCoupon: false,
                couponInput: "",
                couponProcess: false,
              });
              setTimeout(() => {
                this.invalidateTip();
              }, 500);
            } else {
              this.setState({ couponValid: false, couponProcess: false });
            }
          });
      }
    }
  };

  removeCoupon = () => {
    if (this.state.removeCouponHook && this.isCouponApplied()) {
      if (this.state.finalOrderJson) {
      } else {
        this.props.dispatch(OrderActions.removeCoupon(this.props.tax_list));
        setTimeout(() => {
          this.invalidateTip();
        }, 500);
      }
    }
  };

  getDiscountTotal = (discount_product_values) => {
    let totalDiscount = 0;
    discount_product_values.forEach((prod) => {
      totalDiscount += +(prod.oldValue - prod.newValue);
    });
    return totalDiscount.toFixed(2);
  };

  getTotalForReorderHistory = () => {
    // Add tip available check here
    let total = +this.state.finalOrderJson.bill_total;
    if (this.state.restaurantDetails.is_tip_available == 1) {
      if (this.getTipAmountForReorderHistory() > 0) {
        total += +this.getTipAmountForReorderHistory();
      }
    }
    if (this.props.orderType != 2) {
      total += +this.getDeliveryFee();
    }
    return (+total).toFixed(2);
    // ADD Delivery fee here
  };

  getDeliveryFee = () => {
    //Reorder flow

    var delivery_free = "0.00";
    if (this.props.orderType == 2) {
      return delivery_free;
    }
    if (this.state.finalOrderJson) {
      if (
        +this.state.restaurantDetails.delivery_free_for_orders_above == 0 ||
        +this.state.finalOrderJson.sub_total <
          this.state.restaurantDetails.delivery_free_for_orders_above
      ) {
        return (delivery_free = (+this.state.restaurantDetails
          .delivery_fee).toFixed(2));
      } else {
        return delivery_free;
      }
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        if (
          +this.props.restaurantInfo.info.delivery_free_for_orders_above == 0 ||
          +this.props.subTotal <
            this.props.restaurantInfo.info.delivery_free_for_orders_above
        ) {
          return (delivery_free = (+this.props.restaurantInfo.info
            .delivery_fee).toFixed(2));
        } else {
          return delivery_free;
        }
      } else {
        return delivery_free;
      }
    }
  };

  isFreeDeliveryNotAvailable = () => {
    if (this.state.finalOrderJson) {
      return +this.state.restaurantDetails.delivery_free_for_orders_above == 0;
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        return (
          +this.props.restaurantInfo.info.delivery_free_for_orders_above == 0
        );
      } else {
        return false;
      }
    }
  };

  isSubTotalLessThanFreeDeliveryAmount = () => {
    // Reorder flow
    if (this.state.finalOrderJson) {
      return (
        +this.state.restaurantDetails.delivery_free_for_orders_above == 0 ||
        +this.state.finalOrderJson.sub_total <
          this.state.restaurantDetails.delivery_free_for_orders_above
      );
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        return (
          +this.props.restaurantInfo.info.delivery_free_for_orders_above > 0 ||
          this.props.subTotal <
            +this.props.restaurantInfo.info.delivery_free_for_orders_above
        );
      } else {
        return false;
      }
    }
  };

  getMoreOrderValueForFreeDelivery = () => {
    if (this.state.finalOrderJson) {
      return (
        +this.state.restaurantDetails.delivery_free_for_orders_above -
        this.state.finalOrderJson.sub_total
      ).toFixed(2);
    } else {
      return (
        +this.props.restaurantInfo.info.delivery_free_for_orders_above -
        this.props.subTotal
      ).toFixed(2);
    }
  };

  openPrivacy = () => {
    let url = "";
    if (this.state.finalOrderJson) {
      url = this.state.restaurantDetails.privacy_url;
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        url = this.props.restaurantInfo.info.privacy_url;
      }
    }
    if (url != "") {
      // TODO
      // Linking.openURL(url);
    }
  };

  openTerms = () => {
    let url = "";
    if (this.state.finalOrderJson) {
      url = this.state.restaurantDetails.terms_url;
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        url = this.props.restaurantInfo.info.terms_url;
      }
    }
    if (url != "") {
      // TODO
      // Linking.openURL(url);
    }
  };

  isCouponApplied = () => {
    if (this.state.finalOrderJson) {
      return (
        this.state.couponInfoForReorder &&
        this.state.couponInfoForReorder.applied
      );
    } else {
      return this.props.couponInfo.applied;
    }
  };

  getCouponValue = () => {
    if (this.state.finalOrderJson) {
      if (this.state.couponInfoForReorder != {}) {
        return this.state.couponInfoForReorder.type ==
          discountTypeLookup.VALUE_BASED
          ? this.state.couponInfoForReorder.value
          : this.getDiscountTotal(
              this.state.couponInfoForReorder.discount_product_values
            );
      }
    } else {
      return this.props.couponInfo.type == discountTypeLookup.VALUE_BASED
        ? this.props.couponInfo.value
        : this.getDiscountTotal(this.props.couponInfo.discount_product_values);
    }
  };

  getCouponDisplayInfo = () => {
    if (this.state.finalOrderJson) {
      if (this.state.couponInfoForReorder != {}) {
        return (
          this.state.couponInfoForReorder.name +
          "(" +
          this.state.couponInfoForReorder.value +
          (this.state.couponInfoForReorder.type ==
          discountTypeLookup.VALUE_BASED
            ? "$"
            : "%") +
          ")"
        );
      }
    } else {
      return (
        this.props.couponInfo.name +
        "(" +
        this.props.couponInfo.value +
        (this.props.couponInfo.type == discountTypeLookup.VALUE_BASED
          ? "$"
          : "%") +
        ")"
      );
    }
  };

  getMinimumDeliveryAmount = () => {
    if (this.state.finalOrderJson) {
      return this.state.restaurantDetails.delivery_minimum_order_amount;
    } else {
      if (this.props.restaurantInfo.business_id != "")
        return this.props.restaurantInfo.info.delivery_minimum_order_amount;
      else return 0;
    }
  };

  closeCoupon = () => {
    this.setState({
      showCoupon: false,
      couponValid: true,
      couponInput: "",
    });
  };

  isPaymentDisabled = () => {
    var flag = true;
    if (this.state.selectedPayment == "Add Card") {
      var numberValidation = valid.number(this.state.cardNumber);
      var cvvValidation3 = valid.cvv(this.state.cardCvv);
      var cvvValidation4 = valid.cvv(this.state.cardCvv, 4);
      var expValidation = valid.expirationDate(this.state.expDate);
      var zipValidation = valid.postalCode(this.state.cardZip);
      if (
        numberValidation.isValid &&
        expValidation.isValid &&
        (cvvValidation3.isValid || cvvValidation4.isValid) &&
        zipValidation.isValid
      ) {
        flag = false;
      }
    } else {
      flag = false;
    }
    return flag;
  };

  savePayment = () => {
    if (this.state.selectedPayment == "Add Card") {
      var numberValidation = valid.number(this.state.cardNumber);
      var cvvValidation3 = valid.cvv(this.state.cardCvv);
      var cvvValidation4 = valid.cvv(this.state.cardCvv, 4);
      var expValidation = valid.expirationDate(this.state.expDate);
      var zipValidation = valid.postalCode(this.state.cardZip);
      if (
        numberValidation.isValid &&
        expValidation.isValid &&
        (cvvValidation3.isValid || cvvValidation4.isValid) &&
        zipValidation.isValid
      ) {
        this.setState({ showPayment: false });
      }
    } else {
      this.setState({ showPayment: false });
    }
  };

  getSelectedPaymentText = () => {
    if (this.state.selectedPayment == "Add Card") {
      if (this.state.cardNumber == "") {
        return "";
      } else {
        let maskedCardNumber = this.state.cardNumber;
        // if (maskedCardNumber.length == 20 || maskedCardNumber.length == 19)
        return (
          <div>
            <span style={{ paddingRight: 10, verticalAlign: "middle" }}>
              {this.state.cardType == "visa" && (
                <FontAwesomeIcon icon={faCcVisa} />
              )}
              {this.state.cardType == "mastercard" && (
                <FontAwesomeIcon icon={faCcMastercard} />
              )}
              {this.state.cardType == "american-express" && (
                <FontAwesomeIcon icon={faCcAmex} />
              )}
              {this.state.cardType !== "visa" &&
                this.state.cardType !== "mastercard" &&
                this.state.cardType !== "american-express" && (
                  <FontAwesomeIcon icon={faCreditCard} />
                )}
            </span>
            <span>
              {maskedCardNumber[0] +
                "*".repeat(maskedCardNumber.length == 20 ? 15 : 14) +
                maskedCardNumber[maskedCardNumber.length - 4] +
                maskedCardNumber[maskedCardNumber.length - 3] +
                maskedCardNumber[maskedCardNumber.length - 2] +
                maskedCardNumber[maskedCardNumber.length - 1]}
            </span>
          </div>
        );
        // return "";
      }
    } else if (typeof this.state.selectedPayment != "number") {
      return (
        <div>
          <span style={{ paddingRight: 8 }}>
            {this.state.cardType == "visa" && (
              <FontAwesomeIcon icon={faCcVisa} />
            )}
            {this.state.cardType == "mastercard" && (
              <FontAwesomeIcon icon={faCcMastercard} />
            )}
            {this.state.cardType == "american-express" && (
              <FontAwesomeIcon icon={faCcAmex} />
            )}
            {this.state.cardType !== "visa" &&
              this.state.cardType !== "mastercard" &&
              this.state.cardType !== "american-express" && (
                <FontAwesomeIcon icon={faCreditCard} />
              )}
          </span>
          <span>{this.state.selectedPayment}</span>
        </div>
      );
    } else if (typeof this.state.selectedPayment == "number") {
      return (
        <div>
          <span style={{ paddingRight: 8 }}>
            {this?.props?.pastCardList[this?.state?.selectedPayment]
              ?.card_name == "visa" && <FontAwesomeIcon icon={faCcVisa} />}
            {this?.props?.pastCardList[this?.state?.selectedPayment]
              ?.card_name == "mastercard" && (
              <FontAwesomeIcon icon={faCcMastercard} />
            )}
            {this?.props?.pastCardList[this?.state?.selectedPayment]
              ?.card_name == "american-express" && (
              <FontAwesomeIcon icon={faCcAmex} />
            )}
            {this?.props?.pastCardList[this?.state?.selectedPayment]
              ?.card_name !== "visa" &&
              this?.props?.pastCardList[this?.state?.selectedPayment]
                ?.card_name !== "mastercard" &&
              this?.props?.pastCardList[this?.state?.selectedPayment]
                ?.card_name !== "american-express" && (
                <FontAwesomeIcon icon={faCreditCard} />
              )}
          </span>
          <span>
            {
              this?.props?.pastCardList[this?.state?.selectedPayment]
                ?.ssl_card_number
            }
          </span>
        </div>
      );
    } else {
      return this?.state?.selectedPayment;
    }
  };

  confirmStartOver = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your order progress will be lost!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Start Over!",
    }).then((result) => {
      if (result.value) {
        this.props.dispatch(OrderActions.phoneOrderSuccessful());
        if (this.props.orderType == 3) {
          this.props.history.push(
            "/dine-in/" +
              this.props.restaurantInfo.info.businessDomain +
              "/" +
              this.props.restaurantInfo.info.locationDomain +
              "/" +
              this.props.restaurantInfo.info.room_name +
              "/" +
              this.props.restaurantInfo.info.table_number
          );
        } else {
          this.props.history.push(
            "/web/" +
              this.props.restaurantInfo.info.businessDomain +
              "/" +
              this.props.restaurantInfo.info.locationDomain
          );
        }
      }
    });
  };

  modifyOrder = () => {
    this.removeCoupon();
    this.props.history.push("./order");
  };
  setThemeColor = () => {
    if (
      this.props.restaurantInfo?.info?.theme_color &&
      this.props.restaurantInfo?.info?.theme_color !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(
        this.props.restaurantInfo?.info?.theme_color
      );
    }
  };

  handleChangeCardNumber = (cardNumber) => {
    var cardTypeArray = creditCardType(cardNumber.target.value);
    var cardType = cardTypeArray[0]?.type;
    this.setState({
      cardNumber: cardNumber.target.value,
      cardType: cardType,
      cardNumberError: false,
    });
  };

  handleCardNumberBlur = (cardNumber) => {
    var numberValidation = valid.number(cardNumber.target.value);
    if (cardNumber.target.value && !numberValidation.isValid) {
      this.setState({ cardNumberError: true });
    } else {
      this.setState({ cardNumberError: false });
    }
  };

  handleChangeExpiryDate = (expiryDate) => {
    this.setState({ expDate: expiryDate.target.value, cardExpiryError: false });
  };

  handleExpiryBlur = (expiryDate) => {
    var expValidation = valid.expirationDate(expiryDate.target.value);
    if (expiryDate.target.value && !expValidation.isValid) {
      this.setState({ cardExpiryError: true });
    } else {
      this.setState({ cardExpiryError: false });
    }
  };

  handleChangeCVC = (cvv) => {
    this.setState({ cardCvv: cvv.target.value, cardCvvError: false });
    let length = 3;
    if (this.state.cardType == "american-express") {
      length = 4;
    }

    if (length == cvv.target.value.length) {
      this.zipCodeInputRef.focus();
    }
  };

  handleCvvBlur = (cvv) => {
    var cvvValidation3 = valid.cvv(cvv.target.value);
    var cvvValidation4 = valid.cvv(cvv.target.value, 4);
    if (
      cvv.target.value &&
      !(cvvValidation3.isValid || cvvValidation4.isValid)
    ) {
      this.setState({ cardCvvError: true });
    } else {
      this.setState({ cardCvvError: false });
    }
  };

  handleZipCodeChange = (zip) => {
    this.setState({ cardZip: zip.target.value, cardZipPostalCodeError: false });
  };

  handleZipCodeBlur = (zip) => {
    var zipValidation = valid.postalCode(zip.target.value);
    if (zip.target.value && !zipValidation.isValid) {
      this.setState({ cardZipPostalCodeError: true });
    } else {
      this.setState({ cardZipPostalCodeError: false });
    }
  };

  cardInputError = () => {
    if (this.state.cardNumberError) {
      return "Card number is invalid";
    } else if (this.state.cardExpiryError) {
      return "Expiry is invalid";
    } else if (this.state.cardCvvError) {
      return "CVC is invalid";
    } else if (this.state.cardZipPostalCodeError) {
      return "Zip/Postal Code is invalid";
    }
    return null;
  };

  render() {
    this.setThemeColor();
    const { classes } = this.props;
    const creditCardPermission =
      this.props.restaurantInfo.info?.is_credit_card_available;

    return (
      <>
        {this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              {this.props.restaurantInfo?.info?.business_name} | Order On The Go
            </title>
          </Helmet>
        )}
        {!this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              Order On The Go | Takeout and Delivery from your favorite
              restaurants
            </title>
          </Helmet>
        )}

        <Dialog
          open={this.state.openDialog}
          // disableBackdropClick
          aria-labelledby="form-dialog-title"
          classes={{
            root: styles.dialogContainer,
            paperScrollPaper: styles.customMobileDialogContainer,
          }}
        >
          <DialogTitle id="form-dialog-title">
            Delete Card Confirmation
            <Button style={{ float: "right", paddingRight: 0 }}>
              <CancelIcon
                onClick={() => this.setState({ openDialog: false })}
              />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.paymentDialog}>
            <Typography>Are you sure you want to delete this card?.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ openDialog: false })}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.DeleteCardApiCall(this.state.cardSSLValue)}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.orderSentLoading && (
          <Box style={{ minHeight: "calc(100vh - 104px)" }}>
            <Grid
              container
              justify="space-around"
              style={{ height: "100vh" }}
              alignItems="center"
              alignContent="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                style={{ maxHeight: "20vh", maxWidth: "20vh" }}
              >
                <Loading title="Placing Order" />
              </Grid>
            </Grid>
          </Box>
        )}
        {!this.state.orderSentLoading && (
          <Box
            className={styles.backgroudImage}
            style={{
              minHeight: "calc(100vh - 104px)",
              backgroundImage: "url(" + this.props.coverImage + ")",
            }}
          >
            <Navbar />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                minHeight: "calc(100vh - 104px)",
              }}
            >
              <Grid item xs={12} sm={6}>
                <Card>
                  {!this.props.isUserLoggedIn && (
                    <CardContent
                      classes={{ root: styles.cardContentContainer }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ textAlign: "center", margin: "20px" }}
                      >
                        <span>
                          Please{" "}
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.setState({ showloginModal: true })
                            }
                          >
                            login
                          </Button>{" "}
                          to continue
                        </span>
                      </Grid>
                    </CardContent>
                  )}
                  {this.props.isUserLoggedIn && (
                    <CardContent
                      classes={{ root: styles.cardContentContainer }}
                    >
                      <Grid container className={styles.cardHeaderContainer}>
                        <Grid item xs={3} sm={6}>
                          <Box className={styles.cardHeader}>
                            {this.props.orderTypeText}
                          </Box>
                          <Hidden xsDown>
                            {this.props.selectedAddress &&
                              this.props.orderTypeText !== "Pickup" && (
                                <div className="font16">
                                  {this.props.selectedAddress.address}
                                </div>
                              )}
                            <div className="font16">
                              {this.props.isScheduled
                                ? "Scheduled at " +
                                  moment(
                                    this.props.scheduleDateTime,
                                    "YYYY-MM-DD HH:mm A"
                                  ).format("DD MMM YYYY LT")
                                : "ASAP"}
                            </div>
                          </Hidden>
                        </Grid>
                        <Grid item xs={9} sm={6} alignItems="flex-end">
                          <div style={{ float: "right", textAlign: "right" }}>
                            <Button
                              style={{ fontSize: 14 }}
                              onClick={() => this.confirmStartOver()}
                            >
                              Start Over
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => this.modifyOrder()}
                            >
                              Modify Order
                            </Button>
                          </div>
                        </Grid>
                        <Hidden smUp>
                          {this.props.orderType == 3 && (
                            <Grid
                              item
                              xs={12}
                              className={styles.mobileSubtitle}
                            >
                              <div className={styles.orderInfo}>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {this.props.orderType == 3 &&
                                    this.props.restaurantInfo?.info
                                      ?.waiter_name && (
                                      <div className={`${styles.asapText}`}>
                                        Served By{" "}
                                        {
                                          this.props.restaurantInfo?.info
                                            ?.waiter_name
                                        }
                                      </div>
                                    )}
                                </div>
                                <div className={`${styles.subhead}`}>
                                  {this.props.restaurantInfo?.info?.room_name} -
                                  Table #{" "}
                                  {
                                    this.props.restaurantInfo?.info
                                      ?.table_number
                                  }
                                </div>
                              </div>
                            </Grid>
                          )}
                          {this.props.orderType !== 3 && (
                            <Grid
                              item
                              xs={12}
                              className={styles.mobileSubtitle}
                            >
                              {this.props.selectedAddress &&
                                this.props.orderTypeText !== "Pickup" && (
                                  <div className="font16">
                                    {this.props.selectedAddress.address}
                                  </div>
                                )}
                              <div className="font16">
                                {this.props.isScheduled
                                  ? "Scheduled at " +
                                    moment(
                                      this.props.scheduleDateTime,
                                      "YYYY-MM-DD HH:mm A"
                                    ).format("DD MMM YYYY LT")
                                  : "ASAP"}
                              </div>
                            </Grid>
                          )}
                        </Hidden>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          {this.props.orderType !== 3 && (
                            <Box className={styles.additionalInformation}>
                              Provide any additional information for your order
                              (eg. APT#, Buzz Code)
                            </Box>
                          )}
                          {this.props.orderType == 3 && (
                            <Box className={styles.additionalInformation}>
                              Provide any additional information for your order
                              (eg. allergy, spicy level)
                            </Box>
                          )}
                          <Box classes={{ root: styles.instruction }}>
                            <TextField
                              variant="standard"
                              label="Instructions"
                              fullWidth={true}
                              onChange={(event) => {
                                var regex = new RegExp("^[a-zA-Z0-9# .,_ ]+$");
                                if (
                                  regex.test(event.target.value) ||
                                  !event.target.value
                                ) {
                                  this.setState({
                                    orderInstructions: event.target.value,
                                    specialInstructionError: false,
                                  });
                                } else {
                                  this.setState({
                                    orderInstructions: event.target.value,
                                    specialInstructionError: true,
                                  });
                                }
                              }}
                            ></TextField>
                            {this.state.specialInstructionError && (
                              <div className={styles.specialInstructionError}>
                                Special Characters are not allowed
                              </div>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} alignItems="flex-end"></Grid>
                      </Grid>
                      <Divider style={{ marginTop: "20px" }} />
                      {this.isTipEnabled() && (
                        <Grid
                          item
                          xs={12}
                          classes={{ root: styles.commonPadding }}
                        >
                          <div className={`font16Bold ${styles.categoryTitle}`}>
                            Add Tip
                          </div>
                          {this.props.orderType !== 3 && (
                            <Grid container justify="space-around">
                              <Button
                                color="primary"
                                variant={
                                  this.state.tipIndex == 4
                                    ? "contained"
                                    : "text"
                                }
                                size="small"
                                onClick={() => {
                                  this.setState({
                                    tipIndex: 4,
                                    tipInputValue: "0.00",
                                  });
                                  this.props.dispatch(
                                    OrderActions.setTipInputValue(0)
                                  );
                                }}
                              >
                                No Tip
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => this.changeTip(0)}
                                variant={
                                  this.state.tipIndex == 0
                                    ? "contained"
                                    : "text"
                                }
                              >
                                10%
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => this.changeTip(1)}
                                variant={
                                  this.state.tipIndex == 1
                                    ? "contained"
                                    : "text"
                                }
                              >
                                15%
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => this.changeTip(2)}
                                variant={
                                  this.state.tipIndex == 2
                                    ? "contained"
                                    : "text"
                                }
                              >
                                20%
                              </Button>
                              {/* <Button
                                onClick={() =>
                                  this.setState({
                                    tipIndex: 3,
                                    tipInputValue: "",
                                  })
                                }
                                size="small"
                              >
                                <TextField
                                  variant="standard"
                                  size="small"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon fontSize="inherit" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={`${
                                    this.state.tipIndex == 3
                                      ? this.state.tipInputValue.toString()
                                      : this.state.finalOrderJson
                                        ? calculateTip(
                                          +this.state.tipIndex,
                                          +this.state.finalOrderJson.sub_total
                                        )
                                        : this.props.tipValue
                                    }`}
                                  onChange={(tipInput) => {
                                    this.setState({
                                      tipInputValue: tipInput.target.value,
                                      tipIndex: 3,
                                    });
                                    this.props.dispatch(
                                      OrderActions.setTipInputValue(
                                        tipInput.target.value
                                      )
                                    );
                                  }}
                                />
                              </Button> */}
                            </Grid>
                          )}
                          {this.props.orderType == 3 && (
                            <Grid container justify="space-around">
                              <Button
                                color="primary"
                                variant={
                                  this.state.tipIndex == 4
                                    ? "contained"
                                    : "text"
                                }
                                size="small"
                                onClick={() => {
                                  this.setState({
                                    tipIndex: 4,
                                    tipInputValue: "0.00",
                                  });
                                  this.props.dispatch(
                                    OrderActions.setTipInputValue(0)
                                  );
                                }}
                              >
                                No Tip
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => this.changeTip(0)}
                                variant={
                                  this.state.tipIndex == 0
                                    ? "contained"
                                    : "text"
                                }
                              >
                                15%
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => this.changeTip(1)}
                                variant={
                                  this.state.tipIndex == 1
                                    ? "contained"
                                    : "text"
                                }
                              >
                                18%
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => this.changeTip(2)}
                                variant={
                                  this.state.tipIndex == 2
                                    ? "contained"
                                    : "text"
                                }
                              >
                                20%
                              </Button>
                              {/* <Button
                                onClick={() =>
                                  this.setState({
                                    tipIndex: 3,
                                    tipInputValue: "",
                                  })
                                }
                                size="small"
                              >
                                <TextField
                                  variant="standard"
                                  size="small"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon fontSize="inherit" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={`${
                                    this.state.tipIndex == 3
                                      ? this.state.tipInputValue.toString()
                                      : this.state.finalOrderJson
                                        ? calculateTip(
                                          +this.state.tipIndex,
                                          +this.state.finalOrderJson.sub_total
                                        )
                                        : this.props.tipValue
                                    }`}
                                  onChange={(tipInput) => {
                                    this.setState({
                                      tipInputValue: tipInput.target.value,
                                      tipIndex: 3,
                                    });
                                    this.props.dispatch(
                                      OrderActions.setTipInputValue(
                                        tipInput.target.value
                                      )
                                    );
                                  }}
                                />
                              </Button> */}
                            </Grid>
                          )}
                          {/* <ButtonGroup
                            variant="text"
                            color="primary"
                            fullWidth={true}
                            aria-label="text primary button group"
                          >
                            <Button
                              color="primary"
                              variant={
                                this.state.tipIndex == 4 ? "contained" : "text"
                              }
                              onClick={() => {
                                this.setState({
                                  tipIndex: 4,
                                  tipInputValue: "0.00",
                                });
                                this.props.dispatch(
                                  OrderActions.setTipInputValue(0)
                                );
                              }}
                            >
                              No Tip
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.changeTip(0)}
                              variant={
                                this.state.tipIndex == 0 ? "contained" : "text"
                              }
                            >
                              10%
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.changeTip(1)}
                              variant={
                                this.state.tipIndex == 1 ? "contained" : "text"
                              }
                            >
                              15%
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.changeTip(2)}
                              variant={
                                this.state.tipIndex == 2 ? "contained" : "text"
                              }
                            >
                              20%
                            </Button>
                            <Button
                              onClick={() =>
                                this.setState({
                                  tipIndex: 3,
                                  tipInputValue: "",
                                })
                              }
                            >
                              <TextField
                                variant="standard"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoneyIcon fontSize="inherit" />
                                    </InputAdornment>
                                  ),
                                }}
                                value={`${
                                  this.state.tipIndex == 3
                                    ? this.state.tipInputValue.toString()
                                    : this.state.finalOrderJson
                                    ? calculateTip(
                                        +this.state.tipIndex,
                                        +this.state.finalOrderJson.sub_total
                                      )
                                    : this.props.tipValue
                                }`}
                                onChange={(tipInput) => {
                                  this.setState({
                                    tipInputValue: tipInput.target.value,
                                    tipIndex: 3,
                                  });
                                  this.props.dispatch(
                                    OrderActions.setTipInputValue(
                                      tipInput.target.value
                                    )
                                  );
                                }}
                              />
                            </Button>
                          </ButtonGroup> */}
                        </Grid>
                      )}
                      <Divider style={{ marginTop: "20px" }} />
                      <List classes={{ root: styles.commonPadding }}>
                        <ListItem
                          classes={{ root: styles.noInsideBorder }}
                          button
                          onClick={() => this.setState({ showPayment: true })}
                        >
                          <ListItemText
                            primary={
                              this.state.selectedPayment == "Add Card"
                                ? this.state.cardNumber
                                  ? "Payment Method"
                                  : "Add Payment"
                                : "Payment Method"
                            }
                            secondary={
                              this.state.selectedPayment == "Add Card" &&
                              this.isPaymentDisabled() ? (
                                <div
                                  style={{
                                    color: "#c9444d",
                                    paddingRight: 40,
                                  }}
                                >
                                  Please choose your payment method to place
                                  your order
                                </div>
                              ) : creditCardPermission == 1 ? (
                                this.getSelectedPaymentText()
                              ) : (
                                this.getSelectedPaymentText()
                              )
                            }
                          />
                          <ListItemSecondaryAction
                            onClick={() => this.setState({ showPayment: true })}
                          >
                            {this.state.selectedPayment == "Add Card" ? (
                              this.state.cardNumber ? (
                                <CreditCardIcon />
                              ) : (
                                <CreditCardIcon />
                              )
                            ) : (
                              <CreditCardIcon />
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                      <Divider />
                      <List classes={{ root: styles.commonPadding }}>
                        {!this.isCouponApplied() && (
                          <ListItem
                            classes={{ root: styles.noInsideBorder }}
                            button
                            onClick={() => this.setState({ showCoupon: true })}
                          >
                            <ListItemText primary="Add Coupon" />
                            <ListItemSecondaryAction
                              onClick={() =>
                                this.setState({ showCoupon: true })
                              }
                            >
                              <RedeemIcon />
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                        {this.isCouponApplied() && (
                          <ListItem
                            button
                            classes={{ root: styles.noInsideBorder }}
                          >
                            <ListItemText
                              primary={
                                "Coupon Applied : " +
                                this.getCouponDisplayInfo()
                              }
                            />
                            <ListItemSecondaryAction
                              onClick={this.removeCoupon}
                            >
                              <Remove />
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </List>
                      <Divider />
                      <div>
                        <List className={styles.checkoutMenuUl}>
                          {this.props.selectedProducts.map((product, index) => (
                            <>
                              <ListItem
                                alignItems="flex-start"
                                classes={{ root: styles.listItemPadding }}
                              >
                                <ListItemAvatar
                                  classes={{ root: styles.itemCountStyle }}
                                >
                                  <span>{product.quantity}</span>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    this.getProductName(product.product_id) +
                                    this.getVariationInfo(product)
                                  }
                                  secondary={
                                    product.modifiers.length ? (
                                      <Grid item xs={12} sm={12}>
                                        <List>
                                          {product.modifiers.map((modifier) => (
                                            <ListItem
                                              classes={{
                                                root: styles.modifierItemStyle,
                                              }}
                                              key={
                                                "modi" + modifier.modifier_id
                                              }
                                            >
                                              {this.getModifierName(
                                                modifier.modifier_id
                                              )}
                                            </ListItem>
                                          ))}
                                        </List>
                                      </Grid>
                                    ) : null
                                  }
                                />
                                <ListItemText
                                  classes={{ root: styles.amountStyle }}
                                  edge="end"
                                  primary={
                                    "$" + (+product.original_total).toFixed(2)
                                  }
                                />
                              </ListItem>
                              <Divider variant="fullWidth" component="li" />
                            </>
                          ))}
                        </List>
                        <Grid container direction="row" justify="space-between">
                          <span className={styles.infoText}>Subtotal</span>
                          <span className={styles.infoText}>
                            {"$" + this.props.oldSubTotal}
                          </span>
                        </Grid>
                        {this.props.orderType == 1 && (
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <span className={styles.infoText}>
                              Delivery Fee
                            </span>
                            <span className={styles.infoText}>
                              {"$" + this.getDeliveryFee()}
                            </span>
                          </Grid>
                        )}
                        {this.props.orderType == 1 &&
                          this.isSubTotalLessThanFreeDeliveryAmount() && (
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              classes={{ root: styles.padding16 }}
                            >
                              <span className={`${styles.infoText} subhead`}>
                                Add food worth ${" "}
                                {this.getMoreOrderValueForFreeDelivery()} and
                                get free delivery
                              </span>
                            </Grid>
                          )}
                        {this.isCouponApplied() && (
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <span className={styles.discountText}>
                              Discount
                            </span>
                            <span className={styles.discountText}>
                              {"- $" + (+this.getCouponValue()).toFixed(2)}
                            </span>
                          </Grid>
                        )}
                        {
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <span className={styles.infoText}>Tax</span>
                            <span className={styles.infoText}>
                              {" "}
                              $
                              {this.state.finalOrderJson
                                ? (+this.state.finalOrderJson.tax).toFixed(2)
                                : this.props.totalTaxes}
                            </span>
                          </Grid>
                        }
                        {/* TODO check tip enabled */}
                        {this.isTipEnabled() && (
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <span className={styles.infoText}>Tip</span>
                            <span className={styles.infoText}>
                              {" "}
                              {"$" +
                                (this.state.finalOrderJson
                                  ? this.getTipAmountForReorderHistory()
                                  : (+this.props.tipValue).toFixed(2))}
                            </span>
                          </Grid>
                        )}
                      </div>
                      <Divider className={styles.lastDivBeforeTotal} />
                      <List classes={{ root: styles.listItemPadding }}>
                        <ListItem classes={{ root: styles.noInsideBorder }}>
                          <ListItemText
                            classes={{ root: styles.totalTitleText }}
                            primary="Total"
                          />
                          <ListItemText classes={{ root: styles.totalText }}>
                            {"$" +
                              (this.state.finalOrderJson
                                ? this.getTotalForReorderHistory()
                                : this.props.totalCost)}
                          </ListItemText>
                        </ListItem>
                        {this.isSubTotalDeliveryMinimumCompareError() && (
                          <ListItem classes={{ root: styles.noInsideBorder }}>
                            <ListItemText
                              className={styles.errorText}
                              primary={
                                "Minimum order of $" +
                                this.getMinimumDeliveryAmount() +
                                " required for delivery"
                              }
                            />
                          </ListItem>
                        )}
                      </List>
                      <Button
                        className={
                          this.isOrderDisable()
                            ? styles.orderDisabledButton
                            : ` ${styles.orderButton} ${classes.orderButton}`
                        }
                        disabled={this.isOrderDisable()}
                        onClick={() => this.sendOrder()}
                      >
                        Place Order
                      </Button>
                    </CardContent>
                  )}
                </Card>
                <Grid container space={2} justify="right" alignItems="right">
                  <Grid item></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        <Dialog
          open={this.state.showCoupon}
          disableBackdropClick
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <span style={{ paddingTop: "5px" }}>Apply Coupon</span>
            <Button
              onClick={() => this.setState({ showCoupon: false })}
              style={{ float: "right", paddingRight: 0 }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.couponDialog}>
            <TextField
              style={{ width: "100%", marginBottom: "20px" }}
              autoFocus
              margin="dense"
              id="coupon"
              label="Coupon Code"
              error={!this.state.couponValid}
              onChange={(value) =>
                this.setState({ couponInput: value.target.value })
              }
              value={this.state.couponInput}
              helperText={!this.state.couponValid ? "Enter valid coupon" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.applyCoupon()}
              color="primary"
              className={`${styles.orderButton} ${classes.orderButton}`}
              disabled={this.state.couponProcess}
            >
              {!this.state.couponProcess && <span>Apply Coupon</span>}
              {this.state.couponProcess && <span>Validating Coupon..</span>}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showPayment}
          onClose={() => this.setState({ showPayment: false })}
          aria-labelledby="form-dialog-title"
          classes={{
            root: styles.dialogContainer,
            paperScrollPaper: styles.customMobileDialogContainer,
          }}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">
            Select Payment
            <Button
              onClick={() => {
                this.setState({ showPayment: false });
                // Ensure focus is moved back to the triggering element
                this.triggerElement.focus();
              }}
              style={{ float: "right", paddingRight: 0 }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.paymentDialog}>
            <DialogContent className={styles.paymentDialog}>
              <RadioGroup aria-label="payment" name="payment">
                {this.props.pastCardList?.length != 0 &&
                  creditCardPermission == 1 &&
                  this.props.pastCardList?.map((oldCard, index) => (
                    <FormControlLabel
                      control={
                        <Radio checked={this.state.selectedPayment == index} />
                      }
                      label={
                        <Grid container>
                          <Grid item xs={2}>
                            {oldCard.card_name == "visa" && (
                              <FontAwesomeIcon icon={faCcVisa} />
                            )}
                            {oldCard.card_name == "mastercard" && (
                              <FontAwesomeIcon icon={faCcMastercard} />
                            )}
                            {oldCard.card_name == "american-express" && (
                              <FontAwesomeIcon icon={faCcAmex} />
                            )}
                            {oldCard.card_name !== "visa" &&
                              oldCard.card_name !== "mastercard" &&
                              oldCard.card_name !== "american-express" && (
                                <FontAwesomeIcon icon={faCreditCard} />
                              )}
                          </Grid>
                          <Grid item xs={8}>
                            {oldCard.ssl_card_number}
                          </Grid>
                          <Grid item xs={2}>
                            <DeleteOutlineRounded
                              fontSize="18px"
                              style={{ color: "red", marginRight: "50px" }}
                              onClick={() => {
                                this.DeleteCards(oldCard?.ssl_token);
                              }}
                            />
                          </Grid>
                        </Grid>
                      }
                      onClick={() => {
                        this.setState({
                          selectedPayment: index,
                          oldCardUsed: true,
                          cardType: oldCard.card_name,
                        });
                      }}
                    />
                  ))}
                {this.isPayAtStoreEnabled() && (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.selectedPayment == "Pay at Store"}
                      />
                    }
                    label={"Pay at Store"}
                    onClick={() =>
                      this.setState({
                        selectedPayment: "Pay at Store",
                        oldCardUsed: false,
                      })
                    }
                  />
                )}
                {this.isPayCashEnable() && (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.selectedPayment == "Pay Cash"}
                      />
                    }
                    label={"Pay Cash"}
                    onClick={() =>
                      this.setState({
                        selectedPayment: "Pay Cash",
                        oldCardUsed: false,
                      })
                    }
                  />
                )}
                {this.isPayOnDeliveryCashEnabled() && (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          this.state.selectedPayment == "Pay on Delivery - Cash"
                        }
                      />
                    }
                    label={"Pay on Delivery - Cash"}
                    onClick={() =>
                      this.setState({
                        selectedPayment: "Pay on Delivery - Cash",
                        oldCardUsed: false,
                      })
                    }
                  />
                )}
                {this.isPayOnDeliveryCardEnabled() && (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          this.state.selectedPayment == "Pay on Delivery - Card"
                        }
                      />
                    }
                    label={"Pay on Delivery - Card"}
                    onClick={() =>
                      this.setState({
                        selectedPayment: "Pay on Delivery - Card",
                        oldCardUsed: false,
                      })
                    }
                  />
                )}
                {creditCardPermission == 1 && (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.selectedPayment == "Add Card"}
                      />
                    }
                    label={"Add Card"}
                    onClick={() =>
                      this.setState({
                        selectedPayment: "Add Card",
                        oldCardUsed: false,
                      })
                    }
                  />
                )}
                {creditCardPermission == 1 &&
                  this.state.selectedPayment == "Add Card" && (
                    <PaymentInputsContainer>
                      {({
                        meta,
                        getCardImageProps,
                        getCardNumberProps,
                        getExpiryDateProps,
                        getCVCProps,
                      }) => (
                        <div>
                          <PaymentInputsWrapper>
                            <svg {...getCardImageProps({ images })} />
                            <input
                              {...getCardNumberProps({
                                onChange: this.handleChangeCardNumber,
                                onBlur: this.handleCardNumberBlur,
                              })}
                              value={this.state.cardNumber}
                            />
                            <input
                              {...getExpiryDateProps({
                                onChange: this.handleChangeExpiryDate,
                                onBlur: this.handleExpiryBlur,
                              })}
                              value={this.state.expDate}
                            />
                            <input
                              {...getCVCProps({
                                onChange: this.handleChangeCVC,
                                onBlur: this.handleCvvBlur,
                              })}
                              value={this.state.cardCvv}
                            />
                            <input
                              name="zip"
                              type="text"
                              placeholder="Zip/Postal"
                              onChange={this.handleZipCodeChange}
                              onBlur={this.handleZipCodeBlur}
                              value={this.state.cardZip}
                              maxLength="6"
                              ref={(input) => {
                                this.zipCodeInputRef = input;
                              }}
                              style={{ marginLeft: 5, width: "20%" }}
                            />
                          </PaymentInputsWrapper>
                          {this.cardInputError() && (
                            <div
                              style={{
                                color: "#c9444d",
                                fontSize: "0.75rem",
                                marginTop: "0.25rem",
                              }}
                            >
                              {this.cardInputError()}
                            </div>
                          )}
                        </div>
                      )}
                    </PaymentInputsContainer>
                  )}
              </RadioGroup>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.savePayment()}
              disabled={this.isPaymentDisabled()}
            >
              Save Payment
            </Button>
          </DialogActions>
        </Dialog>

        <Modal open={this.state.showloginModal} disableBackdropClick>
          <div className={styles.loginModalStyle}>
            <LoginSignup
              nextScreen="null"
              onClose={() => this.setState({ showloginModal: false })}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: OrderSelectors.getProducts(state),
    totalCost: OrderSelectors.getTotalCost(state),
    menuItems: MenuSelectors.getMenuItems(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
    totalTaxes: OrderSelectors.getTotalTaxes(state),
    subTotal: OrderSelectors.getSubTotal(state),
    oldSubTotal: OrderSelectors.getOldSubTotal(state),
    businessId: state.menu.business_id,
    locationId: state.menu.location_id,
    orderType: LoginSelectors.getOrderTypeId(state),
    orderTypeText: LoginSelectors.getOrderType(state),
    modifiers: MenuSelectors.getModifierList(state),
    userInfo: LoginSelectors.getUserInfo(state),
    variationList: MenuSelectors.getVariationList(state),
    token: LoginSelectors.getUserToken(state),
    selectedAddress: LoginSelectors.getSelectedAddress(state),
    pastCardList: LoginSelectors.getPastCardList(state),
    isScheduled: LoginSelectors.getIsScheduled(state),
    scheduleDateTime: LoginSelectors.getScheduledDateTime(state),
    restaurantTimeZone: MenuSelectors.getTimezone(state),
    restaurantName: MenuSelectors.getRestaurantName(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    coverImage: MenuSelectors.getCoverImage(state),
    tax_list: MenuSelectors.getTaxList(state),
    couponInfo: OrderSelectors.getCouponInfo(state),
    tipValue: OrderSelectors.getTipValue(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    orderStatus: state.order.orderStatus,
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withStyles(classes)(Checkout)))
);
