import React, { Component, useState } from "react";
import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Api from "../../Services/Api";
import { LoginSelectors } from "../../Redux/LoginRedux";
import {
  Grid,
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import styles from "./style.module.scss";
import moment from "moment";
import { instanceOf } from "prop-types";
import { Helmet } from "react-helmet";
import { withTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import { withCookies, Cookies } from "react-cookie";

const google = window.google;

function PhoneNumberMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

class WebMenuHome extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      restaurantClosed: false,
      restaurantErrorMessage: "",
      showVisitorForm: false,
      showwarningdialog: false,
      // hidewelcomemodal: true,
      addInfoProcess: false,
      visitorName: "",
      visitorNameError: false,
      visitorEmail: "",
      visitorEmailError: false,
      visitorPhone: "",
      visitorPhoneError: false,
      worsening: null,
      visitorWorseningError: false,
      isolating: null,
      visitorIsolatingError: false,
      vaccinated: null,
      visitorVaccinatedError: false,
      worseningerror: false,
      worseninghelperText: "",
      isolatinghelperText: "",
      vaccinatedhelperText: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getRestaurantInfo();
  }

  getRestaurantInfo = () => {
    const apiService = Api.create();
    apiService
      .getRestaurantInfoWebMenu(
        this.props.match?.params?.restaurant,
        this.props.match?.params?.location == "main"
          ? null
          : this.props.match?.params?.location
      )
      .then((response) => {
        if (response.status == 200 && response.data?.business_id) {
          const responseInfo = response.data;
          this.props.dispatch(
            OrderActions.setNewRestaurant(
              responseInfo.business_id,
              responseInfo.location_id,
              responseInfo.intersection_plaza,
              false,
              responseInfo
            )
          );
        } else {
          // TODO: Failure to get restaurant info
          this.setState({
            restaurantClosed: true,
            restaurantErrorMessage: response?.data?.status_message,
          });
        }
      });
  };

  getMenu = () => {
    if (
      this.props.restaurantInfo?.info?.business_id &&
      this.props.restaurantInfo?.info?.location_id
    ) {
      this.goToMenuScreen(
        this.props.restaurantInfo?.info?.business_id,
        this.props.restaurantInfo?.info?.location_id
      );
    }
  };

  goToMenuScreen = (business_id, location_id, dateTime = null) => {
    this.props.dispatch(
      MenuActions.menuRequest(business_id, location_id, 2, dateTime, true)
    );
    this.props.history.push(`${this.props.match.url}/details`);
  };

  setThemeColor = () => {
    if (
      this.props.restaurantInfo?.info?.theme_color &&
      this.props.restaurantInfo?.info?.theme_color !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(
        this.props.restaurantInfo?.info?.theme_color
      );
    }
  };

  handlePhoneNumberChange = () => (event) => {
    const value = event.target.value;
    if (value.length != 14) {
      this.setState({
        visitorPhone: value,
      });
    } else {
      this.setState({
        visitorPhoneError: false,
        visitorPhone: value,
      });
    }
  };

  addVisitorInfo = () => {
    const { cookies } = this.props;
    this.setState({
      addInfoProcess: true,
    });

    let visitorNameError = false;
    let visitorEmailError = false;
    let visitorPhoneError = false;
    let visitorWorseningError = false;
    let visitorIsolatingError = false;
    let visitorVaccinatedError = false;

    // Name checking

    if (!/^[a-zA-Z\s]+$/.test(this.state.visitorName)) {
      visitorNameError = true;
    }

    //Phone Number Checking
    if (
      this.state.visitorPhone?.length === 0 &&
      this.state.visitorPhone.length != 14
    ) {
      visitorPhoneError = true;
    } else {
      visitorPhoneError = false;
    }

    // Email Checking
    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        this.state.visitorEmail
      )
    ) {
      visitorEmailError = true;
    }

    // worsening Checking
    if (this.state.worsening === null) {
      visitorWorseningError = true;
      this.setState({
        worseninghelperText: "Please select your choice for this question",
      });
    } else {
      visitorWorseningError = false;
      this.setState({
        worseninghelperText: "",
      });
    }

    // isolating Checking
    if (this.state.isolating === null) {
      visitorIsolatingError = true;
      this.setState({
        isolatinghelperText: "Please select your choice for this question",
      });
    } else {
      visitorIsolatingError = false;
      this.setState({
        isolatinghelperText: "",
      });
    }

    // vaccinated Checking
    if (this.state.vaccinated === null) {
      visitorVaccinatedError = true;
      this.setState({
        vaccinatedhelperText: "Please select your choice for this question",
      });
    } else {
      visitorVaccinatedError = false;
      this.setState({
        vaccinatedhelperText: "",
      });
    }

    if (
      !visitorNameError &&
      !visitorEmailError &&
      !visitorPhoneError &&
      !visitorWorseningError &&
      !visitorIsolatingError &&
      !visitorVaccinatedError
    ) {
      const requestObj = {
        api_key: null,
        business_id: this.props.restaurantInfo?.info?.business_id,
        location_id: this.props.restaurantInfo?.info?.location_id,
        name: this.state.visitorName,
        phone: this.state.visitorPhone,
        email: this.state.visitorEmail,
        worsening: this.state.worsening,
        isolating: this.state.isolating,
        vaccinated: this.state.vaccinated,
      };
      const apiService = Api.create();
      this.setState({ loading: true });
      apiService.addVisitorInfo(requestObj).then((response) => {
        if (response.ok && response.data.status == "Success") {
          this.setState({
            loading: false,
            showVisitorForm: false,
            addInfoProcess: false,
            // hidewelcomemodal: true,
            visitorName: "",
            visitorPhone: "",
            visitorEmail: "",
            worsening: null,
            isolating: null,
            vaccinated: null,
            visitorNameError,
            visitorPhoneError,
            visitorEmailError,
            helperText: "",
          });
          toast.success("Thank you for submission.");
          cookies.set("visitor-list", true, {
            expires: new Date(moment().add(2, "h").format()),
          });
          this.getMenu();
        } else {
          this.setState({
            loading: false,
            addInfoProcess: false,
            showVisitorForm: false,
            showwarningdialog: true,
            // hidewelcomemodal: false,
            visitorNameError,
            visitorPhoneError,
            visitorEmailError,
            helperText: "",
          });
          toast.error(response.data.status_message);
        }
      });
    } else if (
      !visitorNameError &&
      !visitorEmailError &&
      !visitorPhoneError &&
      this.props.restaurantInfo?.info?.country !== "Canada"
    ) {
      const requestObj = {
        api_key: null,
        business_id: this.props.restaurantInfo?.info?.business_id,
        location_id: this.props.restaurantInfo?.info?.location_id,
        name: this.state.visitorName,
        phone: this.state.visitorPhone,
        email: this.state.visitorEmail,
      };

      const apiService = Api.create();
      this.setState({ loading: true });
      apiService.addVisitorInfo(requestObj).then((response) => {
        if (response.ok && response.data.status == "Success") {
          this.setState({
            loading: false,
            showVisitorForm: false,
            addInfoProcess: false,
            // hidewelcomemodal: true,
            visitorName: "",
            visitorPhone: "",
            visitorEmail: "",
            visitorNameError,
            visitorPhoneError,
            visitorEmailError,
            helperText: "",
          });
          toast.success("Thank you for submission.");
          cookies.set("visitor-list", true, {
            expires: new Date(moment().add(2, "h").format()),
          });
          this.getMenu();
        } else {
          this.setState({
            loading: false,
            addInfoProcess: false,
            showVisitorForm: false,
            showwarningdialog: true,
            visitorNameError,
            visitorPhoneError,
            visitorEmailError,
            helperText: "",
          });
          toast.error(response.data.status_message);
        }
      });
    } else {
      this.setState({
        loading: false,
        addInfoProcess: false,
        showwarningdialog: false,
        visitorNameError,
        visitorPhoneError,
        visitorEmailError,
        helperText: "",
      });
    }
  };

  openMenu = () => {
    const { cookies } = this.props;
    const list = cookies.get("visitor-list");
    if (list || this.props.restaurantInfo?.info?.contact_tracing == "0") {
      this.getMenu();
    } else {
      this.setState({ showVisitorForm: true });
    }
  };

  render() {
    this.setThemeColor();

    return (
      <>
        {this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              {this.props.restaurantInfo?.info?.business_name} | Order On The Go
            </title>
          </Helmet>
        )}
        {!this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              Order On The Go | Takeout and Delivery from your favorite
              restaurants
            </title>
          </Helmet>
        )}

        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={styles.backgroudImage}
            style={{
              minHeight: "calc(100vh - 40px)",
              backgroundImage:
                "url(" +
                this.props.restaurantInfo?.info?.background_image +
                ")",
            }}
          >
            {this.state.restaurantClosed && (
              <Grid container justify="center" className={styles.mainSection}>
                <Grid
                  container
                  style={{ minHeight: "30vh" }}
                  justify="center"
                  alignContent="center"
                >
                  <span style={{ fontStyle: "italic" }}>
                    {this.state.restaurantErrorMessage}
                  </span>
                </Grid>
              </Grid>
            )}

            {/* {this.state.hidewelcomemodal && ( */}
            <>
              {!this.state.restaurantClosed && (
                <Grid container justify="center" className={styles.mainSection}>
                  <Grid item xs={12} className={styles.topSection}>
                    <Grid container justify="center" alignItems="center">
                      {this.props.restaurantInfo?.info?.logo && (
                        <Grid item xs={12}>
                          <div style={{ textAlign: "center" }}>
                            <img
                              className={styles.logo}
                              src={this.props.restaurantInfo?.info?.logo}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {this.props.restaurantInfo?.info
                          ?.logo_has_business_name !== "1" && (
                          <h1 style={{ textAlign: "center" }}>
                            {this.props.restaurantInfo?.info?.business_name}
                          </h1>
                        )}
                        <span style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_location}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        {this.props.restaurantInfo?.info
                          ?.logo_has_business_name !== "1" && (
                          <h1 style={{ textAlign: "center" }}>
                            {this.props.restaurantInfo?.info?.business_name}
                          </h1>
                        )}
                        <span style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_location}
                        </span>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <div style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_phone}
                        </div>
                        <div style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_address}
                        </div>
                        <div style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_city},{" "}
                          {this.props.restaurantInfo?.info?.business_province}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                    <Button
                      style={{ width: "100%" }}
                      color="secondary"
                      variant="contained"
                      className={styles.continueButton}
                      onClick={() => this.openMenu()}
                    >
                      View Menu
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
            {/* // )} */}
          </Grid>

          <Dialog
            open={this.state.showVisitorForm}
            disableBackdropClick
            aria-labelledby="form-dialog-title"
            classes="custom-dialog-box"
          >
            <DialogTitle id="form-dialog-title">
              <span style={{ paddingTop: "15px" }}>
                Thank you for visiting{" "}
                {this.props.restaurantInfo?.info?.business_name}
              </span>
            </DialogTitle>
            <DialogContent>
              <div className={styles.dilogText}>
                As a mandatory requirement by our government to stop the spread
                of COVID19, we need all the guests to provide their contact
                information prior to dining with us.
              </div>
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                autoFocus
                margin="dense"
                id="name"
                label="Your Name"
                value={this.state.visitorName}
                onChange={(event) => {
                  if (!/^[a-zA-Z\s]+$/.test(this.state.visitorName)) {
                    this.setState({
                      visitorName: event.target.value,
                      visitorNameError: true,
                    });
                  } else {
                    this.setState({
                      visitorName: event.target.value,
                      visitorNameError: false,
                    });
                  }
                }}
                error={this.state.visitorNameError}
                helperText={
                  this.state.visitorNameError ? "Please enter valid Name!" : ""
                }
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                autoFocus
                margin="dense"
                id="mobile"
                label="Your Mobile"
                error={this.state.visitorPhoneError}
                helperText={
                  this.state.visitorPhoneError
                    ? "Please enter valid phone number!"
                    : ""
                }
                InputProps={{
                  inputComponent: PhoneNumberMasking,
                  value: this.state.visitorPhone,
                  onChange: this.handlePhoneNumberChange(),
                }}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                autoFocus
                margin="dense"
                id="email"
                label="Your Email"
                value={this.state.visitorEmail}
                onChange={(event) => {
                  if (
                    !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
                      event.target.value
                    )
                  ) {
                    this.setState({
                      visitorEmail: event.target.value.trim(),
                    });
                  } else {
                    this.setState({
                      visitorEmail: event.target.value.trim(),
                      visitorEmailError: false,
                    });
                  }
                }}
                error={this.state.visitorEmailError}
                helperText={
                  this.state.visitorEmailError
                    ? "Please enter valid Email Address!"
                    : ""
                }
              />
              {this.props.restaurantInfo?.info?.country === "Canada" && (
                <>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      1. Do you have any of the following new or worsening
                      symptoms or signs?
                    </FormLabel>
                    <Grid container>
                      <Grid item xs={12}>
                        <ul
                          className="list"
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        >
                          <li>Fever or chills</li>
                          <li>Cough</li>
                          <li>Trouble Breathing</li>
                          <li>Decrease or loss of taste or smell</li>
                          <li>
                            Nausea, vomiting or diarrhea (age less than l8 only)
                          </li>
                          <li>
                            Very tired, sore muscles or joints (age 18+ only)
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <RadioGroup
                      row
                      aria-label="worsening"
                      name="row-radio-buttons-group"
                      value={this.state.worsening}
                    >
                      <FormControlLabel
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        value={1}
                        control={<Radio />}
                        label="Yes"
                        required
                        onClick={() =>
                          this.setState({
                            worsening: 1,
                            worseninghelperText: "",
                          })
                        }
                      />
                      <FormControlLabel
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        value={0}
                        control={<Radio />}
                        label="No"
                        onClick={() =>
                          this.setState({
                            worsening: 0,
                            worseninghelperText: "",
                          })
                        }
                      />
                    </RadioGroup>
                    {this.state.worseninghelperText !== "" && (
                      <>
                        <FormHelperText>
                          <span style={{ color: "#f44336" }}>
                            {this.state.worseninghelperText}
                          </span>
                        </FormHelperText>
                      </>
                    )}
                  </FormControl>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      2. Has a doctor, health care provider, public health unit,
                      or Canadian Border Services Agency told you that you
                      should currently be isolating (staying at home)?
                    </FormLabel>
                    <Grid container>
                      <Grid item xs={12}>
                        <ul
                          className="list"
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        >
                          <li>
                            This can be because of an outbreak, contact tracing,
                            or travel outside of Canada in the last 14 days.
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <RadioGroup
                      row
                      aria-label="isolating"
                      name="row-radio-buttons-group"
                      value={this.state.isolating}
                    >
                      <FormControlLabel
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        value={1}
                        control={<Radio />}
                        label="Yes"
                        onClick={() =>
                          this.setState({
                            isolating: 1,
                            isolatinghelperText: "",
                          })
                        }
                      />
                      <FormControlLabel
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        value={0}
                        control={<Radio />}
                        label="No"
                        onClick={() =>
                          this.setState({
                            isolating: 0,
                            isolatinghelperText: "",
                          })
                        }
                      />
                    </RadioGroup>
                    {this.state.isolatinghelperText !== "" && (
                      <>
                        <FormHelperText>
                          <span style={{ color: "#f44336" }}>
                            {this.state.isolatinghelperText}
                          </span>
                        </FormHelperText>
                      </>
                    )}
                  </FormControl>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      3. Do you live with someone who has been told by a doctor,
                      health care provider, or public health unit that they
                      should currently be isolating?
                    </FormLabel>
                    <Grid container>
                      <Grid item xs={12}>
                        <ul
                          className="list"
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        >
                          <li>
                            If you are fully vaccinated** or have tested
                            positive for COVID-19 in the last 90 days and since
                            been cleared, select “No.”
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <RadioGroup
                      row
                      aria-label="vaccinated"
                      name="row-radio-buttons-group"
                      value={this.state.vaccinated}
                    >
                      <FormControlLabel
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        value={1}
                        control={<Radio />}
                        label="Yes"
                        onClick={() =>
                          this.setState({
                            vaccinated: 1,
                            vaccinatedhelperText: "",
                          })
                        }
                      />
                      <FormControlLabel
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        value={0}
                        control={<Radio />}
                        label="No"
                        onClick={() =>
                          this.setState({
                            vaccinated: 0,
                            vaccinatedhelperText: "",
                          })
                        }
                      />
                    </RadioGroup>
                    {this.state.vaccinatedhelperText !== "" && (
                      <>
                        <FormHelperText>
                          <span style={{ color: "#f44336" }}>
                            {this.state.vaccinatedhelperText}
                          </span>
                        </FormHelperText>
                      </>
                    )}
                  </FormControl>
                </>
              )}
              <div className={styles.dilogText}>
                By submitting this form, you agree to the terms and conditions
                of {this.props.restaurantInfo?.info?.business_name}.
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.addVisitorInfo()}
                color="secondary"
                disabled={this.state.addInfoProcess}
                variant="contained"
                fullWidth={true}
                style={{ marginBottom: 20 }}
                size="large"
              >
                {!this.state.addInfoProcess && <span>Submit</span>}
                {this.state.addInfoProcess && <span>Submitting ..</span>}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        {this.props.restaurantInfo?.info?.country === "Canada" && (
          <>
            <Dialog
              open={this.state.showwarningdialog}
              // disableBackdropClick
              aria-labelledby="form-dialog-title"
              classes="custom-dialog-box"
            >
              <DialogTitle id="form-dialog-title">
                <span style={{ paddingTop: "150px" }}>
                  <center>
                    Thank you for visiting{" "}
                    {this.props.restaurantInfo?.info?.business_name}
                  </center>
                </span>
                <h4 style={{ color: "#fb434a" }}>
                  Due to covid 19 restrictions, you are not allowed to access
                  our service.
                </h4>
              </DialogTitle>
              <DialogContent>
                <div className={styles.dilogText}></div>
              </DialogContent>
            </Dialog>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    orderType: LoginSelectors.getOrderTypeId(state),
    isScheduled: LoginSelectors.getIsScheduled(state),
    selectedAddress: LoginSelectors.getSelectedAddress(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withCookies(WebMenuHome)))
);
