import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'


const persistConfig = {
  key: 'root-ootg1',
  storage,
  stateReconciler: seamlessImmutableReconciler
}

// creates the store
export default (rootReducer, rootSaga, history) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = []
  const enhancers = []



  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)

  const routerMiddleWare = routerMiddleware(history)
  middleware.push(routerMiddleWare)
  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware))

  const persistedReducer = persistReducer(persistConfig, rootReducer)


  const createAppropriateStore = createStore
  const store = createAppropriateStore(persistedReducer, compose(...enhancers))
  const persistor = persistStore(store)
  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga)

  return {
    store,
    persistor,
    sagasManager,
    sagaMiddleware
  }
}
