import { call, put } from "redux-saga/effects";
import StoreLoginActions from "../Redux/StoreLoginRedux";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createUrlToScreen from "../Services/Utils";

export function* storeLoginRequest(api, action) {
  const { username, password, businessId, locationId } = action;
  const response = yield call(
    api.storeLoginRequest,
    username,
    password,
    businessId,
    locationId
  );

  if (response.ok && response.data.status !== "Failure") {
    const userInfo = response.data;
    yield put(StoreLoginActions.storeLoginRequestSuccess(userInfo));
    toast.success("Welcome " + response.data.name);
  } else {
    toast.error("Login Failed");
    yield put(StoreLoginActions.loginError());
  }
}
