import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router";
import WebMenuHome from "./WebMenuHome/WebMenuHome";
import OrderScreen from "./OrderScreen/OrderScreen";
import MenuScreen from "./MenuScreen/MenuScreen";
import { CookiesProvider } from "react-cookie";

class WebMenuInitialPlaceholderScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  onThemeColorChange = (color) => {
    this.props.onThemeColorChange(color);
  };

  render() {
    return (
      <CookiesProvider>
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={() => (
              <WebMenuHome onThemeColorChange={this.onThemeColorChange} />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/details`}
            render={() => (
              <MenuScreen onThemeColorChange={this.onThemeColorChange} />
            )}
          />
        </Switch>
      </CookiesProvider>
    );
  }
}

export default withRouter(WebMenuInitialPlaceholderScreen);
