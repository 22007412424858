import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import StickyBox from "react-sticky-box";
import Divider from "@material-ui/core/Divider";
import styles from "./style.module.scss";
// import Button from "@material-ui/core/Button";
// import createUrlToScreen from "../../Services/Utils";

class ServiceStaffSelection extends Component {
  constructor(props) {
    super(props);
    // console.log("ServiceStaffSelection props ", props);
  }
  // bookAppointment = () => {
  //   this.props.history.push(
  //     createUrlToScreen("bookappointment", this.props.match.url)
  //   );

  //   this.setState({ showloginModal: true, showLoadingAnimation: false });

  // };
  render() {
    return (
      <div
        style={{
          justifyContent: "center",
          backgroundColor: "#fff",
          height: "100%",
        }}
      >
        <StickyBox className={styles.titleContainer}>
          <div className={styles.titleBox}>
            <div className="font20Bold">{this.props?.item?.item_name}</div>
            <div className="subhead">{this.props?.item?.description}</div>
          </div>
        </StickyBox>
        <Divider />
        <div style={{ display: "block" }}>
          <div className={`font16Bold ${styles.modifierTitle}`}>
            {"Estimated Service Time :" +
              this.props?.item?.service_duration_minutes}
          </div>
          <div className={`font16Bold ${styles.constTitle}`}>
            {"Estimated Service Cost :  $" + this.props?.item?.item_cost}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ServiceStaffSelection);
