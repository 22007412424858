import apisauce from "apisauce";
import { api_key } from "./apiKey";
import baseUrl from "./baseUrl";

const create = (baseURL = baseUrl()) => {
  const storeLoginApi = apisauce.create({
    baseURL,
  });

  const storeLoginRequest = (username, password, businessId, locationId) => {
    let userData = {
      username: username,
      password: password,
      business_id: businessId,
      location_id: locationId,
      api_key: api_key,
    };
    return storeLoginApi.post("storelogin.php", JSON.stringify(userData));
  };

  return {
    storeLoginRequest,
  };
};

export default {
  create,
};
