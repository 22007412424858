import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  CardHeader,
  IconButton,
  ListItemText,
  Card,
  ListItemSecondaryAction,
  Divider,
  CardContent,
  CardActions,
  Modal,
} from "@material-ui/core";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import styles from "./styles.module.scss";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InfoIcon from "@material-ui/icons/Info";
import Api from "../../Services/Api";
import Swal from "sweetalert2";

class OrderStatusBlock extends Component {
  constructor(props) {
    super(props);
    console.log("OrderStatusBlock props ", props);
    this.state = {
      showEditModal: false,
      isScheduled: this.props.isScheduled,
      datePicked: this.props.scheduleDateTime?.substring(0, 11)?.trim(),
      timePicked: this.props.scheduleDateTime?.substring(
        11,
        this.props.scheduleDateTime.length
      ),
      orderType: this.props.orderType,
    };
  }

  setOrderType = (orderType) => {
    this.setState({ datePicked: "", timePicked: "", orderType: orderType });
  };

  isAsapAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_asap_delivery;
    else return this.props.restaurantInfo?.info?.is_asap_pickup;
  };

  isScheduleAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_scheduled_delivery;
    else return this.props.restaurantInfo?.info?.is_scheduled_pickup;
  };

  getAvailableDate = () => {
    const slots =
      this.state.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    if (slots) {
      const availableDates = Object.keys(slots);
      return availableDates.map((date) => (
        <MenuItem value={date}>{moment(date).format("Do MMM dddd")}</MenuItem>
      ));
    }
  };

  getAvailableTime = () => {
    const slots =
      this.state.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableSlots = slots[this.state.datePicked] ?? [];
    return availableSlots.map((time) => (
      <MenuItem value={time}>{time}</MenuItem>
    ));
  };

  removeProduct = (index) => {
    // if (!this.props.oldOrderJson || !this.props.orderHistoryFlag)
    this.props.dispatch(OrderActions.removeProduct(index));
    // else {
    //     let finalOrderJson = this.props.oldOrderJson;
    //     let deletedProduct = finalOrderJson.product_list[index];
    //     let newProductList = finalOrderJson.product_list.splice(index, 1);
    //     finalOrderJson.product_list = newProductList;
    //     finalOrderJson = this.deleteProductPriceFromTotal(
    //         finalOrderJson,
    //         deletedProduct,
    //     );
    //     let productsInfo = this.props.productsInfo;
    //     productsInfo.splice(index, 1);
    //     this.setState({
    //         finalOrderJson: finalOrderJson,
    //         productsInfo: productsInfo,
    //     });
    //     this.props.dispatch(
    //         OrderHistoryActions.setAll(
    //             finalOrderJson,
    //             productsInfo,
    //             this.props.menuForHistoryOrder,
    //         ),
    //     );
    // }
  };

  getModifierName = (id) => {
    let modifier = "";
    // if (this.props.orderHistoryFlag) {
    //     modifier = this.props.menuForHistoryOrder.modifier_list.find(
    //         (modifier) => modifier.modifier_id == id,
    //     );
    // } else {
    modifier = this.props.modifiers.find(
      (modifier) => modifier.modifier_id == id
    );
    // }
    return modifier.modifier_name;
  };

  getProductName = (id) => {
    let menuItem = "";
    // if (this.props.orderHistoryFlag) {
    //     menuItem = this.props.menuForHistoryOrder.menu_item_list.filter(
    //         (item) => item.item_id == id,
    //     );
    // } else {
    menuItem = this.props.menuItems.filter((item) => item.item_id == id);

    // }

    return menuItem[0].item_name;
  };

  getVariationInfo = (product) => {
    if (product.variation_id) {
      // if (this.props.orderHistoryFlag) {
      //     return (
      //         ' (' +
      //         this.props.menuForHistoryOrder.variation_list.find(
      //             (variation) => variation.variation_id == product.variation_id,
      //         ).variation_name +
      //         ')'
      //     );
      // } else {

      return (
        " (" +
        this.props.variationList.find(
          (variation) => variation.variation_id == product.variation_id
        ).variation_name +
        ")"
      );
      // }
    }
    return "";
  };

  saveChanges = () => {
    // this.props.dispatch(LoginActions.setOrderType(this.state.orderType));
    this.props.dispatch(LoginActions.setIsScheduled(this.state.isScheduled));

    // If Scheduled save date time to login state
    if (this.state.isScheduled) {
      const dateTime = this.state.datePicked + " " + this.state.timePicked;
      this.props.dispatch(LoginActions.setScheduleDateTime(dateTime));

      this.props.dispatch(
        MenuActions.menuRequest(
          this.props.restaurantInfo?.info?.business_id,
          this.props.restaurantInfo?.info?.location_id,
          this.props.orderType,
          dateTime
        )
      );
    } else {
      this.props.dispatch(
        MenuActions.menuRequest(
          this.props.restaurantInfo?.info?.business_id,
          this.props.restaurantInfo?.info?.location_id,
          this.props.orderType
        )
      );
    }

    this.props.dispatch(OrderActions.phoneOrderSuccessful());
    this.setState({ showEditModal: false });
  };

  isCheckoutDisabled = () => {
    if (this.props.selectedProducts.length < 1) return true;
    else
      return this.props.orderType == 1
        ? !this.props.selectedAddress?.latitude
        : false;
  };

  isSaveDisabled = () => {
    if (this.state.isScheduled) {
      return this.state.datePicked == "" || this.state.timePicked == "";
    } else {
      return false;
    }
  };

  isSubTotalDeliveryMinimumCompareError = () => {
    if (this.props.orderType == 1) {
      if (this.props.restaurantInfo.business_id != "") {
        if (
          this.props.subTotal <
          +this.props.restaurantInfo.info.delivery_minimum_order_amount
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getMinimumDeliveryAmount = () => {
    if (this.props.restaurantInfo.business_id != "")
      return this.props.restaurantInfo.info.delivery_minimum_order_amount;
    else return 0;
  };

  getLatestTimeSlots = () => {
    this.setState({
      showEditModal: true,
    });
    this.getRestaurantInfo();
  };

  getRestaurantInfo = () => {
    const apiService = Api.create();
    apiService
      .getRestaurantInfo(
        this.props.match?.params?.restaurant,
        this.props.match?.params?.location == "main"
          ? null
          : this.props.match?.params?.location
      )
      .then((response) => {
        if (response.status == 200 && response.data?.business_id) {
          const responseInfo = {
            ...this.props.restaurantInfo?.info,
            ...response.data,
            businessDomain: this.props.match?.params?.restaurant,
            locationDomain: this.props.match?.params?.location,
          };

          this.props.dispatch(
            OrderActions.setNewRestaurant(
              responseInfo.business_id,
              responseInfo.location_id,
              responseInfo.intersection_plaza,
              false,
              responseInfo,
              false
            )
          );
          if (!responseInfo?.is_pick_up_available) {
            this.props.dispatch(LoginActions.setOrderType(1));
          }
          if (!this.isAsapAvailable()) {
            this.props.dispatch(LoginActions.setIsScheduled(true));
            this.setState({ isScheduled: true });
          }
        } else {
          // TODO: Failure to get restaurant info
          this.setState({
            restaurantClosed: true,
            restaurantErrorMessage: response?.data?.status_message,
          });
        }
      });
  };

  confirmStartOver = () => {
    this.setState({ showEditModal: false });
    Swal.fire({
      title: "Are you sure?",
      text: "Your order progress will be lost!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Start Over!",
    }).then((result) => {
      if (result.value) {
        if (this.props.orderType == 3) {
          this.props.history.push(
            "/dine-in/" +
              this.props.restaurantInfo.info.businessDomain +
              "/" +
              this.props.restaurantInfo.info.locationDomain +
              "/" +
              this.props.restaurantInfo.info.room_name +
              "/" +
              this.props.restaurantInfo.info.table_number
          );
        } else {
          this.props.history.push(
            "/web/" +
              this.props.restaurantInfo.info.businessDomain +
              "/" +
              this.props.restaurantInfo.info.locationDomain
          );
        }
      }
    });
  };

  render() {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Card classes={{ root: styles.orderCard }}>
          <CardContent
            classes={{ root: styles.cardStyle }}
            style={{ width: "100%" }}
          >
            <CardHeader
              classes={{ root: styles.checkoutHeader }}
              title={
                this.props.restaurantInfo.info.is_service
                  ? "Your Service Booking Summary"
                  : "Your  Order Summary"
              }
              // title="Your Order Summary"
              alignItems="center"
            />
            {this.props.restaurantInfo.info.is_service ? (
              " "
            ) : (
              <Grid container classes={{ root: styles.cardPadding }}>
                <Grid item xs={12} sm={12}>
                  {this.props.orderType !== 3 && (
                    <div className={styles.orderInfo}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="font16Bold">
                          {this.props.orderTypeText}
                        </div>
                        <CreateIcon onClick={() => this.getLatestTimeSlots()} />
                      </div>
                    </div>
                  )}

                  {this.props.orderType == 3 && (
                    <div className={styles.orderInfo}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="font16Bold">
                          {this.props.orderTypeText}
                        </div>
                        {this.props.orderType == 3 &&
                          this.props.restaurantInfo?.info?.waiter_name && (
                            <div className={`${styles.asapText}`}>
                              Served By{" "}
                              {this.props.restaurantInfo?.info?.waiter_name}
                            </div>
                          )}
                      </div>
                      <div className={`${styles.subhead}`}>
                        {this.props.restaurantInfo?.info?.room_name} - Table #{" "}
                        {this.props.restaurantInfo?.info?.table_number}
                      </div>
                    </div>
                  )}
                  {this.props.orderType !== 3 && (
                    <>
                      <div className={`${styles.asapText} ${styles.subhead}`}>
                        {this.props.isScheduled
                          ? "Scheduled at " +
                            moment(
                              this.props.scheduleDateTime,
                              "YYYY-MM-DD HH:mm A"
                            ).format("DD MMM YYYY LT")
                          : "ASAP"}
                      </div>
                      {this.props.selectedAddress &&
                        this.props.orderTypeText !== "Pickup" && (
                          <div className={styles.subhead}>
                            {this.props.selectedAddress.address}
                          </div>
                        )}
                      {this.props.orderType == 1 &&
                        !this.props.selectedAddress?.latitude && (
                          <div className="font16" style={{ color: "red" }}>
                            Please select an address for delivery
                          </div>
                        )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}

            <Divider />
            {this.props.selectedProducts.length <= 0 && (
              <Grid
                classes={{ root: styles.cardPadding }}
                container
                style={{ minHeight: "150px" }}
                justify="center"
                alignItems="center"
              >
                {this.props.restaurantInfo.info.is_service ? (
                  <Grid
                    item
                    alignItems="center"
                    style={{ textAlign: "center" }}
                  >
                    <span className="subhead">
                      {" "}
                      Add Service from category to place your booking
                    </span>
                  </Grid>
                ) : (
                  <Grid
                    item
                    alignItems="center"
                    style={{ textAlign: "center" }}
                  >
                    <span className="subhead">
                      {" "}
                      Add Items from menu to build your order
                    </span>
                  </Grid>
                )}
              </Grid>
            )}
            {this.props.selectedProducts.length > 0 && (
              <Grid container classes={{ root: styles.menuContainer }}>
                <List className={styles.checkoutMenuUl}>
                  {this.props.selectedProducts.map((product, index) => (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        onClick={() =>
                          this.props.editProduct(product.product_id, index)
                        }
                        disableGutters={true}
                      >
                        <ListItemAvatar
                          classes={{ root: styles.itemCountStyle }}
                        >
                          <span>{product.quantity}</span>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            this.getProductName(product.product_id) +
                            this.getVariationInfo(product)
                          }
                          secondary={
                            <Grid container>
                              {product.modifiers.length ? (
                                <Grid item xs={12} sm={12}>
                                  <List>
                                    {product.modifiers.map(
                                      (modifier, index) => {
                                        return (
                                          <ListItem
                                            classes={{
                                              root: styles.modifierItemStyle,
                                            }}
                                            key={
                                              "modi" +
                                              modifier.modifier_id +
                                              this.getModifierName(
                                                modifier.modifier_id
                                              ) +
                                              index
                                            }
                                          >
                                            {this.getModifierName(
                                              modifier.modifier_id
                                            )}
                                          </ListItem>
                                        );
                                      }
                                    )}
                                  </List>
                                </Grid>
                              ) : null}
                              {product.special_instructions && (
                                <span class={`${styles.asapText} subhead`}>
                                  {product.special_instructions}
                                </span>
                              )}
                            </Grid>
                          }
                        />
                        <ListItemText
                          classes={{ root: styles.amountStyle }}
                          edge="end"
                          primary={"$" + (+product.product_total).toFixed(2)}
                        />
                        <ListItemSecondaryAction
                          classes={{ root: styles.cancelButtonStyle }}
                        >
                          <IconButton edge="end">
                            <CancelIcon
                              onClick={() => this.removeProduct(index)}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </>
                  ))}
                </List>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  <h4> Subtotal </h4>
                  <h4> ${this.props.subTotal}</h4>
                </Grid>
              </Grid>
            )}
            <CardActions classes={{ root: styles.checkoutButtonStyle }}>
              <Grid item xs={12} sm={12} style={{ width: "100%" }}>
                {this.props.selectedProducts.length > 0 &&
                  this.isSubTotalDeliveryMinimumCompareError() && (
                    <ListItem classes={{ root: styles.noInsideBorder }}>
                      <ListItemText
                        className={styles.errorText}
                        primary={
                          "Minimum order of $" +
                          this.getMinimumDeliveryAmount() +
                          " required for delivery"
                        }
                      />
                    </ListItem>
                  )}
                {this.props.restaurantInfo.info.is_service ? (
                  <Button
                    disabled={
                      this.isCheckoutDisabled() ||
                      this.isSubTotalDeliveryMinimumCompareError()
                    }
                    style={{ width: "100%" }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.props.bookAppointment()}
                    className={
                      this.isSubTotalDeliveryMinimumCompareError() ||
                      this.isCheckoutDisabled
                        ? styles.orderDisabledButton
                        : styles.checkoutButton
                    }
                  >
                    BookNow
                  </Button>
                ) : (
                  <Button
                    disabled={
                      this.isCheckoutDisabled() ||
                      this.isSubTotalDeliveryMinimumCompareError()
                    }
                    style={{ width: "100%" }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.props.checkout()}
                    className={
                      this.isSubTotalDeliveryMinimumCompareError() ||
                      this.isCheckoutDisabled
                        ? styles.orderDisabledButton
                        : styles.checkoutButton
                    }
                  >
                    Checkout
                  </Button>
                )}
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
        {this.props.restaurantInfo.info.is_service ? (
          " "
        ) : (
          <Grid container space={2} justify="center" alignItems="center">
            <Grid item>
              <Button
                className={styles.startOver}
                onClick={() => this.confirmStartOver()}
              >
                Start Over
              </Button>
            </Grid>
          </Grid>
        )}

        {this.props.orderType !== 3 && (
          <Modal
            open={this.state.showEditModal}
            onBackdropClick={() => this.setState({ showEditModal: false })}
          >
            <Grid container className={styles.modalStyle}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ flexDirection: "row", marginTop: 10 }}
                className={styles.padding30}
              >
                <div className="font16" style={{ paddingBottom: 10 }}>
                  When do you need to order
                </div>
                <div>
                  {this.isAsapAvailable() == 1 && (
                    <Button
                      variant="contained"
                      onClick={() => this.setState({ isScheduled: false })}
                      style={{ marginRight: 5 }}
                      color={this.state.isScheduled ? "" : `secondary`}
                    >
                      ASAP
                    </Button>
                  )}
                  {this.isScheduleAvailable() && (
                    <Button
                      variant="contained"
                      onClick={() => this.setState({ isScheduled: true })}
                      color={this.state.isScheduled ? `secondary` : ""}
                    >
                      Schedule Order
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.padding30}>
                {this.state.isScheduled && (
                  <Grid
                    container
                    style={{ flexDirection: "column", marginTop: 30 }}
                  >
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ fontSize: 18 }}
                      >
                        Choose a date for your order
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.datePicked}
                        onChange={(event) =>
                          this.setState({ datePicked: event.target.value })
                        }
                      >
                        {this.getAvailableDate()}
                      </Select>
                    </FormControl>
                    <FormControl style={{ marginTop: 10 }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ fontSize: 18 }}
                      >
                        Choose a time for your order
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.timePicked}
                        onChange={(event) =>
                          this.setState({ timePicked: event.target.value })
                        }
                      >
                        {this.getAvailableTime()}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    classes={{ root: styles.allergyWarningStyle }}
                    elevation={0}
                  >
                    <CardContent>
                      <Grid container justify="center" alignItems="top">
                        <Grid item xs={1}>
                          <span
                            style={{
                              verticalAlign: "top",
                              paddingRight: 10,
                            }}
                          >
                            <InfoIcon />
                          </span>
                        </Grid>
                        <Grid item xs={11}>
                          Changing the order time will remove all the items
                          added to this order
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container justify="flex-end" style={{ marginTop: "10px" }}>
                <Button
                  onClick={() => this.setState({ showEditModal: false })}
                  color="secondary"
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={this.isSaveDisabled()}
                  onClick={() => this.saveChanges()}
                  color="secondary"
                  variant="contained"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Modal>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: OrderSelectors.getProducts(state),
    subTotal: OrderSelectors.getSubTotal(state),
    menuItems: MenuSelectors.getMenuItems(state),
    modifiers: MenuSelectors.getModifierList(state),
    variationList: MenuSelectors.getVariationList(state),
    selectedAddress: LoginSelectors.getSelectedAddress(state),
    scheduleDateTime: LoginSelectors.getScheduledDateTime(state),
    orderTypeText: LoginSelectors.getOrderType(state),
    isScheduled: LoginSelectors.getIsScheduled(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    orderType: LoginSelectors.getOrderTypeId(state),
  };
};

export default withRouter(connect(mapStateToProps)(OrderStatusBlock));
