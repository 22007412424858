import React, { Component } from "react";
import * as animationData from "../../assets/loading_new.json";
import Lottie from "react-lottie";
import clogo from "../../assets/images/clogo.gif";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <div style={{ width: "100%" }}>
          <div
            className="font16Bold"
            style={{ textAlign: "center", paddingBottom: 15 }}
          >
            {this.props.title}
          </div>
        </div>
        {/* <Lottie options={defaultOptions} height="200px" width="200px" /> */}
        <img src={clogo} alt="loading..." />
      </>
    );
  }
}

export default Loading;
