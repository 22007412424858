// Filename App.js

import React, { Component } from "react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Box,
  withStyles,
  withTheme,
  Button,
  Divider,
  Typography,
} from "@material-ui/core";
import Api from "../../Services/Api";
import createUrlToScreen from "../../Services/Utils";
import { toast } from "react-toastify";

const styles = (theme) => ({
  container: {
    height: "14vh",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  orderButton: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

class CancelComponent extends Component {
  constructor(props) {
    super(props);
    console.log("CancelComponent props ", props);
    const storedData = JSON.parse(localStorage.getItem("showAppointmentData"));
    const show_business_id = storedData.showAppointmentBusinessId;
    this.state = {
      cancelResponse: [],

      cancelResponseError: "",
      cancelBusinessId: show_business_id,
    };
  }

  confirmCancelAppointment = async () => {
    let apiService = Api.create();

    var requestObj = {
      appointment_id: this.props.appointmentId,
      business_id: this.state.cancelBusinessId,
    };

    apiService.cancelAppointment(requestObj).then((response) => {
      if (response.status === 200) {
        this.setState({
          cancelResponse: response?.data?.status_message?.appointments || [],
        });
        toast.success("Appointment Canceled Successfully...");
        this.props.history.push("/orderHistory");
      } else {
        this.setState({ cancelResponseError: response?.data });
        toast.error(response?.data?.status_message);
      }
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box className={classes.container}>
          <div
            style={{
              fontWeight: "500",
              fontSize: "21px",
              padding: "10px",
            }}
          >
            Would Like to Cancel the Appointment !!!
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => this.props.handleCloseCancelModal()}
            >
              No
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              style={{ marginLeft: "8px" }}
              onClick={this.confirmCancelAppointment}
            >
              Yes
            </Button>
          </div>
        </Box>
      </>
    );
  }
}

export default withRouter(withTheme(withStyles(styles)(CancelComponent)));
