import apisauce from "apisauce";
import { api_key } from "./apiKey";
import baseUrl from "./baseUrl";

const create = (baseURL = baseUrl()) => {
  const loginApi = apisauce.create({
    baseURL,
  });

  const loginRequest = (username, password) => {
    let userData = {
      customer_email: username,
      password: password,
      api_key: api_key,
    };
    return loginApi.post("login.php", JSON.stringify(userData));
  };

  const storeLoginRequest = (username, password, businessId, locationId) => {
    let userData = {
      username: username,
      password: password,
      business_id: businessId,
      location_id: locationId,
      api_key: api_key,
    };
    return loginApi.post("storelogin.php", JSON.stringify(userData));
  };

  const signupRequest = (name, phone, email, password) => {
    let userData = {
      customer_name: name,
      customer_email: email,
      customer_phone: phone,
      password: password,
      api_key: api_key,
    };
    return loginApi.post("signup.php", JSON.stringify(userData));
  };

  const forgotPassword = (email) => {
    const formData = new FormData();
    formData.append("customer_email", email);
    formData.append("api_key", api_key);
    return loginApi.post("forgot.php", formData);
    // let userData = {
    //   customer_email: email,
    //   api_key: api_key,
    // };
    // return loginApi.post("forgot.php", JSON.stringify(userData));
  };

  const resetPassword = (approveKey, password) => {
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("approve_key", approveKey);
    formData.append("password", password);
    return loginApi.post("reset.php", formData);
  };

  const changePassword = (password, token) => {
    let userData = {
      token: token,
      password: password,
      api_key: api_key,
    };
    return loginApi.post("change_password.php", JSON.stringify(userData));
  };

  const getLatestUserInfo = (customer_id, token) => {
    const formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("api_key", api_key);
    formData.append("token", token);
    return loginApi.post("getcutomerinfo.php", formData);
  };

  return {
    loginRequest,
    signupRequest,
    changePassword,
    getLatestUserInfo,
    resetPassword,
    forgotPassword,
    storeLoginRequest,
  };
};

export default {
  create,
};
