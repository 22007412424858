import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";
import AddressSelection from "./AddressSelection/AddressSelection";
import OrderScreen from "./OrderScreen/OrderScreen";
import Checkout from "./Checkout/Checkout";
import BookingAppoinment from "../Screens/BookingAppoinment/BookingAppoinment";
import AppointmentConfirm from "../components/AppointmentConfirm/AppointmentConfirm";
import ShowAppointment from "./ShowAppointment/ShowAppointment";
import RescheduleAppointment from "../Screens/RescheduleAppointment/RescheduleAppointment";

// import OrderHistory from "./OrderHistory/OrderHistory";
// import PhoneLogin from "./PhoneLogin/PhoneLogin";
// import Api from "../Services/Api";

class InitialPlaceholderScreen extends Component {
  constructor(props) {
    super(props);
  }

  onThemeColorChange = (color) => {
    this.props.onThemeColorChange(color);
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={this.props.match.path}
          render={() => (
            <AddressSelection onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/order`}
          render={() => (
            <OrderScreen onThemeColorChange={this.onThemeColorChange} />
          )}
        />

        <Route
          exact
          path={`${this.props.match.path}/checkout`}
          render={() => (
            <Checkout onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/bookappointment`}
          render={() => (
            <BookingAppoinment onThemeColorChange={this.onThemeColorChange} />
          )}
        />

        <Route
          exact
          path={`${this.props.match.path}/appointment`}
          render={() => (
            <AppointmentConfirm onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/showAppointment/:business_id/:appointment_id`}
          render={() => (
            <ShowAppointment onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/rescheduleappointment`}
          render={() => (
            <RescheduleAppointment
              onThemeColorChange={this.onThemeColorChange}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(InitialPlaceholderScreen);
