import React, { Component } from "react";
import { connect } from "react-redux";
import { MenuSelectors } from "../../Redux/MenuRedux";
import { Grid, Button, Box, Modal } from "@material-ui/core";
import styles from "./style.module.scss";
import InfoIcon from "@material-ui/icons/Info";

class RestaurantInfo extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    allergyModal: false,
  };

  componentDidMount() {}

  openAllergyModal = () => {
    this.setState({
      allergyModal: true,
    });
  };

  render() {
    return (
      <>
        <Box classes={{ root: styles.mainBoxStyle }}>
          <Grid item xs={12} sm={6} md={3} className={styles.restaurantInfo}>
            {this.props.businessInfo.name && (
              <Box className={styles.box}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={8}>
                    <h1>{this.props.businessInfo.name}</h1>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="subhead">
                      {this.props.businessInfo.phone}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="subhead">
                      {this.props.businessInfo.address} |{" "}
                      {this.props.businessInfo.city}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessInfo: MenuSelectors.getBusinessInfo(state),
  };
};

export default connect(mapStateToProps)(RestaurantInfo);
