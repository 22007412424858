import apisauce from "apisauce";
import moment from "moment";
const api_key = "be3e4a582ea9a8d4f45bf5c4f3e85656";

const create = (baseURL = "https://rigel.gujjutech.com/api/v1") => {
  const flightApi = apisauce.create({
    baseURL,
  });

  const today = moment().format("YYYY-MM-DD");

  const getFlightData = (flightNo) => {
    return flightApi.get(`/flight?flight_iata=${flightNo}`);
  };

  return {
    getFlightData,
  };
};

export default {
  create,
};
