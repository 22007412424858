const calculateDineInTip = (tipIndex, subTotal, tipArray) => {
  let tip = 0;
  if (tipIndex < 4 && tipIndex !== 0) {
    let tipPercentValue = tipArray[tipIndex];
    tip = subTotal * (tipPercentValue / 100);
  } else if (tipIndex == 0) {
    tip = 0.0;
  }
  return (+tip).toFixed(2);
};

export default calculateDineInTip;
