import crypto from 'crypto'

const publicKey = '-----BEGIN PUBLIC KEY-----\n' +
    'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgG6astQndp45a+SQee5jXXMog46e\n' +
    '2ryDufeOqii6pReatOYmDyBYC0kFucCojE04eyONHKMze5N8gqFS6xM1oihScoN8\n' +
    'hTBOq06XO+XhXPjqE1EqT+wtzhMeMu8b25/XdQSNUMbvlV6diYtW7VNbQBSA4ET6\n' +
    '/c+4s1r4AygMAA1lAgMBAAE=\n' +
    '-----END PUBLIC KEY-----'

export function encrypt(text) {
  const encryptedData = crypto.publicEncrypt(publicKey,Buffer.from(text));
  return encryptedData.toString('base64')
}
