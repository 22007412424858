import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "./style.module.scss";
import {
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import background_1 from "../../assets/images/backimage_1.jpeg";
import LoginActions from "../../Redux/LoginRedux";
import { LoginSelectors } from "../../Redux/LoginRedux";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approve_key: null,
      newPassword: "",
      confirmPassword: "",
      showPasswordnew: false,
      showPasswordconfirm: false,
      passwordError: false,
      confirmError: false,
      passwordReset: false,
    };
  }
  resetPassword = () => {
    if (this.state.newPassword.length < 6) {
      this.setState({ passwordError: true });
      return;
    }
    if (this.state.newPassword != this.state.confirmPassword) {
      this.setState({ passwordError: true });
      return;
    }

    this.props.dispatch(
      LoginActions.resetPassword(this.state.approve_key, this.state.newPassword)
    );
  };

  componentDidMount = () => {
    this.props.dispatch(LoginActions.logOut());
    this.setState({
      approve_key: this.props.match?.params?.approveKey,
      passwordReset: false,
    });
  };

  render() {
    return (
      <>
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={styles.backgroudImage}
            style={{
              minHeight: "100vh",
              backgroundImage: `url(${background_1})`,
            }}
          >
            {!this.props.resetPasswordSuccess && (
              <Grid container justify="center" className={styles.mainSection}>
                <span style={{ fontStyle: "italic" }}>
                  Enter the new Password
                </span>
                <FormControl fullWidth={true}>
                  <InputLabel>New Password</InputLabel>
                  <Input
                    type="password"
                    id="new-password"
                    aria-describedby="new-password-text"
                    type={this.state.showPasswordnew ? "text" : "password"}
                    onChange={(value) =>
                      this.setState({ newPassword: value.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            this.setState({
                              showPasswordnew: !this.state.showPasswordnew,
                            })
                          }
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {this.state.showPasswordnew ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {this.state.passwordError && (
                    <FormHelperText error id="my-helper-text" label="Error">
                      Password must be atleast 6 characters
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="signup-phone">
                    Confrim Password
                  </InputLabel>
                  <Input
                    type="password"
                    id="signup-password"
                    aria-describedby="signup-password-text"
                    type={this.state.showPasswordconfirm ? "text" : "password"}
                    onChange={(value) =>
                      this.setState({ confirmPassword: value.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            this.setState({
                              showPasswordconfirm: !this.state
                                .showPasswordconfirm,
                            })
                          }
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {this.state.showPasswordconfirm ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {this.state.confirmError && (
                    <FormHelperText error id="my-helper-text" label="Error">
                      Password does not match or password has to minimum 6
                      characters
                    </FormHelperText>
                  )}
                </FormControl>
                <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                  <Button
                    style={{ width: "100%" }}
                    color="secondary"
                    variant="contained"
                    className={styles.continueButton}
                    onClick={() => this.resetPassword()}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            )}
            {this.props.resetPasswordSuccess && (
              <Grid container justify="center" className={styles.mainSection}>
                <Grid item xs={12}>
                  Password reset successful, Please login to your favorite
                  restuarant.
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    resetPasswordSuccess: LoginSelectors.getResetPassword(state),
  };
};
export default withRouter(connect(mapStateToProps)(ForgetPassword));
