// Filename App.js

import React, { Component } from "react";
import { Box, Container } from "@material-ui/core";
import NavBar from "../../components/Navbar/Navbar";

class AppointmentConfirm extends Component {
  render() {
    return (
      <Box>
        <NavBar />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>Appointment Booking Successfully !!!</h2>
        </div>
      </Box>
    );
  }
}

export default AppointmentConfirm;
