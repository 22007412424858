import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator
const ORDER_TYPES = [
  {
    type: "Delivery",
    id: 1,
  },
  {
    type: "Pickup",
    id: 2,
  },
];

const { Types, Creators } = createActions({
  storeLoginRequest: ["username", "password", "businessId", "locationId"],
  storeLoginRequestSuccess: ["userInfo"],
  logoutUser: {},
  clearUserData: {},
  setOrderType: ["selectedOrderType"],
  setAddress: ["selectedAddress", "isScheduled", "scheduleDateTime"],
  setIsScheduled: ["isScheduled"],
  setScheduleDateTime: ["dateTime"],
  loginError: {},
});

export const StoreLoginTypes = Types;
export default Creators;

// Initial State
export const INITIAL_STORE_LOGIN_STATE = Immutable({
  isLoggedIn: false,
  userInfo: {},
  loginError: false,
  addressSelected: {},
  orderType: "2",
  token: "",
  isScheduled: false,
  scheduleDateTime: "",
});

// Selectors to derive data from state
export const StoreLoginSelectors = {
  isUserloggedIn: (state) => {
    return state.storeLogin.isLoggedIn;
  },
  getSelectedAddress: (state) => state.storeLogin.addressSelected,
  getOrderType: (state) =>
    ORDER_TYPES.find(({ id }) => id == state.storeLogin.orderType).type,
  getOrderTypeId: (state) => state.storeLogin.orderType,
  getAllOrderTypes: (state) => ORDER_TYPES,
  getUserInfo: (state) => state.storeLogin?.userInfo,
  getUserToken: (state) => state.storeLogin?.token,
  // getScheduledDateTime: state => {
  //   return state.login.isScheduled
  //     ? state.login.scheduleDateTime.getDate() == new Date().getDate()
  //       ? 'Today ' + moment(state.login.scheduleDateTime).format('LT')
  //       : moment(state.login.scheduleDateTime).format('DD MMM LT')
  //     : 'ASAP';
  // },
  getIsScheduled: (state) => state.storeLogin.isScheduled,
  getLoginError: (state) => state.storeLogin.loginError,
  getScheduledDateTime: (state) => state.storeLogin.scheduleDateTime,
};

// Reducers

export const storeLoginRequest = (
  state,
  { username, password, businessId, locationId }
) => {

  return {
    loading: true,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
  };
};

export const storeLoginRequestSuccess = (state, responseObj) => {
  return {
    loading: false,
    isLoggedIn: true,
    userInfo: responseObj.userInfo,
    loginError: false,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: responseObj.userInfo.token,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
  };
};

export const setOrderType = (state, { selectedOrderType }) => {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    addressSelected: state.addressSelected,
    orderType: selectedOrderType,
    token: state.token,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    isStoreLogin: state.isStoreLogin,
  };
};

export const logoutUser = (state) => {

  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
  };
};

export const clearUserData = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: false,
    addressSelected: null,
    orderType: "2",
    token: state.token,
    isScheduled: null,
    scheduleDateTime: null,
  };
};

export const setAddress = (state, selectedAddress) => {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    addressSelected: selectedAddress.selectedAddress,
    orderType: state.orderType,
    token: state.token,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
  };
};

export const setIsScheduled = (state, { isScheduled }) => {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: state.token,
    isScheduled: isScheduled,
    scheduleDateTime: state.scheduleDateTime,
  };
};

export const setScheduleDateTime = (state, { dateTime }) => {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: state.token,
    isScheduled: state.isScheduled,
    scheduleDateTime: dateTime,
  };
};

export const loginError = (state) => {

  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: true,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_STORE_LOGIN_STATE, {
  [Types.STORE_LOGIN_REQUEST]: storeLoginRequest,
  [Types.STORE_LOGIN_REQUEST_SUCCESS]: storeLoginRequestSuccess,
  [Types.LOGOUT_USER]: logoutUser,
  [Types.CLEAR_USER_DATA]: clearUserData,
  [Types.SET_ADDRESS]: setAddress,
  [Types.SET_IS_SCHEDULED]: setIsScheduled,
  [Types.LOGIN_ERROR]: loginError,
  [Types.SET_ORDER_TYPE]: setOrderType,
  [Types.SET_SCHEDULE_DATE_TIME]: setScheduleDateTime,
});
