import React, { Component } from "react";
import FlightApi from "../../Services/FlightApi";
import { Grid, TextField, Button, Divider, Box } from "@material-ui/core";
import moment from "moment";
import styles from "./style.module.scss";

class FlightStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flightNo: null,
      flightDetails: null,
      flightError: false,
    };
  }

  setFlightNo = (flightNo) => {
    this.setState({
      flightNo: flightNo,
    });
  };

  searchForFlight = () => {
    const today = moment().format("YYYY-MM-DD");
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
    if (this.state.flightNo.length > 2) {
      let apiService = FlightApi.create();
      apiService.getFlightData(this.state.flightNo).then((response) => {
        let flightDetails = null;
        if (response.ok && response.data.data.length) {
          response.data.data.map((flight) => {
            if (
              flight.flight_date == today &&
              flight.flight_status == "active"
            ) {
              flightDetails = flight;
            }
          });

          if (flightDetails == null) {
            response.data.data.map((flight) => {
              if (
                flight.flight_date == yesterday &&
                flight.flight_status == "active"
              ) {
                flightDetails = flight;
              }
              this.setState({
                flightDetails: flightDetails,
                flightError: false,
              });
            });
          }

          if (flightDetails == null) {
            response.data.data.map((flight) => {
              if (flight.flight_date == today) {
                flightDetails = flight;
              }
              this.setState({
                flightDetails: flightDetails,
                flightError: false,
              });
            });
          }

          if (flightDetails) {
            this.setState({
              flightDetails: flightDetails,
              flightError: false,
            });
          } else {
            this.setState({
              flightDetails: null,
              flightError: true,
            });
          }
        } else {
          let flightDetails = null;
          this.setState({
            flightDetails: flightDetails,
            flightError: true,
          });
        }
      });
    }
  };

  render() {
    return (
      <>
        <Grid container direction="row" className={styles.container}>
          <Grid item xs={12}>
            <div>
              <h3>Flight Status</h3>
              <TextField
                id="outlined-basic"
                label="Enter Flight Number"
                variant="outlined"
                size="small"
                value={this.state.flightNo}
                onChange={(event) => this.setFlightNo(event.target.value)}
              />
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={() => this.searchForFlight()}
                style={{ marginLeft: 5 }}
              >
                Search
              </Button>
            </div>
            {this.state.flightDetails && !this.state.flightError && (
              <>
                <Grid item xs={12}>
                  <h5>
                    Departure Info - {this.state.flightDetails.flight.iata}
                    <div>
                      {this.state.flightDetails.flight_status ==
                        "scheduled" && (
                        <span>
                          {this.state.flightDetails.departure.delay == null && (
                            <span className={styles.primary}>SCHEDULED</span>
                          )}
                          {this.state.flightDetails.departure.delay !==
                            null && (
                            <span className={styles.error}>
                              DELAYED -{" "}
                              {this.state.flightDetails.departure.delay} mins
                            </span>
                          )}
                        </span>
                      )}
                      {(this.state.flightDetails.flight_status == "active" ||
                        this.state.flightDetails.flight_status == "landed") && (
                        <span>
                          {this.state.flightDetails.departure.delay == null && (
                            <span className={styles.success}>ON-TIME</span>
                          )}
                          {this.state.flightDetails.departure.delay !==
                            null && (
                            <span className={styles.error}>
                              DELAYED -{" "}
                              {this.state.flightDetails.departure.delay} mins
                            </span>
                          )}
                        </span>
                      )}
                      {this.state.flightDetails.flight_status ==
                        "cancelled" && (
                        <span className={styles.error}>Cancelled</span>
                      )}
                    </div>
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={styles.label}>Airport</div>
                      <div className={styles.value}>
                        {this.state.flightDetails.departure.iata}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={styles.label}>Gate</div>
                      <div className={styles.value}>
                        {this.state.flightDetails.departure.gate
                          ? this.state.flightDetails.departure.gate
                          : "N/A"}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={styles.label}>Terminal</div>
                      <div className={styles.value}>
                        {this.state.flightDetails.departure.terminal
                          ? this.state.flightDetails.departure.terminal
                          : "N/A"}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      {this.state.flightDetails.departure.actual == null &&
                        this.state.flightDetails.departure.scheduled ==
                          this.state.flightDetails.departure.estimated && (
                          <>
                            <div className={styles.label}>Scheduled</div>
                            <div className={styles.value}>
                              {moment
                                .utc(
                                  this.state.flightDetails.departure.scheduled
                                )
                                .format("MMM D, HH:mm")}
                            </div>
                          </>
                        )}
                      {this.state.flightDetails.departure.actual == null &&
                        this.state.flightDetails.departure.scheduled !=
                          this.state.flightDetails.departure.estimated && (
                          <>
                            <div className={styles.label}>Estimated</div>
                            <div className={styles.value}>
                              {moment
                                .utc(
                                  this.state.flightDetails.departure.estimated
                                )
                                .format("MMM D, HH:mm")}
                            </div>
                          </>
                        )}
                      {this.state.flightDetails.departure.actual !== null && (
                        <>
                          <div className={styles.label}>Departed</div>
                          <div className={styles.value}>
                            {moment
                              .utc(this.state.flightDetails.departure.actual)
                              .format("MMM D, HH:mm")}
                          </div>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <h5>
                    Arrival Info - {this.state.flightDetails.flight.iata}
                    <div>
                      {this.state.flightDetails.flight_status ==
                        "scheduled" && (
                        <span>
                          {this.state.flightDetails.arrival.delay == null && (
                            <span className={styles.primary}>SCHEDULED</span>
                          )}
                          {this.state.flightDetails.arrival.delay !== null && (
                            <span className={styles.error}>
                              DELAYED -{" "}
                              {this.state.flightDetails.departure.delay} mins
                            </span>
                          )}
                        </span>
                      )}
                      {(this.state.flightDetails.flight_status == "active" ||
                        this.state.flightDetails.flight_status == "landed") && (
                        <span>
                          {this.state.flightDetails.arrival.delay == null && (
                            <span className={styles.success}>ON-TIME</span>
                          )}
                          {this.state.flightDetails.arrival.delay !== null && (
                            <span className={styles.error}>
                              DELAYED - {this.state.flightDetails.arrival.delay}{" "}
                              mins
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={styles.label}>Airport</div>
                      <div className={styles.value}>
                        {this.state.flightDetails.arrival.iata}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={styles.label}>Gate</div>
                      <div className={styles.value}>
                        {this.state.flightDetails.arrival.gate
                          ? this.state.flightDetails.arrival.gate
                          : "N/A"}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={styles.label}>Terminal</div>
                      <div className={styles.value}>
                        {this.state.flightDetails.arrival.terminal
                          ? this.state.flightDetails.arrival.terminal
                          : "N/A"}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      {this.state.flightDetails.arrival.actual == null &&
                        this.state.flightDetails.arrival.scheduled ==
                          this.state.flightDetails.arrival.estimated && (
                          <>
                            <div className={styles.label}>Scheduled</div>
                            <div className={styles.value}>
                              {moment
                                .utc(this.state.flightDetails.arrival.scheduled)
                                .format("MMM D, HH:mm")}
                            </div>
                          </>
                        )}
                      {this.state.flightDetails.arrival.actual == null &&
                        this.state.flightDetails.arrival.scheduled !=
                          this.state.flightDetails.arrival.estimated && (
                          <>
                            <div className={styles.label}>Estimated</div>
                            <div className={styles.value}>
                              {moment
                                .utc(this.state.flightDetails.arrival.estimated)
                                .format("MMM D, HH:mm")}
                            </div>
                          </>
                        )}
                      {this.state.flightDetails.arrival.actual != null && (
                        <>
                          <div className={styles.label}>Arrived</div>
                          <div className={styles.value}>
                            {moment
                              .utc(this.state.flightDetails.arrival.actual)
                              .format("MMM D, HH:mm")}
                          </div>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {this.state.flightError && (
              <Grid item xs={12}>
                <div style={{ marginTop: 15 }} className={styles.error}>
                  Flight Not Found
                </div>
              </Grid>
            )}
            <div className={styles.note}>
              * Flight Data provided by aviationstack. Actual data may differ,
              Please check with Airport for more details.
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default FlightStatus;
